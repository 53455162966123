import ServiceBase from "base/baseService"

export default class ProfissaoService extends ServiceBase {

    title = "Profissão"
    pluralTitle = "Profissões"
    url = '/cadastro/pessoa/profissao'
    frontUrl = '/cadastro/pessoa/profissao'
    frontUrlList = '/cadastro/pessoa/profissaolist'
    primaryKey = 'codigo_prf'
    ignoredsSchemaConvertions = []

    prepareToFront(data = {}) {

        return data
    }

    prepareToSave({ data }) {

        delete data.meta

        return data

    }

}
