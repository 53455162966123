import { Outlet, useNavigate } from "react-router-dom";
import MenuTop from "./workarea/desktop/MenuTop";
import { Badge } from "primereact/badge";
import { useEffect, useRef, useState } from "react";
import { classNames } from 'primereact/utils'
import * as auth from "./infra/localStorage"
import { TieredMenu } from 'primereact/tieredmenu'
import UserAvatar from "workarea/desktop/UserAvatar";

export default function Home() {

    const navigate = useNavigate()
    const overlayRef = useRef(null)
    const storageData = auth.getData()
    const [ditem, setDitem] = useState([])

    useEffect(() => {

        window.addEventListener('beforeunload', (event) => {
            event.returnValue = true
        })

        // document.addEventListener('touchmove', e => e.preventDefault(), { passive: false, })

        const menu = auth.getData().menu
        if (Object.keys(menu).length > 0) {
            setDitem(menu)
            scanItems(menu)
        }

        function scanItems(items) {
            items.forEach(child => {
                if (child.route) {
                    child.command = () => navigate(child.route)
                    child.template = (item, options) => {
                        return (
                            <a href={child.route} role="menuitem" className={options.className}
                                target={item.target} onClick={options.onClick}
                            >
                                <span className={classNames(options.iconClassName, options.icon)}></span>
                                <span className="p-menuitem-text">{item.label}</span>
                            </a>
                        )
                    }
                }
                if (child.items) {
                    scanItems(child.items)
                }
            })
        }
        // })


    }, [])

    function handlePop(event) {
        overlayRef.current.toggle(event)
    }

    return (
        <div className="home">
            <nav className="sidebar">
                <div>
                    <div className="logo-empresa">
                        <img src="/logo192.png" alt="" />
                        <span>Fiz Aqui</span>
                    </div>
                    <div className="flex flex-row justify-content-evently align-items-center justify-content-center gap-4 mt-3">
                        <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '1.3rem' }}>
                            <Badge value="2"></Badge>
                        </i>
                        <i className="pi pi-calendar p-overlay-badge" style={{ fontSize: '1.3rem' }}>
                            <Badge value="5+" severity="danger"></Badge>
                        </i>
                        <i className="pi pi-envelope p-overlay-badge" style={{ fontSize: '1.3rem' }}>
                            <Badge value="2" severity="success"></Badge>
                        </i>
                        <i>
                            {/* <Avatar className="mb-0" label="IS" image={gravatar} shape="circle" onClick={handlePop} /> */}
                            <UserAvatar></UserAvatar>
                        </i>
                    </div>
                    <div className="menu">
                        <TieredMenu model={ditem} breakpoint="767px" />
                    </div>
                </div>
                <div className="flex flex-column sidebar-footer gap-2 mb-3 ml-3 mr-3">


                    <div className="flex flex-column m-3">
                        {storageData.usuario.nome_usu} - {storageData.empresa.nome_fantasia_pej}
                    </div>
                </div>
            </nav >

            <div className="content">
                <div className="topbar">
                    <MenuTop></MenuTop>
                </div>
                <div className="flex justify-content-center m-4">
                    <Outlet />
                </div>
            </div>

        </div >
    )
}