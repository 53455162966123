import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import InputFrame from "./InputFrame";

export default function InputTextareaForm(props) {

    const field = props.controller.field
    const fieldState = props.controller.fieldState

    return (
        <>
            <InputFrame {...props}>
                <InputTextarea
                    id={field.name}
                    {...field}
                    autoComplete="off"
                    className={'p-inputtext-sm ' + (fieldState.isDirty ? '' : '') + classNames({ 'p-invalid': fieldState.error })}
                    {...props}
                />
            </InputFrame>
        </>
    )
}
