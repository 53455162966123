import React, { useEffect, useRef, useState } from 'react'
import { Column } from 'primereact/column'
import ClassificacaoPessoaService from "./classificacaoPessoaService"
import FormList from 'base/FormList'

export default function ClassificacaoPessoaList() {

    const [service, setService] = useState()
    const toast = useRef(null)

    if (!service) {
        setService(new ClassificacaoPessoaService())
    }
    const searchData = {
        pkName: 'codigo_clap',
        dsName: 'descricao_clap',
        mainField: { name: 'descricao_clap', label: 'Descrição' },
        advFields: [
            { name: 'codigo_clap', label: 'Código', type: 'integer', inputMode: 'numeric' },
        ]
    }

    useEffect(() => {
    }, [service])

    return (

        <FormList service={service} toast={toast} searchData={searchData} showActions={true}>
            <Column field="descricao_clap" sortable header="Classificação da pessoa"></Column>
            <Column field="meta.descricao_tip" sortable header="Tipo"></Column>
        </FormList >
    )
}