import queryString from "query-string"

export function newSearch({ config, values }) {

    config.searchType = config?.searchType || values?.searchType || 'main'
    config.page = config?.page || 0
    config.perPage = config?.perPage || 10

    config.mainField.value = config?.mainField.value || values?.mainField.value || ''
    config.mainField.type = config?.mainField.type || 'string'
    config.mainField.inputMode = config?.mainField.inputMode || 'text'
    config.mainField.mask = config?.mainField.mask || null
    config.mainField.operation = config?.mainField.operation || 'and'

    config.advFields = config.advFields || []

    config.advFields.map((e, i) => {

        if (values?.advFields[i]?.value) {
            if (e.value === undefined) {
                e.value = values?.advFields[i].value
            } else {
                values.advFields[i].value = e.value
            }
        }

        e.value = e.value || null
        e.type = e.type || 'string'
        e.inputMode = e.inputMode || 'text'
        e.mask = e.mask || null
        e.operation = e.operation || 'and'

    })

    return config
}

export const searchToQs = ({ config, selPage }) => {
    let qs = ''

    if (config.searchType === 'adv') {

        const keys = config.advFields.reduce((acc, { name, value }) => {
            if (value) {
                acc[name] = value
            }
            return acc
        }, {})

        keys.page = selPage + 1
        keys.perPage = config.perPage

        qs = queryString.stringify(keys)

    } else if (config.searchType === 'main') {

        const keys = { [config.mainField.name]: config.mainField.value }

        keys.page = selPage + 1
        keys.perPage = config.perPage
        qs = queryString.stringify(keys)

    }

    qs = qs ? '?' + qs : qs

    return qs
}