import ServiceBase from "base/baseService"

export default class SexoService extends ServiceBase {

    title = "Sexo"
    pluralTitle = "Sexos"
    url = '/cadastro/pessoa/sexo'
    frontUrl = '/cadastro/pessoa/pessoafisica/sexo'
    frontUrlList = '/cadastro/pessoa/pessoafisica/sexolist'
    primaryKey = 'codigo_esci'

}

