import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import UsuarioService from './usuarioService'
import { Panel } from 'primereact/panel'
import ControllerInput from 'base/ControllerInput'
import GridForm from 'base/GridForm'
import { yupResolver } from "@hookform/resolvers/yup"
import FormCadastro, { FormCadastroAux } from 'base/FormCadastro'
import { Toast } from 'primereact/toast'


export default function Usuario() {

    const [service] = useState(new UsuarioService())
    const toast = useRef(null)
    const { id } = useParams()
    const [validations, setValidations] = useState([]);

    const form = useForm({
        defaultValues: async () => await FormCadastroAux.load({ id, service: service, toast }),
        resolver: yupResolver(validations)
    })

    useEffect(() => {
        service.getSchema().then(setValidations)
    }, [service])


    return (

        < FormCadastro service={service} form={form} aux={FormCadastroAux}>
            <Toast ref={toast} />

            <Panel className="mb-4" header={"Capa"} >
                <GridForm>
                    <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-4" field="meta.nome_razao_pes" form={form} service={service} type="text" label="Nome" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="meta.cpf_cnpj_pes" form={form} service={service} type="text" label="CPF" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="meta.cnh_pef" form={form} service={service} type="text" label="CNH" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="IE" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="RG" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Orgão emissor" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Data de emissão" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Apelido" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Incrição municipal" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Nacionalidade" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Escolaridade" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Naturalidade" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Hobbie" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Nascimento" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Sexo" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Estado civil" />
                    <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-3" field="meta.nome_pai_pef" form={form} service={service} type="text" label="Pai" />
                    <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-3" field="meta.nome_mae_pef" form={form} service={service} type="text" label="Mãe" />
                </GridForm>
            </Panel>
            <Panel className="mb-4" header={"Endereços"} toggleable >
                <GridForm>
                    <ControllerInput addClassName="md:col-12 lg:col-12 xl:col-12" field="" form={form} service={service} type="switch" label="Principal" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="CEP" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Endereço" />
                    <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-1" field="" form={form} service={service} type="text" label="Número" />
                    <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-3" field="" form={form} service={service} type="text" label="Complemento" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Bairro" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Município" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="País" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="E-mail" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="E-mail financeiro" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Telefone pessoal" />
                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="" form={form} service={service} type="text" label="Telefone comercial" />
                </GridForm>
            </Panel>



        </FormCadastro >
    )
}

