import ServiceBase from "base/baseService"
import { jsDateToLuxonString } from "helper/formater"

export default class RamoAtividadeService extends ServiceBase {

    title = "Ramo de atividade"
    pluralTitle = "Ramo de atividades"
    url = '/cadastro/pessoa/ramoatividade'
    frontUrl = '/cadastro/pessoa/ramoatividade'
    frontUrlList = '/cadastro/pessoa/ramoatividadelist'
    primaryKey = 'codigo_rati'
    ignoredsSchemaConvertions = []

    prepareToFront(data = {}) {

        return data
    }

    prepareToSave({ data }) {

        data.atualizado_rati = jsDateToLuxonString(data.atualizado_rati)

        delete data.meta

        return data

    }

}
