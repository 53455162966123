
import { useEffect, useState } from 'react'
import Form from 'base/Form'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Toast } from 'primereact/toast'
import { DataTable } from 'primereact/datatable'
import { Paginator } from 'primereact/paginator'
import SearchBar from 'base/searchBar/SearchBar'
import { newSearch, searchToQs } from 'base/crud/crud'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { useTranslation } from 'react-i18next'

export default function FormList(props) {

    const { t } = useTranslation()
    const { service, children, toast, searchData, showActions } = props
    const [perpage, setRows] = useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const cadastro = useLocation()
    const navigate = useNavigate()
    const [first, setFirst] = useState(cadastro.state?.first || 0)
    const [page, setPage] = useState(cadastro.state?.page || 0)
    const [list, setList] = useState([])
    const [visitedId] = useState(cadastro.state?.visitedId || [])

    const [searchConfig] = useState(newSearch({ config: searchData, values: cadastro.state?.search }))

    console.log(t(service?.pluralTitle))

    useEffect(() => {

        loadData(page, searchConfig)

    }, [service])

    function loadData(selPage, config) {

        const queryString = searchToQs({ config, selPage })

        service.list(queryString).then((response) => {

            const meta = response.data.meta
            console.log(response)

            setList(response.data.data)
            setTotalRows(meta.total)

        }).catch((error) => {
            console.log("Erro: ", error)
        })
    }

    function handleSearch(config) {
        loadData(0, config)
        setPage(0)
        setFirst(0)
    }
    function handleRowEdit(data) {

        visitedId.push(data[searchData.pkName])

        const url = service.getIdFrontUrl(data[searchData.pkName])
        navigate(url, { state: { search: searchConfig, page, first, visitedId } })

    }

    function handleRowDelete(data) {
        console.log(data)
        const pkValue = data[searchData.pkName]
        const dsValue = data[searchData.dsName] || data.meta[searchData.dsName]
        const dsItem = pkValue + '-' + dsValue

        confirmDialog({
            message: `Confirma excluir ${dsItem}?`,
            icon: 'pi pi-exclamation-triangle',

            accept: () => {

                service.delete(pkValue).then(() => {
                    const newValue = list.filter((e) => e[searchData.pkName] !== pkValue)
                    setList(newValue)
                    toast.current.show({ severity: 'info', summary: 'Deletado', detail: dsItem + ' deletado!', life: 3000 })
                }).catch((err) => {

                    const message = err.response.data
                    if (message) {
                        toast.current.show({ severity: 'error', summary: 'Ops!', detail: message, life: 3000 })
                    }

                })

            }

        })
    }

    function action(data) {
        return (
            <div>
                <Button className="mr-1" label="" icon="pi pi-pencil" size="small" onClick={() => handleRowEdit(data)} severity="success" text />
                <Button label="" icon="pi pi-trash" size="small" onClick={() => handleRowDelete(data)} severity="warning" text />
            </div>
        )
    }

    return (

        <Form title={t(service?.pluralTitle) || ''}>
            <Toast ref={toast} />
            <ConfirmDialog />
            <SearchBar
                searchConfig={searchConfig}
                onMainSearchClick={handleSearch}
                onAdvSearchClick={handleSearch}
                onNewClick={() => navigate(service.getNewFrontUrl())}
            />
            <DataTable
                className='mt-4'
                value={list}
                dataKey={searchData.pkName}
                size="small"
                showGridlines
                rows={perpage}
                tableStyle={{ minWidth: '1rem' }}
                totalRecords={totalRows}
                paginatorPosition="bottom"
                paginatorRight
                selectionMode="single"
                rowClassName={(rowData) => visitedId.includes(rowData[searchData.pkName]) ? 'bg-gray-50' : ''}
            >

                {children}
                {showActions && <Column body={action}></Column>}

            </DataTable>
            <Paginator

                first={first}
                rows={perpage}
                onPageChange={(event) => {
                    setFirst(event.first)
                    setRows(event.rows)
                    setPage(event.page)
                    loadData(event.page, searchConfig)
                }}
                totalRecords={totalRows}
                currentPageReportTemplate="Mostrando {first} à {last} de {totalRecords} linhas"
                template={{
                    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
                    CurrentPageReport: (options) => {
                        return (
                            <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                                {options.totalRecords} resultados
                            </span>
                        )
                    }
                }
                }

            />

        </Form >
    )
}
