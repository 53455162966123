export default function OutputSpanForm(props) {

    return (
        <div className={`flex flex-column ${props.className}`}>
            <div className={`p-inputtext-sm mb-1 text-sm ${props.classNameLabel}`}>{props.label}</div >
            <span className={`text-sm font-bold ${props.classNameField}`}>{props.value || props.form?.getValues(props.field)}</span>
        </div>
    )
}

/*
Label é usado apenas em formulário e associado com inputs, caso contrário ele vai gerar erros com associações de Id's
*/