import PessoaService from "business/cadastro/pessoa/pessoa/pessoaService"
import { isValidCnpj } from "helper/checker"
import { boolToNumber, jsDateToLuxonString, toJSDate } from "helper/formater"
import { justNumbers } from "helper/parser"
import * as yup from "yup"

export default class EmpresaService extends PessoaService {

    title = "Empresas do sistema"
    pluralTitle = "Empresas do sistema"
    url = '/configuracao/empresa'
    frontUrl = '/configuracao/empresa'
    frontUrlList = '/configuracao/empresalist'
    primaryKey = 'codigo_emp'
    ignoredsSchemaConvertions = ['cod_retri_pej', 'cod_retrie_pej', 'cod_rati_pej', 'cod_grup_pes', 'cod_catp_pes', 'cod_clap_pes']

    postDTO(data = {}) {

        data.pessoa_juridica.pessoa.tel_celular_pes = justNumbers(data.pessoa_juridica.pessoa.tel_celular_pes)
        data.pessoa_juridica.pessoa.tel_comercial_pes = justNumbers(data.pessoa_juridica.pessoa.tel_comercial_pes)

        return data

    }
    prepareToFront(data = {}) {

        console.log('prepareToFront()')

        data.ativa_emp = boolToNumber(data.ativa_emp)

        // Garante a presença de pessoa e pessoa_endereco no Novo
        data.pessoa_juridica = data.pessoa_juridica || { pessoa: {} }

        data.pessoa_juridica.cnpj_ativo_pej = 'cnpj_ativo_pej' in data ? boolToNumber(data.pessoa_juridica.cnpj_ativo_pej) : true
        data.pessoa_juridica.forn_pej = boolToNumber(data.pessoa_juridica.forn_pej)
        data.pessoa_juridica.forn_oficial_pej = boolToNumber(data.pessoa_juridica.forn_oficial_pej)
        data.pessoa_juridica.fidel_forn_pej = boolToNumber(data.pessoa_juridica.fidel_forn_pej)
        data.pessoa_juridica.conveniado_pej = boolToNumber(data.pessoa_juridica.conveniado_pej)
        data.pessoa_juridica.reter_picocs_pej = boolToNumber(data.pessoa_juridica.reter_picocs_pej)
        data.pessoa_juridica.pessoa.reter_iss_pes = boolToNumber(data.pessoa_juridica.pessoa.reter_iss_pes)
        data.pessoa_juridica.inc_fiscal_pej = boolToNumber(data.pessoa_juridica.inc_fiscal_pej)
        data.pessoa_juridica.inc_cult_pej = boolToNumber(data.pessoa_juridica.inc_cult_pej)

        data.pessoa_juridica.pessoa.pessoa_endereco = data.pessoa_juridica.pessoa?.pessoa_endereco || []
        data.pessoa_juridica.pessoa.cli_atacadista_pes = data.pessoa_juridica.pessoa.cli_atacadista_pes || 0
        data.pessoa_juridica.pessoa.blq_venda_ate_pes = toJSDate(data.pessoa_juridica.pessoa.blq_venda_ate_pes)
        data.pessoa_juridica.data_fundacao_pej = toJSDate(data.pessoa_juridica.data_fundacao_pej)

        if (data.pessoa_juridica.pessoa?.pessoa_endereco) {
            data.pessoa_juridica.pessoa.pessoa_endereco.forEach(e => {
                e.usa_desde_end = toJSDate(e.usa_desde_end)
            })
        }

        return data
    }

    async getById(args = {}) {

        args.includes = args.includes || 'main,pessoa_endereco_tipo'
        return super.getById(args).then((response) => {
            console.log('BACKEND: ', response)
            return response
        })

    }

    prepareToSave({ data }) {

        data.ativa_emp = boolToNumber(data.ativa_emp)

        data.pessoa_juridica.cod_retri_pej = data.pessoa_juridica.cod_retri_pej?.codigo_retri || null
        data.pessoa_juridica.cod_retrie_pej = data.pessoa_juridica.cod_retrie_pej?.codigo_retri || null
        data.pessoa_juridica.cod_rati_pej = data.pessoa_juridica.cod_rati_pej?.codigo_rati || null

        data.pessoa_juridica.forn_pej = boolToNumber(data.pessoa_juridica.forn_pej)
        data.pessoa_juridica.forn_oficial_pej = boolToNumber(data.pessoa_juridica.forn_oficial_pej)

        data.pessoa_juridica.cnpj_ativo_pej = boolToNumber(data.pessoa_juridica.cnpj_ativo_pej)
        data.pessoa_juridica.fidel_forn_pej = boolToNumber(data.pessoa_juridica.fidel_forn_pej)
        data.pessoa_juridica.conveniado_pej = boolToNumber(data.pessoa_juridica.conveniado_pej)
        data.pessoa_juridica.reter_picocs_pej = boolToNumber(data.pessoa_juridica.reter_picocs_pej)
        data.pessoa_juridica.pessoa.reter_iss_pes = boolToNumber(data.pessoa_juridica.pessoa.reter_iss_pes)
        data.pessoa_juridica.inc_fiscal_pej = boolToNumber(data.pessoa_juridica.inc_fiscal_pej)
        data.pessoa_juridica.inc_cult_pej = boolToNumber(data.pessoa_juridica.inc_cult_pej)

        data.pessoa_juridica.pessoa.blq_venda_ate_pes = jsDateToLuxonString(data.pessoa_juridica.pessoa.blq_venda_ate_pes)

        data.pessoa_juridica.pessoa.reter_iss_pes = boolToNumber(data.pessoa_juridica.pessoa.reter_iss_pes)
        data.pessoa_juridica.pessoa.cli_atacadista_pes = boolToNumber(data.pessoa_juridica.pessoa.cli_atacadista_pes)

        data.pessoa_juridica.pessoa.cod_grup_pes = data.pessoa_juridica.pessoa.cod_grup_pes?.codigo_grup || null
        data.pessoa_juridica.pessoa.cod_catp_pes = data.pessoa_juridica.pessoa.cod_catp_pes?.codigo_catp || null
        data.pessoa_juridica.pessoa.cod_clap_pes = data.pessoa_juridica.pessoa.cod_clap_pes?.codigo_clap || null

        data.pessoa_juridica.pessoa.pessoa_endereco.forEach(e => {
            e.cod_tend_end = e.cod_tend_end.codigo_tend
            e.cod_mun_end = e.cod_mun_end.codigo_mun
        })

        delete data.meta

        return data

    }

    async getSchema() {

        return super.getSchema().then((data) => {

            console.log('BIAS', data)

            if (data.fields.pessoa_juridica.fields.pessoa.fields) {

                data.fields.pessoa_juridica.fields.pessoa.fields.cpf_cnpj_pes = data.fields.pessoa_juridica.fields.pessoa.fields.cpf_cnpj_pes.test('cnpj', 'Cnpj incorreto!', isValidCnpj)
                data.fields.pessoa_juridica.fields.pessoa.fields.pessoa_endereco.innerType.fields.cod_tend_end = yup.object().typeError('Obrigatório').required('Obrigatório')
                data.fields.pessoa_juridica.fields.pessoa.fields.pessoa_endereco.innerType.fields.cod_mun_end = yup.object()
                    .test('municipio', 'Inválido', e => e.codigo_mun)
                    .typeError('Obrigatório')
                    .required('Obrigatório')

            }

            return data

        })

    }

}

