import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'

export default function AdvancedSearchBar(props) {

    const [fields, setFields] = useState(props.config.advFields)

    const handleValueChange = (index, newValue) => {
        const newFields = [...fields]
        newFields[index].value = newValue
        setFields(newFields)
    }

    return (

        <div className={`border-1 mb-2 p-2 border-100 surface-ground  ${props.barClassName}`} >

            <div className="formgrid grid p-fluid mt-0">

                {fields.map((field, index) => {
                    return (
                        <div key={index} className='field col-12 md:col-4 lg:col-3 xl:col-2 mb-2'>
                            <InputText
                                id={field.name}
                                className='p-inputtext-sm'
                                value={field.value ? field.value : ''}
                                onChange={(e) => handleValueChange(index, e.target.value)}
                                placeholder={field.label}
                                inputMode={field.inputMode}
                                autoComplete='off'
                                onKeyUp={data => {
                                    if (data.key === 'Enter') {
                                        props.config.searchType = 'adv'
                                        props.config.advFields = fields
                                        props.onSearchClick(props.config)

                                    }
                                }}
                            />
                        </div>

                    )
                })}

            </div>

            <div className='flex gap-2 justify-content-between'>

                <div>
                    <Button label="" severity="" icon='pi pi-eraser' text onClick={() => {
                        fields.map((e) => {
                            // e.type === 'string'
                            e.value = ''
                            return e
                        })
                        setFields([...fields])
                    }} />
                </div>
                <div className='flex gap-2'>


                    <Button label="Pesquisar" icon="pi pi-search" severity="" onClick={_ => {
                        props.config.searchType = 'adv'
                        props.config.advFields = fields
                        props.onSearchClick(props.config)
                    }} />
                    <Button label="Fechar" severity="" icon='pi pi-times' onClick={() => {
                        // props.config.searchType = 'main'
                        props.onCloseClick()
                    }} />
                </div>

            </div>

        </div >
    )
}

