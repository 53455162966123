const keyName = 'fizaquiGlobal'

function getDefaultData() {
    return {
        token: '',
        base: { descricao_bad: '', comment: 'Conteúdo apenas informativo, não é usado em requisições!' },
        empresa: { codigo_emp: 0, nome_razao_pes: '', nome_fantasia_pej: '', comment: 'Conteúdo apenas informativo, não é usado em requisições!' },
        usuario: {
            email_usu: '',
            img_usu: '',
            nome_usu: '',
            primeiroNome: '',
            meioNome: '',
            ultimoNome: '',
        }
    }
}

export function setData(newData) {

    const str = JSON.stringify(newData, (_, value) => value === undefined ? null : value)

    return localStorage.setItem(keyName, str)
}

export function getData() {

    const dataDef = getDefaultData()

    let globalData = JSON.parse(localStorage.getItem(keyName)) || {}

    dataDef.token = globalData?.token || ''
    dataDef.base.codnum_bad = globalData?.base?.codnum_bad || ''
    dataDef.base.descricao_bad = globalData?.base?.descricao_bad || ''
    dataDef.empresa.codigo_emp = globalData?.empresa?.codigo_emp || 0
    dataDef.empresa.nome_razao_pes = globalData?.empresa?.nome_razao_pes || ''
    dataDef.empresa.nome_fantasia_pej = globalData?.empresa?.nome_fantasia_pej || ''
    dataDef.usuario.email_usu = globalData?.usuario?.email_usu || ''
    dataDef.usuario.img_usu = globalData?.usuario?.img_usu || ''
    dataDef.usuario.nome_usu = globalData?.usuario?.nome_usu || ''
    dataDef.usuario.primeiroNome = globalData?.usuario?.primeiroNome || ''
    dataDef.usuario.meioNome = globalData?.usuario?.meioNome || ''
    dataDef.usuario.ultimoNome = globalData?.usuario?.ultimoNome || ''
    dataDef.menu = globalData?.menu || {}

    return dataDef

}

export function clear() {
    const data = getDefaultData()
    setData(data)
}
