/**
 *  save(id = null, data = {}) {
 *    return super.save(id, data)
 * }
 */
import ServiceBase from "base/baseService"
import PessoaService from "business/cadastro/pessoa/pessoa/pessoaService"
import { toJSDate } from "helper/formater"

export default class ProdutoService extends ServiceBase {

    title = "Produto"
    pluralTitle = "Produtos"
    url = '/cadastro/produto/produto'
    frontUrl = '/cadastro/produto/produto'
    frontUrlList = '/cadastro/produto/produtolist'
    primaryKey = "codigo_pro"

    postDTO(data = {}) {

        return {
            ...data
        }
    }
    prepareToFront(data = {}) {

        return {
            ...data,
            data_vencimento_pro: toJSDate(data.data_vencimento_pro),
            preco_atacado_pro: data.preco_atacado_pro ? Number(data.preco_atacado_pro) : data.preco_atacado_pro, // Nessessário apenas para controlar o isDirty do campo
            preco_custo_pro: data.preco_custo_pro ? Number(data.preco_custo_pro) : data.preco_custo_pro,
            preco_varejo_pro: data.preco_varejo_pro ? Number(data.preco_varejo_pro) : data.preco_varejo_pro,
            aliquota_pis_pro: data.aliquota_pis_pro ? Number(data.aliquota_pis_pro) : data.aliquota_pis_pro,
            aliquota_cofins_pro: data.aliquota_cofins_pro ? Number(data.aliquota_cofins_pro) : data.aliquota_cofins_pro,
        }
    }

    async list(qs = '') {

        return super.list(qs).then((response) => {
            response.data.data.map((e) => {
                if (e.data_vencimento_pro) {
                    e.data_vencimento_pro = new Date(e.data_vencimento_pro).toLocaleDateString('pt-BR')
                }
                return ''
            })
            return response
        })
    }

    // Get show
    async getById({ id = null }) {

        return super.getById({ id }).then((response) => {
            response.data.data_vencimento_pro = response.data.data_vencimento_pro ? new Date(response.data.data_vencimento_pro) : null
            response.data = this.prepareToFront(response.data)
            console.log('produtoService response: ', response)
            return response
        }).catch((error) => {
            console.log("Erro (service): ", error)
            return error
        })

    }

    async filterFornecedor({ search }) {

        // Tá errado isso aqui pois tem que trazer pessoas

        // const search = data.filter

        const queryString = `?page=1${'&nome_razao_pes=' + search}`

        return new PessoaService().list(queryString)

    }



}

