import ServiceBase from "base/baseService"
import { toJSDate } from "helper/formater"

export default class HobbyService extends ServiceBase {

    title = "Hobby"
    pluralTitle = "Hobbys"
    url = '/cadastro/pessoa/hobby'
    frontUrl = '/cadastro/pessoa/pessoafisica/hobby'
    frontUrlList = '/cadastro/pessoa/pessoafisica/hobbylist'
    primaryKey = 'codigo_hobb'

    postDTO(data = {}) {

        return {
            ...data,
        }

    }
    prepareToFront(data = {}) {
        return {
            ...data,
        }
    }

    static async list(qs = '') {

        return super.list(qs).then((response) => {
            return response
        })
    }

    // Get show
    async getById({ id = null }) {

        return super.getById({ id }).then((response) => {
            response.data = this.prepareToFront(response.data)
            console.log(response)
            return response
        }).catch((error) => {
            console.log("Erro (service): ", error)
            return error
        })

    }

}

