import React, { useEffect, useRef, useState } from 'react'
import { Column } from 'primereact/column'
import ProfissaoService from "./profissaoService"
import FormList from 'base/FormList'

export default function ProfissaoList() {

    const [service, setService] = useState()
    const toast = useRef(null)

    if (!service) {
        setService(new ProfissaoService())
    }

    const searchData = {
        pkName: 'codigo_prf',
        dsName: 'descricao_prf',
        mainField: { name: 'descricao_prf', label: 'Descrição' },
        advFields: [
            { name: 'codigo_prf', label: 'Código', type: 'integer', inputMode: 'numeric' },
            { name: 'descricao_prf', label: 'Descrição', type: 'string', inputMode: 'text' },
            { name: 'salario_prf', label: 'Salário', type: 'decimal', inputMode: 'decimal' },
        ]
    }

    useEffect(() => {
    }, [service])

    return (
        <FormList service={service} toast={toast} searchData={searchData} showActions={true}>
            <Column field="descricao_prf" sortable header="Descrição"></Column>
            <Column field="salario_prf" sortable header="Salário"></Column>
        </FormList >
    )
}