import axios from "axios"
import * as localStorage from "./localStorage.js"

export default function server(headerAdded) {

    const data = localStorage.getData()

    const instance = axios.create({
        // baseURL: 'http://0.0.0.0:3333/',
        baseURL: 'https://api.fizaqui.com/',
        timeout: 5000,
        headers: {
            'Content-Type': "application/json; charset=utf-8",
            'Authorization': "Bearer " + data.token,
            ...headerAdded
        }
    })
    instance.interceptors.response.use((response) => {
        return response
    }, (error) => {

        console.log('Erro (server): ', error)

        if (error.response && error.response.data && error.response.data.errors) {
            const reason = error.response.data.errors[0].message

            if (reason.includes('E_INVALID_API_TOKEN')) {
                alert("(Server) Token inválido!")
                window.location.href = '/login'
            }
        }

        return Promise.reject(error)

    })

    return instance

}