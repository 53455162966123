import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import InputFrame from "./InputFrame";

export default function InputNumberForm(props) {

    const field = props.controller.field
    const fieldState = props.controller.fieldState
    const form = props.form

    return (
        <>
            <InputFrame {...props}>
                <InputNumber
                    // {...field} // NaN mas precisa pra disparar o isDirty
                    // defaultValue={null}
                    inputId={field.name}
                    // name={field.name}
                    control={field.control}
                    inputRef={field.ref}
                    value={field.value}
                    onBlur={field.onBlur}
                    useGrouping={true}
                    // onValueChange={(e) => form.setValue(e.target.name, e.target.value)}
                    onValueChange={(e) => field.onChange(e)}
                    onChange={
                        (e) => {
                            form.setValue(e.target?.name, e.target?.value)

                        }
                    }
                    inputClassName={'p-inputtext-sm ' + (fieldState.isDirty ? '' : '') + classNames({ 'p-invalid': fieldState.error })}
                    minFractionDigits={props.minFractionDigits || 2}
                    maxFractionDigits={props.maxFractionDigits || 2}
                    locale='pt-BR'
                    prefix={props.prefix || ''}
                    suffix={props.suffix || ''}
                    pt={{ input: { root: { autoComplete: 'off' } } }}
                    onKeyDown={props.onKeyDown}
                />
            </InputFrame>
        </>
    )
}