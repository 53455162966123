import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import { InputText } from 'primereact/inputtext'
import ProdutoService from "business/cadastro/produto/produtoService"
import { formatIntegerToDecimal, showNull } from "helper/formater"
import Form from "base/Form"


export default function ProdutoList() {
    const [service] = useState(new ProdutoService())
    const cadastro = useLocation()
    const [produtoList, setProdutoList] = useState([])
    const toast = useRef(null)
    const navigate = useNavigate()
    const [first, setFirst] = useState(cadastro.state?.first || 0)
    const [page, setPage] = useState(cadastro.state?.page || 0)
    const [search, setSearch] = useState(cadastro.state?.search || '')
    const [perpage, setRows] = useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const [visitedId] = useState(cadastro.state?.visitedId || [])

    useEffect(() => {
        loadData(page)
    }, [])

    function loadData(selPage) {

        const queryString = `?page=${selPage + 1}&perpage=${perpage}&descricao_pro=${search}`

        service.list(queryString).then((response) => {

            const meta = response.data.meta

            setProdutoList(response.data.data)
            setTotalRows(meta.total)

        }).catch((error) => {
            console.log("Erro: ", error)
        })
    }

    function handleRowNew() {

        navigate(service.getNewFrontUrl())
    }

    function handleRowEdit(data) {
        visitedId.push(data.codigo_pro)
        navigate(service.getIdFrontUrl(data.codigo_pro), { state: { search, page, first, visitedId } })
    }
    function handleRowDelete(data) {

        confirmDialog({
            message: `Confirma excluir o item ${data.codigo_pro}-${data.descricao_pro}?`,
            icon: 'pi pi-exclamation-triangle',
            accept: () => {

                service.delete(data.codigo_pro).then((response) => {
                    const newValue = produtoList.filter((e) => e.codigo_pro != data.codigo_pro)
                    setProdutoList(newValue)
                    toast.current.show({ severity: 'info', summary: 'Deletado', detail: 'Produto deletado!', life: 3000 })
                }).catch((err) => {

                    const message = err.response.data
                    if (message) {
                        toast.current.show({ severity: 'error', summary: 'Ops!', detail: message, life: 3000 })
                    }

                })

            }

        })
    }

    function handleSearch(data) {
        loadData(0)
        setPage(0)
        setFirst(0)
    }
    function handleSearchKeyUp(data) {
        if (data.key === 'Enter') {
            handleSearch(search)
        }
    }

    function action(data) {
        return (
            <div>
                <Button className="mr-1" label="" icon="pi pi-pencil" size="small" onClick={() => handleRowEdit(data)} severity="success" text />
                <Button label="" icon="pi pi-trash" size="small" onClick={() => handleRowDelete(data)} severity="warning" text />
            </div>
        )
    }
    const dialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" />
            <Button label="Save" icon="pi pi-check" text />
        </>
    )
    function leftToolbarTemplate(data) {
        return (
            <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                </span>
                <InputText id="procurar" placeholder="Procurar" inputMode="search" value={search} onChange={(e) => setSearch(e.target.value)}
                    onKeyUp={handleSearchKeyUp}
                    className='mr-0' />
                <Button label="Pesquisar" icon="pi pi-search" onClick={() => handleSearch(search)} />
            </div>
        )
    }
    function rightToolbarTemplate(data) {
        return (
            <>
                <Button label="Novo" icon="pi pi-plus" severity="success" onClick={handleRowNew} outlined />
            </>
        )

    }
    return (
        <Form title={service.pluralTitle}>

            <ConfirmDialog />
            <Toast ref={toast} />
            <div>
                <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>
                <DataTable value={produtoList} dataKey="codigo_pro" size="small" showGridlines
                    rows={perpage}
                    tableStyle={{ minWidth: '1rem' }}
                    totalRecords={totalRows}
                    paginatorPosition="bottom"
                    paginatorRight
                    selectionMode="single"
                    rowClassName={(rowData) => visitedId.includes(rowData.codigo_pro) ? 'bg-gray-50' : ''}
                >

                    <Column field="codigo_pro" sortable header="Código"></Column>
                    <Column field="descricao_pro" sortable header="Descrição"></Column>
                    <Column field="codigo_barra_pro" sortable
                        body={(e) => showNull(e.codigo_barra_pro)}
                        header="Código de barras"></Column>
                    <Column field="data_vencimento_pro" sortable header="Vencimento"></Column>
                    <Column field="preco_custo_pro" sortable header="Custo"
                        body={(e) => formatIntegerToDecimal(e.preco_custo_pro)}
                        bodyStyle={{ textAlign: 'right', overflow: 'visible' }}
                    ></Column>
                    <Column field="preco_varejo_pro" sortable header="Varejo"
                        body={(e) => formatIntegerToDecimal(e.preco_varejo_pro)}
                        bodyStyle={{ textAlign: 'right', overflow: 'visible' }}
                    ></Column>
                    <Column field="preco_atacado_pro" sortable header="Atacado"
                        body={(e) => formatIntegerToDecimal(e.preco_atacado_pro)}
                        bodyStyle={{ textAlign: 'right', overflow: 'visible' }}
                    ></Column>
                    {/* <Column field="aliquota_pis_pro" sortable header="PIS %"
                        body={(e) => formatIntegerToDecimal(e.aliquota_pis_pro)}
                        bodyStyle={{ textAlign: 'right', overflow: 'visible' }}
                    ></Column>
                    <Column field="aliquota_cofins_pro" sortable header="COFINS %"
                        body={(e) => formatIntegerToDecimal(e.aliquota_cofins_pro)}
                        bodyStyle={{ textAlign: 'right', overflow: 'visible' }}
                    ></Column> */}
                    <Column body={action}></Column>

                </DataTable>
                <Paginator

                    first={first}
                    rows={perpage}
                    onPageChange={(event) => {
                        setFirst(event.first)
                        setRows(event.rows)
                        setPage(event.page)
                        loadData(event.page)
                    }}
                    totalRecords={totalRows}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    template={{
                        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
                        CurrentPageReport: (options) => {
                            return (
                                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                                    {options.totalRecords} resultados
                                </span>
                            )
                        }
                    }
                    }

                />

            </div >
        </Form >
    );
}