import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import ConfigService from './configService'
import { yupResolver } from "@hookform/resolvers/yup"
import FormCadastro, { FormCadastroAux } from 'base/FormCadastro'
import { Toast } from 'primereact/toast'
import { Message } from 'primereact/message'
import GridForm from 'base/GridForm'
import ControllerInput from 'base/ControllerInput'
import { DataView, DataViewLayoutOptions } from 'primereact/dataview'
import { formatDatetime } from 'helper/formater'
import { Divider } from 'primereact/divider'


export default function Config() {

    const [service] = useState(new ConfigService())
    const toast = useRef(null)
    const { id } = useParams()
    const [validations, setValidations] = useState([]);
    const [logs, setLogs] = useState([]);

    const form = useForm({
        defaultValues: async () => await load(),
        resolver: yupResolver(validations)
    })

    async function load() {

        const data = await FormCadastroAux.load({ id, service: service, toast, useNew: true, beforeSubmit })

        console.log(data)
        setLogs(data.meta.usuario_hist_acao)

        return data
    }

    const beforeSubmit = (data) => {

        return data
    }

    useEffect(() => {
        service.getSchema().then(setValidations)
    }, [])

    function getDescricao() {
        const field = form.getValues('config_numero')
        if (field) return field[0].descricao_num
        return ''
    }
    function getDocumentacao() {
        const field = form.getValues('config_numero')
        if (field) return field[0].documentacao_num
        return ''
    }
    function getTipoDeInput() {
        const field = form.getValues('config_numero')
        if (field) {
            if (field[0].cod_tid_num === 'boolean') {
                console.log('?switch')
                return 'switch'
            }
        }
        console.log('?textarea')

        return 'textarea'

    }

    const logItemTemplate = (log) => {
        return (
            <div className="flex flex-column mt-3 md:col-12 lg:col-12 xl:col-12">
                <div className='mr-2'>
                    <strong className='mr-2'>
                        {formatDatetime(log.criado_hisa)}:
                    </strong>
                    {log.descricao_hisa}
                </div>
                <div className='text-sm font-italic'>
                    <span>{log.meta.nome_razao_usu}</span> -
                    <span className='ml-2'>{log.meta.nome_razao_emp}</span>
                </div>
            </div>
        )
    }

    return (

        < FormCadastro service={service} form={form} aux={FormCadastroAux} beforeSubmit={beforeSubmit}>
            <Toast ref={toast} />
            <GridForm>
                <div className='p-grid p-flex-row md:col-6 lg:col-6 xl:col-7'>

                    <ControllerInput
                        field="valor_conf"
                        form={form}
                        service={service}
                        type={getTipoDeInput()}
                        label={getDescricao()}
                        rows={5}
                        className={'mb-1'}
                    />
                    <ControllerInput
                        field="meta.descricao_hisa"
                        form={form}
                        service={service}
                        type="textarea"
                        label="Motivo da alteração"
                        rows={5}

                    />

                    {/* <InputSwitch className='text-xl' checked={checked} onChange={(e) => setChecked(e.value)} /> */}

                    {/* <Controller
                        name="checked"
                        control={form.control}
                        rules={{ required: 'Accept is required.' }}
                        render={({ field, fieldState }) => (
                            <>
                                <label htmlFor={field.name} >Lampada</label>
                                <InputSwitch
                                    inputId={field.name}
                                    // checked={field.value}
                                    inputRef={field.ref}
                                    className={classNames({ 'p-invalid': fieldState.error })}
                                    onChange={(e) => field.onChange(e.value)}
                                />
                            </>
                        )}
                    />

                    <ControllerInput
                        field="valor_conf"
                        form={form}
                        service={service}
                        type='switch'
                        label='liquidificador'
                        rows={5}
                    /> */}

                    <div className='font-semibold mt-3'>
                        Alterações:
                    </div>

                    <DataView
                        value={logs}
                        layout={DataViewLayoutOptions.List} // Escolha o layout desejado (Grid, List, etc.)
                        itemTemplate={logItemTemplate}
                        paginator
                        rows={6}
                    />

                </div>
                <Divider layout="vertical" type='dashed' className='hidden md:flex' />
                <div className='p-grid p-flex-row md:col-6 lg:col-6 xl:col-5'>
                    <Message
                        text={getDocumentacao()}
                        severity="info"
                        className='flex gap-2 p-2'
                    />
                </div>
            </GridForm>

            {/* {JSON.stringify(form.getValues('config_numero')[0].descricao_num)} */}

        </FormCadastro >
    )
}

