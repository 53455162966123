import Form from "../../Form";
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

export default function Estoque() {
    const [preco, setPreco] = useState(0)

    const handleChangeCurrency = (e) => {
        setPreco(formatCurrencyToDecimal(e.target.value))
    }

    return (

        <Form title="Estoque">

            <div className="FormItem">
                <label htmlFor="name">Nome:</label>
                <input type="text" name="name" />
            </div>
            <div className="FormItem">
                <label htmlFor="localidade">Localidade:</label>
                <input type="text" name="localidade" />
            </div>

            <p>
                <p>Preço: {preco}</p>
                <InputText
                    type="text"
                    value={formatCurrencyToDecimal(preco)}
                    onChange={handleChangeCurrency}
                    inputMode="decimal"
                />
                <InputText
                    type="number"
                    value={preco}
                    onChange={(e) => setPreco(e.target.value)}
                />
            </p>
            <Button label="Salvar" icon="pi pi-check" />
            <Button label="Cancelar" icon="pi pi-check" severity="success" />

        </Form>

    )
}

function formatCurrencyToDecimal(value) {
    const rawValue = justNumbers(value)
    const num = Number(rawValue) * 0.01
    return num.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
}

function justNumbers(value) {
    if (value) {
        const onlyNumber = /[^0-9]/g
        return value.toString().replace(onlyNumber, '')
    }
    return ''

}