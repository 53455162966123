import { InputSwitch } from "primereact/inputswitch";
import { classNames } from "primereact/utils";
import InputFrame from "./InputFrame";

export default function InputTextareaForm(props) {

    const field = props.controller.field
    const fieldState = props.controller.fieldState

    return (
        <>
            <InputFrame {...props}>
                <div className="flex flex-row">
                    <InputSwitch
                        inputId={field.name}
                        {...field}
                        checked={field.value === '1' || field.value === 1 || field.value === true}
                        {...props}
                        className={classNames(props.className + ' mr-2', { 'p-invalid': fieldState.error })}
                    />
                    {/* <label htmlFor={field.name} className='text-sm'>{props.label} {(required && <b>*</b>)}</label> */}
                </div>

            </InputFrame>
        </>
    )
}
