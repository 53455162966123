import { Calendar } from "primereact/calendar";
import { classNames } from "primereact/utils";
import InputFrame from "./InputFrame"

export default function InputCalendarForm(props) {

    const field = props.controller.field
    const fieldState = props.controller.fieldState

    return (
        <>
            <InputFrame {...props}>
                <Calendar
                    inputId={field.name}
                    {...field}
                    locale="pt"
                    className={'p-calendar-sm text-sm ' + (fieldState.isDirty ? '' : '') + classNames({ 'p-invalid': fieldState.error })}
                    dateFormat="dd/mm/yy"
                    showIcon
                    hideOnDateTimeSelect
                    showOnFocus={false}
                    mask={props.mask || "99/99/9999"}
                    showButtonBar
                    inputMode={props.inputMode}
                    showTime={props.showTime}
                    hourFormat={props.hourFormat}
                />
            </InputFrame>
        </>
    )
}