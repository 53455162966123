import ServiceBase from "base/baseService"
import server from "infra/server"

export default class loginService extends ServiceBase {
    url = '/cadastro/login'
    frontUrl = '/cadastro/login'

    async getMenu() {

        console.log("Lendo menu da API...")
        return await server().get('cadastro/pessoa/usuario/menu').then((response) => {
            return this.menuToTree({ menus: response.data })
        }).catch((error) => {
            console.log("Erro: ", error)
        })

    }

    menuToTree({ menus }) {

        menus.map((e) => {
            e['label'] = e.descricao_men
            e['icon'] = e.icone_men
            e['route'] = e.rota_web_men
            delete e.descricao_men
            delete e.codigo_men
            delete e.oculto_men
            delete e.rota_web_men
            delete e.icone_men
            delete e.rota_web_men

            if (e.route == null) {
                delete e.route
            }
        })

        console.log("menuToTree menus: ", menus)
        const arvore = [];

        for (const menu of menus) {
            const partesOrdem = menu.ordem_men.split('.');
            let nodoAtual = arvore;

            for (let index = 0; index < partesOrdem.length; index++) {
                const parte = partesOrdem[index];

                if (!nodoAtual.find(node => node.ordem === parte)) {
                    const novoNodo = {
                        ordem: parte,
                        ordem_men: menu.ordem_men,
                        label: index === partesOrdem.length - 1 ? menu.label : null,
                        icon: menu.icon,
                        route: menu.route,
                        items: [],
                    };
                    nodoAtual.push(novoNodo);
                }

                nodoAtual = nodoAtual.find(node => node.ordem === parte).items;
            }
        }

        return arvore;
    }

    menuToTree_old({ menus, subItemName = 'items' }) {

        console.log("menuToTree: ", menus)

        menus.map((e) => {
            e['label'] = e.descricao_men
            e['icon'] = e.icone_men
            e['route'] = e.rota_web_men
            delete e.descricao_men
            delete e.codigo_men
            delete e.oculto_men
            delete e.rota_web_men
            delete e.icone_men
            delete e.rota_web_men

            if (e.route == null) {
                delete e.route
            }
        })

        console.log("menuToTree2: ", menus)
        console.log("menuToTree str>>: ", JSON.stringify(menus))

        let text = ''
        let last = menus[0]
        let nivel = 0

        for (let i = 0; i < menus.length; i++) {
            console.log("i/lenght: ", i, i + 1)

            const menu = menus[i]
            const proximoMaior = menus[i].ordem_men.length > last.ordem_men.length
            const proximoMenor = menus[i].ordem_men.length < last.ordem_men.length
            last = menus[i]

            if (proximoMaior) {
                nivel++
                console.log("Sobe ", nivel)

                text += ',"items":[' + JSON.stringify(menu)
            } else if (proximoMenor) {
                nivel--
                console.log("Desce ", nivel)
                text += ']},' + JSON.stringify(menu)
            } else {
                console.log("Mantém")
                text += JSON.stringify(menu) + ','
            }

        }

        const closeBrackets = ']}'.repeat(nivel)
        console.log('closeBrackets: ', closeBrackets)


        console.log("Final ", nivel)

        console.log("menuToTree text: ", text)

        text = '[' + text + closeBrackets + ']'
        text = text.replace(/"},"items"/g, '","items"');
        text = text.replace(/},,"items"/g, ',"items"');
        text = text.replace(/}{/g, '},{');
        text = text.replace(/},]/g, '}]');

        console.log("menuToTree text2: ", text)

        return JSON.parse(text)

    }

}

