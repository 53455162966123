import { justNumbers } from "./parser"

/**
 * Checa se o CPF é válido
 */
export function isValidCpf(strCPF) {

    strCPF = justNumbers(strCPF)

    let soma = 0
    let resto

    const invalids = [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
    ]

    if (invalids.includes(strCPF)) return false

    for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11

    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(strCPF.substring(9, 10))) return false

    soma = 0
    for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
    resto = (soma * 10) % 11

    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(strCPF.substring(10, 11))) return false

    return true

}

export function isValidCnpj(strCnpj) {

    // Remove todos os caracteres que não são dígitos
    strCnpj = strCnpj.replace(/[^\d]+/g, '')

    const invalids = [
        '00000000000000',
        '11111111111111',
        '22222222222222',
        '33333333333333',
        '44444444444444',
        '55555555555555',
        '66666666666666',
        '77777777777777',
        '88888888888888',
        '99999999999999',
    ]

    if (invalids.includes(strCnpj)) return false

    // Verifica se o CNPJ tem 14 dígitos
    if (strCnpj.length !== 14) return false

    // Elimina CNPJs conhecidos como inválidos
    if (/^(\d)\1+$/.test(strCnpj)) return false

    // Valida os dois dígitos verificadores
    let tamanho = strCnpj.length - 2
    let numeros = strCnpj.substring(0, tamanho)
    let digitos = strCnpj.substring(tamanho)
    let soma = 0
    let pos = tamanho - 7

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) pos = 9
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado != digitos.charAt(0)) return false

    tamanho = tamanho + 1
    numeros = strCnpj.substring(0, tamanho)
    soma = 0
    pos = tamanho - 7

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--
        if (pos < 2) pos = 9
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    if (resultado != digitos.charAt(1)) return false

    return true
}