import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

i18n
    .use(initReactI18next)
    .init({
        debug: false,
        resources: {
            en: {
                translation: {
                    "Pessoas jurídicas": "Legal entities",
                    "PESSOA JURÍDICA": "LEGAL ENTITY",
                    "perfilHelp": `
                        User profile registration: Define a description, for example: Salesperson.
                        Select menu options and permissions,
                        Uncheck buttons next to menus to restrict access.
                        Save this registration and enter it in the user registration.
                        After that, the user must log in again for all changes made here to take effect.
                        User profile registration is not per company, so if a seller profile has different permissions
                        in another company then you must create a 'Salesperson 2' profile for that user.
                        Take a good look: In each company the user can have a different profile!`,
                    "Perfis de usuário": "User profiles",
                }
            },
            pt_br: {
                translation: {
                    "Pessoas jurídicas": "Pessoas jurídicas",
                    "PESSOA JURÍDICA": "PESSOA JURÍDICA",
                    "perfilHelp": `
                        Cadastro de perfil de usuário: Defina uma descrição, por exemplo: Vendedor. 
                        Selecione as opções de menu e permissões,
                        Desmarque botões ao lado de menus para restringir o acesso.
                        Salve esse cadastro e informe- o no cadastro de usuário.
                        Após isso o usuário deverá fazer login novamente para que todas as alterações aqui feitas vigorem.
                        O cadastro de perfil de usuário não é por empresa, então caso um perfil de vendedor tenha permissões diferentes 
                        em outra empresa então você deve criar um perfil 'Vendedor 2' para esse usuário.
                        Veja bem: Em cada empresa o usuário pode ter um perfil diferente!`,
                    "Perfis de usuário": "Perfis de usuário",
                }
            },
        },
        lng: "en", // linguagem padrão
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react já faz a proteção contra XSS
        }
    })

export default i18n
