import ServiceBase from "base/baseService"
import { boolToNumber } from "helper/formater"
import { jsDateToLuxonString } from "helper/formater"

export default class RegimeTributacaoService extends ServiceBase {

    title = "Regime de tributação"
    pluralTitle = "Regime de tributações"
    url = '/cadastro/pessoa/regimetributacao'
    frontUrl = '/cadastro/pessoa/regimetributacao'
    frontUrlList = '/cadastro/pessoa/regimetributacaolist'
    primaryKey = 'codigo_retri'
    ignoredsSchemaConvertions = []

    prepareToFront(data = {}) {

        data.direito_cred_retri = boolToNumber(data.direito_cred_retri)
        data.especial_retri = boolToNumber(data.especial_retri)

        return data
    }

    prepareToSave({ data }) {

        data.atualizado_retri = jsDateToLuxonString(data.atualizado_retri)

        data.direito_cred_retri = boolToNumber(data.direito_cred_retri)
        data.especial_retri = boolToNumber(data.especial_retri)

        delete data.meta

        return data

    }

}
