import { classNames } from "primereact/utils";
import InputFrame from "./InputFrame"
import { AutoComplete } from 'primereact/autocomplete';

export default function InputAutoCompleteForm(props) {

    const field = props.controller.field
    const fieldState = props.controller.fieldState

    return (
        <>
            <InputFrame {...props}>
                <AutoComplete
                    {...field}
                    inputId={field.name}
                    suggestions={props.suggestions}
                    completeMethod={props.completeMethod}
                    className={'p-inputtext-sm ' + (fieldState.isDirty ? 'bg-green-100' : '') + classNames({ 'p-invalid': fieldState.error })}
                    size='small'
                    field={props.fieldShow}
                    autoComplete={props.autoComplete || "off"}
                    forceSelection={props.forceSelection || true}
                />
            </InputFrame>
        </>
    )
}