import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import CategoriaPessoaService from 'business/cadastro/pessoa/categoriaPessoa/categoriaPessoaService'
import ControllerInput from 'base/ControllerInput'
import GridForm from 'base/GridForm'
import { yupResolver } from '@hookform/resolvers/yup'
import FormCadastro, { FormCadastroAux } from 'base/FormCadastro'
import { TabView, TabPanel } from 'primereact/tabview'
import { defaultEmptySelection } from 'helper/default'
import { errorToToast } from 'helper/error'
import TipoPessoaService from 'business/cadastro/pessoa/tipoPessoa/tipoPessoaService'

export default function CategoriaPessoa() {

    const [service, setService] = useState()

    if (!service) {
        setService(new CategoriaPessoaService())
    }

    const stepperRef = useRef(null)
    const toast = useRef(null)
    const { id } = useParams()
    const [validations, setValidations] = useState([])
    const [tipoPessoaList, setTipoPessoaList] = useState([])

    const form = useForm({
        defaultValues: async () => await load(),
        resolver: yupResolver(validations),
        mode: "onBlur"
    })

    async function load() {
        const data = await FormCadastroAux.load({ id, service, toast, beforeSubmit, useNew: true })
        if (data.tipo_pessoa) {
            const tipoPessoa = { codigo_tip: data.tipo_pessoa?.codigo_tip, descricao_tip: data.tipo_pessoa?.descricao_tip }
            setTipoPessoaList([tipoPessoa])
            data.cod_tip_catp = tipoPessoa
        }
        return data
    }

    const beforeSubmit = (data) => {
        let submitData = structuredClone(data)
        submitData = service.prepareToSave({ data: submitData })
        return submitData
    }

    useEffect(() => {
        service.getSchema().then(e => {
            setValidations(e)
        })
    }, [service])

    const loadTipoPessoaList = async () => {
        if (tipoPessoaList.length < 2) {
            const tip = new TipoPessoaService()
            await tip.list('').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_tip: e.codigo_tip, descricao_tip: e.descricao_tip }))
                data.push({ codigo_tip: null, descricao_tip: defaultEmptySelection() })
                setTipoPessoaList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    return (
        < FormCadastro service={service} form={form} aux={FormCadastroAux} toast={toast} beforeSubmit={beforeSubmit}>

            <TabView ref={stepperRef} className='text-sm' style={{ flexBasis: '1rem' }}>

                <TabPanel header="Capa" leftIcon="pi pi-home mr-2">

                    <GridForm>

                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-4" field="descricao_catp" form={form} service={service} type="text" clearString label="Descrição" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="cod_tip_catp" form={form} service={service} type="dropdown" label="Tipo de pessoa"
                            optionLabel='descricao_tip'
                            options={tipoPessoaList}
                            onClick={loadTipoPessoaList}
                        />

                    </GridForm>

                </TabPanel>

            </TabView>

        </FormCadastro>
    )
}
