export function generateCPF() {

    let cpf = ''
    for (let i = 0; i < 9; i++) {
        cpf += Math.floor(Math.random() * 10)
    }
    let d1 = 0
    for (let i = 0; i < 9; i++) {
        d1 += cpf[i] * (10 - i)
    }
    d1 = 11 - (d1 % 11);
    if (d1 >= 10) d1 = 0
    cpf += d1
    let d2 = 0
    for (let i = 0; i < 10; i++) {
        d2 += cpf[i] * (11 - i)
    }
    d2 = 11 - (d2 % 11)
    if (d2 >= 10) d2 = 0
    cpf += d2

    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

}

export function generateCNPJ() {

    // Gera os primeiros 8 dígitos aleatoriamente
    let cnpj = ''
    for (let i = 0; i < 8; i++) {
        cnpj += Math.floor(Math.random() * 10).toString()
    }

    // Adiciona os quatro dígitos fixos "0001"
    cnpj += '0001'

    // Gera o primeiro dígito verificador
    let soma = 0
    let multiplicadores = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]

    for (let i = 0; i < 12; i++) {
        soma += parseInt(cnpj[i], 10) * multiplicadores[i]
    }

    let resto = soma % 11
    const digito1 = resto < 2 ? 0 : 11 - resto
    cnpj += digito1.toString()

    // Gera o segundo dígito verificador
    soma = 0
    multiplicadores.unshift(6)

    for (let i = 0; i < 13; i++) {
        soma += parseInt(cnpj[i], 10) * multiplicadores[i]
    }

    resto = soma % 11
    const digito2 = resto < 2 ? 0 : 11 - resto
    cnpj += digito2.toString()

    return cnpj
}
