import { getMessageFromFormErrors } from "helper/error";

export default function InputFrame(props) {

    if (props) {
    } else {
        console.log("Props está vazio: ", props)
    }

    const errors = props.form.formState.errors
    const field = props.controller.field
    const service = props?.service || false
    const partsName = field.name.split('.')
    const justName = partsName[partsName.length - 1]
    const required = service.requiredFields && service.requiredFields.includes(justName)
    const children = props.children
    const requiredColor = required ? 'text-blue-500' : ''

    // if (field.name) {
    //     console.log('InputTextForm - requiredFields: ', props.label, service.requiredFields, ' name: ', field.name, justName, required, '  Dirty: ', fieldState.isDirty, '  error: ', fieldState.error, '    erros: ', errors, ' getMessageFromFormErrors:errors,  ', getMessageFromFormErrors(errors, field.name))
    // }

    const getFormErrorMessage = (name) => {
        const message = getMessageFromFormErrors(errors, name)
        return message ? <small className="p-error">{message}</small> : <small className="p-error"></small>
    }
    return (
        <>
            <label htmlFor={field.name} className={`mb-1 text-sm ${requiredColor}`} >{props.label} {(required && <b>*</b>)}</label>
            {children}
            {getFormErrorMessage(field.name)}
        </>
    )
}
