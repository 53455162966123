import * as yup from "yup"

/**
 * 
 * @param {ignoreds} Array of string with field names to be ignoreds in the convertion from backend to frontend schema
 */
export function adonisToYup({ fields, ignoreds, messages, parentName = '' }, { onRulesScan = _ => false }) {

    const rs = {}

    for (const field in fields) {

        if (ignoreds.includes(field))
            continue

        const value = fields[field]

        if (value.type === 'object') {
            rs[field] = yup.object(adonisToYup({ fields: value.children, ignoreds, parentName: parentName + field }, { onRulesScan }))
        } else if (value.type === 'array') {
            rs[field] = yup.array().of(yup.object().shape(adonisToYup({ fields: value.each?.children || {}, ignoreds, parentName: parentName + field }, { onRulesScan })))
        } else if (value.type === 'literal') {

            if (value.subtype === 'string') {
                rs[field] = yup.string()
            } else if (value.subtype === 'number') {
                rs[field] = yup.number()
            } else if (value.subtype === 'date') {
                rs[field] = yup.date()
            } else if (value.subtype === 'boolean') {
                rs[field] = yup.boolean()
            } else {
                console.error(`File: baseService.js, Method: adonisToYup, tipo ${value.subtype} do campo [${field}] ainda não previsto no conversor de schema`, value)
                continue
            }
        }

        if (value?.optional)
            rs[field] = rs[field].optional()

        if (value?.nullable)
            rs[field] = rs[field].nullable()

        for (const rule of value.rules) {

            if (typeof onRulesScan == 'function') {
                onRulesScan(rule, field, value)
            }
            if (rule.name === 'required') {
                rs[field] = rs[field].required('Obrigatório')
            } else if (rule.name === 'maxLength') {
                rs[field] = rs[field].max(rule.compiledOptions.maxLength, `Deve ter o tamanho máximo de ${rule.compiledOptions.maxLength}`)
            } else if (rule.name === 'minLength') {
                rs[field] = rs[field].min(rule.compiledOptions.minLength, `Deve ter o tamanho mínimo de ${rule.compiledOptions.minLength}`)
            } else if (rule.name === 'email') {
                rs[field] = rs[field].email('E-mail com formato incorreto')
            } else if (rule.name === 'unsigned') {
                rs[field] = rs[field].min(0, 'Deve ser positivo')
            } else if (rule.name === 'regex') {
                rs[field] = rs[field].matches(rule.compiledOptions.pattern, 'Inválido')
            } else if (rule.name === 'range') {
                rs[field] = rs[field].min(rule.compiledOptions.start, 'Deve ser maior')
                rs[field] = rs[field].max(rule.compiledOptions.stop, 'Deve ser menor')
            } else if (rule.name === 'startUppercase') {
                rs[field] = rs[field].test('startUppercase', 'Deve começar com maiúsculo', value => {
                    if (!value) return true
                    return value[0] === value[0].toUpperCase()
                })
            } else if (rule.name === 'generalRegistration') {
                rs[field] = rs[field].matches(/^([A-Z0-9./-]*)$/, 'Maiúsculo, número e pontuação')
            } else if (rule.name === 'numberString') {
                rs[field] = rs[field].matches(/^\d*$/, 'Apenas números')
            } else if (rule.name === 'equalsIfAlpha') {
                rs[field] = rs[field].test('equalsIfAlpha', 'Aceita ' + rule.compiledOptions[0] + ' ou outros', value => {

                    if (typeof value !== 'string') {
                        return
                    }

                    // Extrai letras da string
                    const word = (value.match(/([A-Z]+)/g) || []).join('')

                    if (word) {
                        if (word !== rule.compiledOptions[0] || word !== value) {
                            return false
                        }
                    }

                    return true
                })
            } else if (rule.name === 'beforeOrEqual' || rule.name === 'before') {
                const limitDate = new Date()
                limitDate.setDate(limitDate.getDate() - rule.compiledOptions.offset.duration)
                rs[field] = rs[field].max(limitDate, 'Deve ser menor que ' + limitDate.toLocaleString())
            } else if (rule.name === 'afterOrEqual' || rule.name === 'after') {
                const limitDate = new Date()
                limitDate.setDate((limitDate.getDate() - rule.compiledOptions.offset.duration) * -1)
                rs[field] = rs[field].min(limitDate, 'Deve ser maior que ' + limitDate.toLocaleString())
            }
        }

    }

    return rs

}