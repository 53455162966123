import { justNumbers } from "helper/parser"
import { DateTime } from "luxon"

export function formatCurrency(value, minimumFractionDigits = 2) {

    const rawValue = justNumbers(value)

    if (typeof value !== 'string') {
        console.warn(value + ' param need to be string!')
    } else if (minimumFractionDigits !== 2 && minimumFractionDigits !== 3 && minimumFractionDigits !== 3) {
        console.warn('minimumFractionDigits param need to be 2|3|4!')
    }

    let num = 0

    switch (minimumFractionDigits) {
        case 2:
            num = Number(rawValue) / 100
            break
        case 3:
            num = Number(rawValue) / 1000
            break
        case 4:
            num = Number(rawValue) / 10000
            break
    }
    return num.toLocaleString('pt-BR', { style: 'currency', curency: 'BRL', minimumFractionDigits })
}

export function formatCurrencyToDecimal(value) {
    const num = Number(justNumbers(value)) * 0.01
    return Number(num.toFixed(2))
}

export function formatIntegerToCurrency(value) {
    const num = Number(value)
    return num.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}
export function formatIntegerToDecimal(value) {
    if (value == null) {
        return <b>NULL</b>
    }

    const num = Number(value)
    return num.toLocaleString('pt-BR', { minimumFractionDigits: 2 })
}

export function formatDatetime(value) {
    const dataLuxon = DateTime.fromISO(value)
    return dataLuxon.toFormat('dd/MM/yyyy HH:mm')
}
export function toJSDate(value) {
    return value ? new Date(value) : null
}

export function showNull(value) {
    if (value == null) {
        return <b>NULL</b>
    }
    return value
}

export function getMask(name = "") {

    switch (name) {
        case 'cellphone': return '(99) 99999-999?9'
        case 'cpf': return '999.999.999-99'
        case 'cnpj': return '99.999.999/9999-99'
        case 'cep': return '99.999-999'
        case 'datetime': return '99/99/9999 99:99'
    }
    return ''
}

export function boolToNumber(value) {
    if (value === null || value === undefined) return null
    return value ? 1 : 0
}

export function formatCellphone(telefone = '') {

    if (telefone) {

        telefone = telefone.replace(/\D/g, '')
        const nonoDigito = telefone.length > 10 ? '9' : ''
        return telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3' + nonoDigito)
    }

    return telefone

}

export function formatCPF(cpf = '') {

    cpf = cpf.replace(/\D/g, '')
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

}
export function formatCNPJ(cnpj = '') {

    cnpj = cnpj.replace(/\D/g, '')
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')

}

export function jsDateToLuxonString(value) {
    if (value) {
        const newOne = DateTime.fromJSDate(value).toString()
        if (newOne.includes('Invalid')) {
            console.error(`Error '${newOne}' trying to convert '${value}' to string Luxon, the date must be in JSDate format!`)
        }
        return newOne
    }
    return null
}
