import PessoaService from "business/cadastro/pessoa/pessoa/pessoaService"
import { isValidCnpj } from "helper/checker"
import { boolToNumber, jsDateToLuxonString, toJSDate } from "helper/formater"
import { justNumbers } from "helper/parser"
import * as yup from "yup"

export default class PessoaJuridicaService extends PessoaService {

    title = "Pessoa jurídica"
    pluralTitle = "Pessoas jurídicas"
    url = '/cadastro/pessoa/pessoajuridica'
    frontUrl = '/cadastro/pessoa/pessoajuridica'
    frontUrlList = '/cadastro/pessoa/pessoajuridicalist'
    primaryKey = 'codigo_pej'
    ignoredsSchemaConvertions = ['cod_retri_pej', 'cod_retrie_pej', 'cod_rati_pej', 'cod_grup_pes', 'cod_catp_pes', 'cod_clap_pes']

    postDTO(data = {}) {

        data.pessoa.tel_celular_pes = justNumbers(data.pessoa.tel_celular_pes)
        data.pessoa.tel_comercial_pes = justNumbers(data.pessoa.tel_comercial_pes)

        return data

    }
    prepareToFront(data = {}) {

        console.log('prepareToFront()')

        // Garante a presença de pessoa e pessoa_endereco no Novo
        data.pessoa = data.pessoa || {}

        data.cnpj_ativo_pej = 'cnpj_ativo_pej' in data ? boolToNumber(data.cnpj_ativo_pej) : true
        data.forn_pej = boolToNumber(data.forn_pej)
        data.forn_oficial_pej = boolToNumber(data.forn_oficial_pej)
        data.fidel_forn_pej = boolToNumber(data.fidel_forn_pej)
        data.conveniado_pej = boolToNumber(data.conveniado_pej)
        data.reter_picocs_pej = boolToNumber(data.reter_picocs_pej)
        data.pessoa.reter_iss_pes = boolToNumber(data.pessoa.reter_iss_pes)
        data.inc_fiscal_pej = boolToNumber(data.inc_fiscal_pej)
        data.inc_cult_pej = boolToNumber(data.inc_cult_pej)

        data.pessoa.pessoa_endereco = data.pessoa?.pessoa_endereco || []
        data.pessoa.cli_atacadista_pes = data.pessoa.cli_atacadista_pes || 0
        data.pessoa.blq_venda_ate_pes = toJSDate(data.pessoa.blq_venda_ate_pes)
        data.data_fundacao_pej = toJSDate(data.data_fundacao_pej)

        if (data.pessoa?.pessoa_endereco) {
            data.pessoa.pessoa_endereco.forEach(e => {
                e.usa_desde_end = toJSDate(e.usa_desde_end)
            })
        }

        return data
    }

    async getById(args = {}) {

        args.includes = args.includes || 'main,pessoa_endereco_tipo'
        return super.getById(args).then((response) => {
            console.log("outro aqui")
            // response.data = this.prepareToFront(response.data)
            console.log('BACKEND: ', response)
            return response
        })

    }

    prepareToSave({ data }) {

        data.cod_retri_pej = data.cod_retri_pej?.codigo_retri || null
        data.cod_retrie_pej = data.cod_retrie_pej?.codigo_retri || null
        data.cod_rati_pej = data.cod_rati_pej?.codigo_rati || null

        data.forn_pej = boolToNumber(data.forn_pej)
        data.forn_oficial_pej = boolToNumber(data.forn_oficial_pej)

        data.cnpj_ativo_pej = boolToNumber(data.cnpj_ativo_pej)
        data.fidel_forn_pej = boolToNumber(data.fidel_forn_pej)
        data.conveniado_pej = boolToNumber(data.conveniado_pej)
        data.reter_picocs_pej = boolToNumber(data.reter_picocs_pej)
        data.pessoa.reter_iss_pes = boolToNumber(data.pessoa.reter_iss_pes)
        data.inc_fiscal_pej = boolToNumber(data.inc_fiscal_pej)
        data.inc_cult_pej = boolToNumber(data.inc_cult_pej)

        data.pessoa.blq_venda_ate_pes = jsDateToLuxonString(data.pessoa.blq_venda_ate_pes)

        data.pessoa.reter_iss_pes = boolToNumber(data.pessoa.reter_iss_pes)
        data.pessoa.cli_atacadista_pes = boolToNumber(data.pessoa.cli_atacadista_pes)

        data.pessoa.cod_grup_pes = data.pessoa.cod_grup_pes?.codigo_grup || null
        data.pessoa.cod_catp_pes = data.pessoa.cod_catp_pes?.codigo_catp || null
        data.pessoa.cod_clap_pes = data.pessoa.cod_clap_pes?.codigo_clap || null

        data.pessoa.pessoa_endereco.forEach(e => {
            e.cod_tend_end = e.cod_tend_end.codigo_tend
            e.cod_mun_end = e.cod_mun_end.codigo_mun
        })

        delete data.meta

        return data

    }

    async getSchema() {

        return super.getSchema().then((data) => {

            if (data.fields.pessoa.fields) {

                data.fields.pessoa.fields.cpf_cnpj_pes = data.fields.pessoa.fields.cpf_cnpj_pes.test('cnpj', 'Cnpj incorreto!', isValidCnpj)
                data.fields.pessoa.fields.pessoa_endereco.innerType.fields.cod_tend_end = yup.object().typeError('Obrigatório').required('Obrigatório')
                data.fields.pessoa.fields.pessoa_endereco.innerType.fields.cod_mun_end = yup.object()
                    .test('municipio', 'Inválido', e => e.codigo_mun)
                    .typeError('Obrigatório')
                    .required('Obrigatório')

            }

            return data

        })

    }

}

