import PessoaService from "business/cadastro/pessoa/pessoa/pessoaService"
import { isValidCpf } from "helper/checker"
import { boolToNumber, toJSDate } from "helper/formater"
import { justNumbers } from "helper/parser"
import * as yup from "yup"

export default class PessoaFisicaService extends PessoaService {

    title = "Pessoa física"
    pluralTitle = "Pessoas físicas"
    url = '/cadastro/pessoa/pessoafisica'
    frontUrl = '/cadastro/pessoa/pessoafisica'
    frontUrlList = '/cadastro/pessoa/pessoafisicalist'
    primaryKey = 'codigo_pef'
    ignoredsSchemaConvertions = ['cod_esco_pef', 'cod_hobb_pef', 'cod_esci_pef', 'cod_sex_pef', 'cod_prf_pef', 'cod_grup_pes', 'cod_catp_pes', 'cod_clap_pes']

    postDTO(data = {}) {

        data.pessoa.tel_celular_pes = justNumbers(data.pessoa.tel_celular_pes)
        data.pessoa.tel_comercial_pes = justNumbers(data.pessoa.tel_comercial_pes)

        return data

    }
    prepareToFront(data = {}) {

        // Garante a presença de pessoa e pessoa_endereco no Novo
        data.pessoa = data.pessoa || {}

        data.pessoa.pessoa_endereco = data.pessoa?.pessoa_endereco || []
        data.pessoa.cli_atacadista_pes = data.pessoa.cli_atacadista_pes || 0
        data.data_emis_rg_pef = toJSDate(data.data_emis_rg_pef)
        data.data_nasc_pef = toJSDate(data.data_nasc_pef)
        data.renda_mes_pef = data.renda_mes_pef || null
        // data.pessoa.lim_credito_pes = data.pessoa.lim_credito_pes ? Number(data.pessoa.lim_credito_pes) : data.pessoa.lim_credito_pes

        if (data.pessoa?.pessoa_endereco) {
            data.pessoa.pessoa_endereco.forEach(e => {
                e.usa_desde_end = toJSDate(e.usa_desde_end)
            })
        }

        return data
    }

    async getById(args = {}) {

        args.includes = args.includes || 'pessoa_fisica,pessoa_endereco_tipo'
        return super.getById(args).then((response) => {
            response.data = this.prepareToFront(response.data)
            console.log('BACKEND: ', response)
            return response
        })

    }

    prepareToSave({ data }) {

        data.cod_esci_pef = data.cod_esci_pef?.codigo_esci || null
        data.cod_sex_pef = data.cod_sex_pef?.codigo_sex || null
        data.cod_prf_pef = data.cod_prf_pef?.codigo_prf || null
        data.cod_esco_pef = data.cod_esco_pef?.codigo_esco || null
        data.cod_hobb_pef = data.cod_hobb_pef?.codigo_hobb || null
        data.pessoa.reter_iss_pes = boolToNumber(data.pessoa.reter_iss_pes)
        data.pessoa.cli_atacadista_pes = boolToNumber(data.pessoa.cli_atacadista_pes)
        data.pessoa.blq_venda_ate_pes = boolToNumber(data.pessoa.blq_venda_ate_pes)
        data.pessoa.cod_grup_pes = data.pessoa.cod_grup_pes?.codigo_grup || null
        data.pessoa.cod_catp_pes = data.pessoa.cod_catp_pes?.codigo_catp || null
        data.pessoa.cod_clap_pes = data.pessoa.cod_clap_pes?.codigo_clap || null

        data.pessoa.pessoa_endereco.forEach(e => {
            e.cod_tend_end = e.cod_tend_end.codigo_tend
            e.cod_mun_end = e.cod_mun_end.codigo_mun
        })

        delete data.meta

        return data

    }

    async getSchema() {

        return super.getSchema().then((data) => {

            if (data.fields.pessoa.fields) {

                data.fields.pessoa.fields.cpf_cnpj_pes = data.fields.pessoa.fields.cpf_cnpj_pes.test('cpf', 'Cpf incorreto!', isValidCpf)
                data.fields.pessoa.fields.pessoa_endereco.innerType.fields.cod_tend_end = yup.object().typeError('Obrigatório').required('Obrigatório')
                data.fields.pessoa.fields.pessoa_endereco.innerType.fields.cod_mun_end = yup.object()
                    .test('municipio', 'Inválido', e => e.codigo_mun)
                    .typeError('Obrigatório')
                    .required('Obrigatório')

            }
            console.log('Very Final yup: ', data)

            return data

        })

    }

}

