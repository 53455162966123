export function getMessageFromFormErrors(errors, fieldName) {
    const partes = fieldName.split(/\.|\[|\].?/).filter(Boolean); // Split on '.', '[', or ']'
    let valor = errors

    for (let parte of partes) {
        if (valor && typeof parte === 'string' && /^\d+$/.test(parte)) {
            // Se parte é um número (índice de array), converta para número
            parte = parseInt(parte, 10)
        }
        if (valor) {
            valor = valor[parte]
        }
    }

    return valor ? valor.message : undefined // Retorna a mensagem se valor não for undefined
}

export function errorToToast({ err, toast }) {
    const data = err.response?.data
    let messages = ''

    if (typeof data == 'object') {

        if (data?.messages?.errors) {
            const msgError = data?.messages?.errors
            if (Array.isArray(msgError)) {
                messages = ''
                msgError.map(e => {
                    const msg = e.message + '. '
                    messages += msg
                    return ''
                })
            }
        } else if (data && typeof data?.error == 'string') {
            messages += data.error
        }
    } else if (typeof data == 'string') {
        messages = data
    }

    if (!messages && err?.message) {
        messages = err.message + '. '
    }

    if (data?.message?.length > 0) {
        messages = data?.message
    }

    toast.current.show({ severity: 'error', summary: 'Ops!', detail: messages, life: 7000 })

}