import { Dropdown } from 'primereact/dropdown'
import { classNames } from "primereact/utils";
import InputFrame from "./InputFrame"

export default function InputDropdownForm(props) {

    const field = props.controller.field
    const fieldState = props.controller.fieldState

    return (
        <>
            <InputFrame {...props}>
                <Dropdown
                    {...field}
                    inputId={field.name}
                    locale="pt"
                    autoComplete="off"
                    className={'p-inputtext-sm ' + (fieldState.isDirty ? '' : '') + classNames({ 'p-invalid': fieldState.error })}
                    size='small'
                    options={props.options || {}}
                    onClick={props.onClick}
                    optionLabel={props.optionLabel || ''}
                    placeholder={props.placeholder || ''}
                    emptyMessage={props.emptyMessage || 'Selecionar'}
                    disabled={props.disabled}
                    showClear={false}
                    autoOptionFocus={true}

                />
            </InputFrame>
        </>
    )
}