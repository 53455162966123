import ServiceBase from "base/baseService"

export default class EstadoCivilService extends ServiceBase {

    title = "Estado civil"
    pluralTitle = "Estados civis"
    url = '/cadastro/pessoa/estadocivil'
    frontUrl = '/cadastro/pessoa/pessoafisica/estadocivil'
    frontUrlList = '/cadastro/pessoa/pessoafisica/estadocivillist'
    primaryKey = 'codigo_esci'

}

