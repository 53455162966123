import ServiceBase from "base/baseService"

export default class GrupoPessoaService extends ServiceBase {

    title = "Grupo de pessoa"
    pluralTitle = "Grupo de pessoas"
    url = '/cadastro/pessoa/grupopessoa'
    frontUrl = '/cadastro/pessoa/grupopessoa'
    frontUrlList = '/cadastro/pessoa/grupopessoalist'
    primaryKey = 'codigo_grup'
    ignoredsSchemaConvertions = ['cod_tip_grup']

    prepareToSave({ data }) {

        data.cod_tip_grup = data.cod_tip_grup?.codigo_tip || null

        delete data.meta

        return data

    }

}
