import { yupResolver } from "@hookform/resolvers/yup"
import ControllerInput from 'base/ControllerInput'
import FormCadastro, { FormCadastroAux } from 'base/FormCadastro'
import GridForm from 'base/GridForm'
import OutputSpanForm from 'base/OutputSpanForm'
import CategoriaPessoaService from 'business/cadastro/pessoa/categoriaPessoa/categoriaPessoaService'
import ClassificacaoPessoaService from 'business/cadastro/pessoa/classificacaoPessoa/classificacaoPessoaService'
import EscolaridadeService from 'business/cadastro/pessoa/escolaridade/escolaridadeService'
import EstadoCivilService from 'business/cadastro/pessoa/estadoCivil/estadoCivilService'
import GrupoPessoaService from 'business/cadastro/pessoa/grupoPessoa/grupoPessoaService'
import HobbyService from 'business/cadastro/pessoa/hobby/hobbyService'
import PessoaFisicaService from 'business/cadastro/pessoa/pessoaFisica/pessoaFisicaService'
import ProfissaoService from 'business/cadastro/pessoa/profissao/profissaoService'
import SexoService from 'business/cadastro/pessoa/sexo/sexoService'
import { defaultEmptySelection } from 'helper/default'
import { errorToToast } from 'helper/error'
import { formatDatetime, getMask } from 'helper/formater'
import { generateCPF } from 'helper/generate'
import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'
import { DataView } from 'primereact/dataview'
import { Divider } from 'primereact/divider'
import { TabPanel, TabView } from 'primereact/tabview'
import { useEffect, useRef, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export default function PessoaFisica() {

    const [service, setService] = useState()

    if (!service) {
        setService(new PessoaFisicaService())
    }

    const [municipiosBox, setMunicipiosBox] = useState(null)
    const stepperRef = useRef(null)
    const toast = useRef(null)
    const { id } = useParams()
    const [validations, setValidations] = useState([])
    const [escolaridadeList, setEscolaridadeList] = useState([])
    const [hobbyList, setHobbyList] = useState([])
    const [estadoCivilList, setEstadoCivilList] = useState([])
    const [sexoList, setSexoList] = useState([])
    const [pessoaEnderecoTipoList, setPessoaEnderecoTipoList] = useState([])
    const [usuarioHistAcao, setUsuarioHistAcao] = useState([])
    const [registro, setRegistro] = useState({})
    const [profissaoList, setProfissaoList] = useState([])
    const [classificacaoPessoaList, setClassificacaoPessoaList] = useState([])
    const [grupoPessoaList, setGrupoPessoaList] = useState([])
    const [categoriaPessoaList, setCategoriaPessoaList] = useState([])

    const DEF = { RESIDENCIAL: 1, REGISTRO: 5 }

    const form = useForm({
        defaultValues: async () => await load(),
        resolver: yupResolver(validations),
        mode: "onBlur"
    })

    const pessoaEndereco = useFieldArray({
        control: form.control,
        name: "pessoa.pessoa_endereco"
    })

    async function load() {
        const data = await FormCadastroAux.load({ id, service, toast, beforeSubmit, useNew: true })

        if (data.escolaridade) {
            const escolaridade = { codigo_esco: data.escolaridade?.codigo_esco, descricao_esco: data.escolaridade?.descricao_esco }
            setEscolaridadeList([escolaridade])
            data.cod_esco_pef = escolaridade
        }

        if (data.hobby) {
            const hobby = { codigo_hobb: data.hobby?.codigo_hobb, descricao_hobb: data.hobby?.descricao_hobb }
            setHobbyList([hobby])
            data.cod_hobb_pef = hobby
        }

        if (data.estado_civil) {
            const estadoCivil = { codigo_esci: data.estado_civil?.codigo_esci, descricao_esci: data.estado_civil?.descricao_esci }
            setEstadoCivilList([estadoCivil])
            data.cod_esci_pef = estadoCivil
        }

        if (data.sexo) {
            const sexo = { codigo_sex: data.sexo?.codigo_sex, descricao_sex: data.sexo?.descricao_sex }
            setSexoList([sexo])
            data.cod_sex_pef = sexo
        }

        if (data.profissao) {
            const profissao = { codigo_prf: data.profissao?.codigo_prf, descricao_prf: data.profissao?.descricao_prf }
            setProfissaoList([profissao])
            data.cod_prf_pef = profissao
        }

        if (data.pessoa.classificacao_pessoa) {
            const classificacaoPessoa = { codigo_clap: data.pessoa.classificacao_pessoa?.codigo_clap, descricao_clap: data.pessoa.classificacao_pessoa?.descricao_clap }
            setClassificacaoPessoaList([classificacaoPessoa])
            data.pessoa.cod_clap_pes = classificacaoPessoa
        }

        if (data.pessoa.grupo_pessoa) {
            const grupoPessoa = { codigo_grup: data.pessoa.grupo_pessoa?.codigo_grup, descricao_grup: data.pessoa.grupo_pessoa?.descricao_grup }
            setGrupoPessoaList([grupoPessoa])
            data.pessoa.cod_grup_pes = grupoPessoa
        }

        if (data.pessoa.categoria_pessoa) {
            const categoriaPessoa = { codigo_catp: data.pessoa.categoria_pessoa?.codigo_catp, descricao_catp: data.pessoa.categoria_pessoa?.descricao_catp }
            setCategoriaPessoaList([categoriaPessoa])
            data.pessoa.cod_catp_pes = categoriaPessoa
        }

        startMunicipiosBox(data)
        setPessoaEnderecoTipoList([...data.meta.pessoa_endereco_tipo])

        service.addEnderecoResidencial(data.pessoa)
        service.enderecosCodigoToObject(data.pessoa, data.meta.pessoa_endereco_tipo)

        return data

    }

    const startMunicipiosBox = (data) => {

        const muns = data.pessoa.pessoa_endereco.map(e => service.formatMunicipioOption(e.municipio.codigo_mun, e.municipio.descricao_mun, e.municipio.uf.sigla_uf))
        setMunicipiosBox(muns)

    }

    const beforeSubmit = (data) => {

        let submitData = structuredClone(data)

        const { dirtyFields } = form.formState

        service.formatActionOfEnderecos(submitData?.pessoa, dirtyFields?.pessoa)

        submitData = service.prepareToSave({ data: submitData })

        return submitData

    }

    useEffect(() => {
        service.getSchema().then(setValidations)
    }, [service])

    const loadProfissaoList = async () => {
        if (profissaoList.length < 2) {
            const prf = new ProfissaoService()
            await prf.list('').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_prf: e.codigo_prf, descricao_prf: e.descricao_prf }))
                data.push({ codigo_prf: null, descricao_prf: defaultEmptySelection() })
                setProfissaoList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    const loadClassificacaoPessoaList = async () => {
        if (classificacaoPessoaList.length < 2) {
            const clap = new ClassificacaoPessoaService()
            await clap.list('?cod_tip_grup=f&cod_tip_grup=null').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_clap: e.codigo_clap, descricao_clap: e.descricao_clap }))
                data.push({ codigo_clap: null, descricao_clap: defaultEmptySelection() })
                setClassificacaoPessoaList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    const loadGrupoPessoaList = async () => {
        if (grupoPessoaList.length < 2) {
            const grup = new GrupoPessoaService()
            await grup.list('?cod_tip_grup=f&cod_tip_grup=null').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_grup: e.codigo_grup, descricao_grup: e.descricao_grup }))
                data.push({ codigo_grup: null, descricao_grup: defaultEmptySelection() })
                setGrupoPessoaList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    const loadCategoriaPessoaList = async () => {
        if (categoriaPessoaList.length < 2) {
            const catp = new CategoriaPessoaService()
            await catp.list('?cod_tip_catp=f&cod_tip_catp=null').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_catp: e.codigo_catp, descricao_catp: e.descricao_catp }))
                data.push({ codigo_catp: null, descricao_catp: defaultEmptySelection() })
                setCategoriaPessoaList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    const loadEscolaridadeList = async () => {
        if (escolaridadeList.length < 2) {
            const esco = new EscolaridadeService()
            await esco.list('').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_esco: e.codigo_esco, descricao_esco: e.descricao_esco }))
                data.push({ codigo_esco: null, descricao_esco: defaultEmptySelection() })
                setEscolaridadeList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }
    const loadHobbyList = async () => {
        if (hobbyList.length < 2) {
            const hobb = new HobbyService()
            await hobb.list('').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_hobb: e.codigo_hobb, descricao_hobb: e.descricao_hobb }))
                data.push({ codigo_hobb: null, descricao_hobb: defaultEmptySelection() })
                setHobbyList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }
    const loadEstadoCivilList = async () => {
        if (estadoCivilList.length < 2) {
            const esci = new EstadoCivilService()
            await esci.list('').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_esci: e.codigo_esci, descricao_esci: e.descricao_esci }))
                data.push({ codigo_esci: null, descricao_esci: defaultEmptySelection() })
                setEstadoCivilList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }
    const loadSexoList = async () => {
        if (sexoList.length < 2) {
            const sex = new SexoService()
            await sex.list('').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_sex: e.codigo_sex, descricao_sex: e.descricao_sex }))
                data.push({ codigo_sex: null, descricao_sex: defaultEmptySelection() })
                setSexoList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    const municipioSearch = (event) => {

        service.filterMunicipio({ municipio: event.query }).then((response) => {
            const rs = response.data.data
            const formatado = rs.map(e => service.formatMunicipioOption(e.codigo_mun, e.descricao_mun, e.meta.sigla_uf))
            setMunicipiosBox(formatado)
        }).catch((error) => {
            console.log("Erro: ", error)
        })
    }

    const enderecoSearch = async (cep, index) => {

        service.enderecoSearch({
            cep,
            resolve: ({ response, optionMunicipio }) => {
                if (!municipiosBox.some(e => e.codigo_mun === response.ibge)) {
                    const municipiosUnique = municipiosBox
                    municipiosUnique.push(optionMunicipio)
                    setMunicipiosBox(municipiosUnique)
                }
                form.setValue(`pessoa.pessoa_endereco[${index}].endereco_end`, response.logradouro, { shouldValidate: true, shouldTouch: true })
                form.setValue(`pessoa.pessoa_endereco[${index}].bairro_end`, response.bairro)
                form.setValue(`pessoa.pessoa_endereco[${index}].cod_mun_end`, optionMunicipio)
            },
            reject: () => {
                toast.current.show({ severity: 'warn', summary: 'Ops!', detail: `CEP ${cep} não encontrado!`, life: 7000 })
            }
        })

    }

    return (

        < FormCadastro service={service} form={form} aux={FormCadastroAux} toast={toast}
            beforeSubmit={beforeSubmit}
        >


            <TabView ref={stepperRef}
                className='text-sm'
                style={{ flexBasis: '1rem' }}
                onBeforeTabChange={async (e) => {

                    if (e.index === DEF.REGISTRO) {
                        if (usuarioHistAcao.length === 0 || !registro) {
                            const rs = await service.getById({ id, includes: 'registro,usuario_hist_acao' })
                            setUsuarioHistAcao(rs.data.meta.usuario_hist_acao)
                            setRegistro(rs.data.meta.registro)
                        }

                    }

                }}
            >

                <TabPanel header="Capa" leftIcon="pi pi-home mr-2">
                    <GridForm>
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-4" field="pessoa.nome_razao_pes" form={form} service={service} type="text" clearString label="Nome"
                            onBlur={(event) => {
                                event.preventDefault()
                                if (event.target.value.includes('Inventad')) {
                                    if (form.getValues("pessoa.cpf_cnpj_pes") === '') {
                                        form.setValue("pessoa.cpf_cnpj_pes", generateCPF())
                                    }
                                }
                            }}
                        />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" mask={getMask('cpf')} field="pessoa.cpf_cnpj_pes" form={form} service={service} type="text" inputMode="tel" label="CPF" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="rg_pef" form={form} service={service} type="text" inputMode="tel" noSpace label=" RG" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="org_emis_rg_pef" form={form} service={service} type="text" noSpace label="Orgão emissor (RG)" />

                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" mask={getMask('cellphone')} field="pessoa.tel_celular_pes" form={form} service={service} type="text" inputMode="tel" label="Celular" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" mask={getMask('cellphone')} field="pessoa.tel_comercial_pes" form={form} service={service} type="text" inputMode="tel" label="Fone comercial" />

                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa.email_pes" form={form} service={service} type="text" noSpace inputMode="email" label="E-mail" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa.email_financeiro_pes" form={form} service={service} type="text" noSpace inputMode="email" label="E-mail (financeiro)" />

                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="data_emis_rg_pef" form={form} service={service} type="calendar" inputMode="tel" label="Data da emissão (RG)" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa.ie_pes" form={form} service={service} type="text" inputMode="text" noSpace label="IE" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa.ins_mun_pes" form={form} type="text" noSpace service={service} label="Incrição municipal" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="cnh_pef" form={form} service={service} type="text" inputMode="tel" noSpace label="CNH" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="data_nasc_pef" form={form} service={service} type="calendar" inputMode="tel" label="Nascimento" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="nacionalidade_pef" form={form} service={service} type="text" clearString label="Nacionalidade" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="naturalidade_pef" form={form} service={service} type="text" clearString label="Naturalidade" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="cod_esco_pef" form={form} type="dropdown" service={service} label="Escolaridade"
                            optionLabel='descricao_esco'
                            options={escolaridadeList}
                            onClick={loadEscolaridadeList}
                        />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="cod_hobb_pef" form={form} service={service} type="dropdown" label="Hobby"
                            optionLabel='descricao_hobb'
                            options={hobbyList}
                            onClick={loadHobbyList}
                        />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="cod_sex_pef" form={form} service={service} type="dropdown" label="Sexo"
                            optionLabel='descricao_sex'
                            options={sexoList}
                            onClick={loadSexoList}
                        />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="cod_esci_pef" form={form} service={service} type="dropdown" label="Estado civil"
                            optionLabel='descricao_esci'
                            options={estadoCivilList}
                            onClick={loadEstadoCivilList}
                        />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa.tratamento_pes" form={form} service={service} type="text" clearString label="Tratamento" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-3" field="pessoa.codinome_pes" form={form} service={service} type="text" clearString label="Codinome" />
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-3" field="nome_conjuge_pef" form={form} service={service} type="text" clearString label="Conjuge" />
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-3" field="nome_pai_pef" form={form} service={service} type="text" clearString label="Nome do pai" />
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-3" field="nome_mae_pef" form={form} service={service} type="text" clearString label="Nome da mãe" />
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-6" field="obs_pef" form={form} service={service} type="textarea" clearString label="Observações" rows={3} />
                        {pessoaEndereco.fields.map((data, index) => {
                            if (data.cod_tend_end.codigo_tend !== DEF.RESIDENCIAL) {
                                return null
                            }
                            return (
                                <div key={index} className='grid col-12 m-0 p-0'>
                                    <Divider className='flex mt-2 mb-2'></Divider>
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].cod_tend_end`} form={form} service={service} type="dropdown" label="Endereço de"
                                        optionLabel='descricao_tend'
                                        options={pessoaEnderecoTipoList}
                                        disabled
                                    />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].cep_end`} form={form} service={service} mask={getMask('cep')} type="text" inputMode="tel" label="CEP"
                                        onBlur={e => enderecoSearch(e.target.value, index)}
                                    />
                                    <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field={`pessoa.pessoa_endereco[${index}].endereco_end`} form={form} service={service} type="text" clearString label="Endereço" />
                                    <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-2" field={`pessoa.pessoa_endereco[${index}].numero_end`} form={form} service={service} type="text" inputMode="numeric" noSpace label="Número" />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].complemento_end`} form={form} service={service} type="text" clearString label="Complemento" />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].bairro_end`} form={form} service={service} type="text" clearString label="Bairro" />

                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].cod_mun_end`} form={form} service={service} type="autocomplete" label="Municipio"
                                        suggestions={municipiosBox}
                                        completeMethod={municipioSearch}
                                        fieldShow={'descricao_munuf'}
                                    />

                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].tel_end`} mask={getMask('cellphone')} form={form} service={service} type="text" inputMode="tel" label="Telefone" />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].usa_desde_end`} form={form} service={service} type="calendar" inputMode="tel" label="Desde" />

                                </div>
                            )
                        }
                        )}

                    </GridForm>
                </TabPanel>

                <TabPanel header="Endereços" leftIcon="pi pi-directions mr-2">
                    <GridForm>
                        {pessoaEndereco.fields.map((data, index) => {
                            if (data.cod_tend_end.codigo_tend === DEF.RESIDENCIAL) {
                                return ''
                            }
                            return (
                                <div key={data.id} className='grid col-12 m-0 p-0'>
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].cod_tend_end`} form={form} service={service} type="dropdown" label="Endereço de"
                                        optionLabel='descricao_tend'
                                        options={pessoaEnderecoTipoList}
                                        onClick={_ => true}
                                    />
                                    <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-2" field={`pessoa.pessoa_endereco[${index}].nome_end`} form={form} service={service} type="text" clearString label="Nome" />
                                    <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-2" field={`pessoa.pessoa_endereco[${index}].cep_end`} form={form} service={service} mask={getMask('cep')} type="text" inputMode="tel" label="CEP"
                                        onBlur={e => enderecoSearch(e.target.value, index)}
                                    />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-3" field={`pessoa.pessoa_endereco[${index}].endereco_end`} form={form} service={service} type="text" clearString label="Endereço" />
                                    <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-1" field={`pessoa.pessoa_endereco[${index}].numero_end`} form={form} service={service} type="text" inputMode="numeric" noSpace label="Número" />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].complemento_end`} form={form} service={service} type="text" clearString label="Complemento" />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].bairro_end`} form={form} service={service} type="text" clearString label="Bairro" />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].cod_mun_end`} form={form} service={service} type="autocomplete" label="Municipio"
                                        suggestions={municipiosBox}
                                        completeMethod={municipioSearch}
                                        fieldShow={'descricao_munuf'}
                                    />

                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].tel_end`} mask={getMask('cellphone')} form={form} service={service} type="text" inputMode="tel" label="Telefone" />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa.pessoa_endereco[${index}].usa_desde_end`} form={form} service={service} type="calendar" inputMode="tel" label="Desde" />

                                    <Button

                                        className="md:col-1 lg:col-1 xl:col-1"
                                        label="." icon="pi pi-trash" size="small" onClick={(e) => {
                                            confirmDialog({
                                                message: 'Confirma remover o endereço? ' + index,
                                                icon: 'pi pi-question-circle',
                                                accept: () => {
                                                    const removed = form.getValues(`pessoa.pessoaEnderecoRemoved`) || []
                                                    pessoaEndereco.remove(index)
                                                    if (data.codigo_end) {
                                                        removed.push({ codigo_end: data.codigo_end })
                                                        form.setValue('pessoa.pessoaEnderecoRemoved', removed)
                                                    }
                                                }
                                            })
                                            e.preventDefault()
                                        }}
                                        severity="warning" text
                                    />

                                    {pessoaEndereco.fields.length > 2 && <Divider className='flex mt-2 mb-2'></Divider>}
                                </div>
                            )
                        })}

                        <div className="flex m-2 justify-content-start">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault()
                                    pessoaEndereco.append(service.getNewEndereco(), { shouldFocus: false })
                                }}
                            >Adicionar</Button>

                        </div>

                    </GridForm>
                </TabPanel>
                <TabPanel header="Profissional" leftIcon="pi pi-user mr-2">
                    <GridForm>

                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="cod_prf_pef" form={form} service={service} type="dropdown" label="Profissão"
                            optionLabel='descricao_prf'
                            options={profissaoList}
                            onClick={loadProfissaoList}
                        />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="tempo_srv_trab_pef" form={form} service={service} type="text" clearString label="Tempo de serviço" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="renda_mes_pef" form={form} service={service} type="number" inputMode="numeric" clearString label="Renda mensal" />

                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="num_ctps_pef" form={form} service={service} type="text" inputMode="text" noSpace label="Número CTPS" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="serie_ctps_pef" form={form} service={service} type="text" inputMode="text" noSpace label="Série CTPS" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="num_pis_pef" form={form} service={service} type="text" inputMode="text" noSpace label="PIS" />
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-2" field="pessoa.id_inter_pes" form={form} service={service} type="text" clearString label="Identif. internacional" />
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-4" field="pessoa.biografia_pes" form={form} service={service} type="text" clearString label="Bio" />
                    </GridForm>
                </TabPanel>

                <TabPanel header="Crédito" leftIcon="pi pi-dollar mr-2">
                    <GridForm>
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-4" field="pessoa.ref_bancaria_pes" form={form} service={service} type="textarea" clearString label="Referências bancárias" rows={5} />
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-4" field="ref_pessoal_pef" form={form} service={service} type="textarea" clearString label="Referências pessoais" rows={5} />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa.lim_credito_pes" form={form} service={service} type="number" inputMode="numeric" noSpace label="Limite de crédito" />

                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa.cli_atacadista_pes" form={form} service={service} type="switch" label="Atacadista" />
                    </GridForm>
                </TabPanel>
                <TabPanel header="Mais" leftIcon="pi pi-wrench mr-2">
                    <GridForm>
                        <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field="pessoa.cod_clap_pes" form={form} service={service} type="dropdown" label="Classificação"
                            optionLabel='descricao_clap'
                            options={classificacaoPessoaList}
                            onClick={loadClassificacaoPessoaList}
                        />
                        <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field="pessoa.cod_grup_pes" form={form} service={service} type="dropdown" label="Grupo"
                            optionLabel='descricao_grup'
                            options={grupoPessoaList}
                            onClick={loadGrupoPessoaList}
                        />

                        <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field="pessoa.cod_catp_pes" form={form} service={service} type="dropdown" label="Categoria"
                            optionLabel='descricao_catp'
                            options={categoriaPessoaList}
                            onClick={loadCategoriaPessoaList}
                        />
                    </GridForm>
                </TabPanel>

                <TabPanel header="Informações" leftIcon="pi pi-info-circle mr-2" visible={id > 0} >
                    <GridForm>

                        <OutputSpanForm field="usu_ins_pes" className="col-12 md:col-4 lg:col-4 xl:col-3" value={form.getValues('pessoa.usu_ins_pes') + ' - ' + registro?.meta?.nome_razao_usu} label="Inserido por" />
                        <OutputSpanForm field="cod_emp_pes" className="col-12 md:col-4 lg:col-4 xl:col-3" value={form.getValues('pessoa.cod_emp_pes') + ' - ' + registro?.meta?.nome_razao_emp} label="Na empresa" />
                        <OutputSpanForm field="pessoa.criado_pes" className="col-12 md:col-4 lg:col-4 xl:col-3" form={form} value={formatDatetime(form.getValues('pessoa.criado_pes'))} label="Inserido em" />

                        <DataView
                            className='p-grid p-flex-row md:col-12 lg:col-12 xl:col-12'
                            value={usuarioHistAcao}
                            // layout={DataViewLayoutOptions.List} // Escolha o layout desejado (Grid, List, etc.)
                            paginator
                            emptyMessage='Nenhuma alteração registrada'
                            rows={6}
                            itemTemplate={
                                (data) => {
                                    return (
                                        <div className="flex flex-column mt-3 md:col-12 lg:col-12 xl:col-12">
                                            <div className='mr-2'>
                                                <strong className='mr-2'>
                                                    {formatDatetime(data.criado_hisa)}:
                                                </strong>
                                                {data.descricao_hisa}
                                            </div>
                                            <div className='text-sm font-italic'>
                                                <span>{data.meta.nome_razao_usu}</span> -
                                                <span className='ml-2'>{data.meta.nome_razao_emp}</span>
                                            </div>
                                        </div>
                                    )
                                }

                            }
                        />
                    </GridForm>
                </TabPanel>


            </TabView>



        </FormCadastro >
    )
}

