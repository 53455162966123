import ServiceBase from "base/baseService"

export default class TipoPessoaService extends ServiceBase {

    title = "Tipo de pessoa"
    pluralTitle = "Tipo de pessoas"
    url = '/cadastro/pessoa/tipopessoa'
    frontUrl = '/cadastro/pessoa/tipopessoa'
    frontUrlList = '/cadastro/pessoa/tipopessoalist'
    primaryKey = 'codigo_tip'

}
