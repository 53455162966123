/**
 *  save(id = null, data = {}) {
 *    return super.save(id, data)
 * }
 */
import ServiceBase from "base/baseService"
import * as yup from "yup"

export default class UnidadeService extends ServiceBase {
    url = '/cadastro/produto/unidade'
    frontUrl = '/cadastro/produto/unidade'

    postDTO(data = {}) {

        return {
            ...data
        }
    }
    prepareToFront(data = {}) {

        return {
            ...data
        }
    }

    async list(qs = '') {

        return super.list(qs).then((response) => {
            response.data.data.map((e) => {
                // if (e.datven_uni) {
                //     e.datven_uni = new Date(e.datven_uni).toLocaleDateString('pt-BR')
                // }
            })
            return response
        })
    }

    // Get show
    async getById({ id = null }) {

        return super.getById({ id }).then((response) => {
            response.data = this.prepareToFront(response.data)
            return response
        }).catch((error) => {
            console.log("Erro (service): ", error)
        })

    }

    // Store or update
    async save(id = null, data = {}) {

        return super.save(id, this.postDTO(data))

    }

    getSchema() {
        return yup.object({
            descricao_uni: yup.string().min(3, 'Precisa no mínimo 3 letras').required(),
        }).required()

    }

}

