import { adonisToYup } from "base/schemaConverter/adonisToYup"
import server from "infra/server"
import * as yup from "yup"

export default class ServiceBase {

    constructor() {
        console.log("servicoBase.Constructor()")
        // Usar Arrows functions para não precisar fazer o pesado Bind!
        // this.onRulesScan = this.onRulesScan.bind(this)
    }

    title = 'Title'
    pluralTitle = "Titles"
    url = ''
    frontUrl = ''
    frontUrlList = ''
    requiredFields = []
    primaryKey = ''
    ignoredsSchemaConvertions = [] // Campos a ignorar na conversão de regras de schema do backend para o frontend

    postDTO(data = {}) {
        return data
    }
    // Get index
    list(qs = '') {
        const url = this.getQsUrl(qs)
        return server().get(url)
    }
    // Store or update
    save(id = null, data = {}) {
        const mapData = this.postDTO(data)
        console.log('baseService.saving... ', id, this.url)
        if (id) {
            return server().put(this.getIdUrl(id), mapData)
        }
        return server().post(this.getInsertUrl(), mapData)
    }
    // Get show
    async getByIdServer({ id = null, includes = null }) {

        if (id) {
            const argus = this.getIdUrl(id)
            if (includes) {
                return server().get(argus, { params: { includes } })
            } else {
                return server().get(argus)
            }
        }

        return new Promise()
    }
    async getById(args) {

        return this.getByIdServer(args).then((response) => {
            response.data = this.prepareToFront(response.data)
            return response
        }).catch((error) => {
            console.log("Erro (service): ", error)
            return error
        })

    }
    // Delete destroy
    delete(id = null) {
        const url = this.getIdUrl(id)
        console.log('url: ', url)
        return server().delete(url)
    }
    // Post store
    _insert(data = {}) {
        return {}
    }
    // Put update
    _update(data = {}) {
        return {}
    }

    getInsertUrl() {
        return this.url
    }
    getIdUrl(id = '') {
        return `${this.url}/${id}`
    }
    getQsUrl(qs = '') {
        return `${this.url}/${qs}`
    }

    getNewFrontUrl() {
        return this.frontUrl
    }
    getIdFrontUrl(id = '') {
        return `${this.frontUrl}/${id}`
    }
    getListFrontUrl() {
        return `${this.frontUrl}List`
    }
    addRequiredFields(field) {
        if (!this.requiredFields.includes(field)) {
            this.requiredFields.push(field)
        }
    }
    // Tratamento de dados vindos do backend.
    prepareToFront(data = {}) {
        return data
    }
    // Tratamento de dados para enviar aobackend.
    prepareToSave({ data }) {
        return data
    }
    async getSchema() {
        this.requiredFields = []
        return server().get(`${this.url}/schema`).then((data) => {

            console.log('Adonis Schema: ', data)

            data.data.ignoreds = this.ignoredsSchemaConvertions

            const schema = adonisToYup(data.data, { onRulesScan: (rule, field, value) => this.onRulesScan(rule, field, value) })
            console.log('Final yup: ', schema)
            console.log('requiredFields: ', this.requiredFields)

            return yup.object(schema)
        })
    }
    onRulesScan(rule, field, value) {
        if (rule.name === 'required') {
            if (!['object', 'array'].includes(value.type)) {
                this.addRequiredFields(field)
            }
        }
    }

}




