import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import { Toolbar } from 'primereact/toolbar'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Paginator } from 'primereact/paginator'
import { InputText } from 'primereact/inputtext'
import ConfigService from "business/configuracao/config/configService"
import Form from "base/Form"
import { InputSwitch } from 'primereact/inputswitch'


export default function ConfigList() {
    const [service] = useState(new ConfigService())
    const cadastro = useLocation()
    const [configList, setConfigList] = useState([])
    const toast = useRef(null)
    const navigate = useNavigate()
    const [first, setFirst] = useState(cadastro.state?.first || 0)
    const [page, setPage] = useState(cadastro.state?.page || 0)
    const [search, setSearch] = useState(cadastro.state?.search || '')
    const [setRows] = useState(10)
    const [totalRows, setTotalRows] = useState(0)
    const [visitedId] = useState(cadastro.state?.visitedId || [])
    const perpage = 10

    useEffect(() => {
        loadData(page)
    }, [])

    function loadData(selPage) {

        const queryString = `?page=${selPage + 1}&perpage=${perpage}&descricao_num=${search}`

        service.list(queryString).then((response) => {

            const meta = response.data.meta
            setConfigList(response.data.data)
            setTotalRows(meta.total)

        }).catch((error) => {
            console.log("Erro: ", error)
        })
    }

    function handleRowNew() {

        navigate(service.getNewFrontUrl())
    }

    function handleRowEdit(data) {
        visitedId.push(data.codigo_conf)
        console.log('agora sim id:', data.codigo_conf)
        const ama = service.getIdFrontUrl(data.codigo_conf)
        navigate(ama, { state: { search, page, first, visitedId } })
    }

    function handleSearch(data) {
        loadData(0)
        setPage(0)
        setFirst(0)
    }
    function handleSearchKeyUp(data) {
        if (data.key === 'Enter') {
            handleSearch(search)
        }
    }


    const dialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" />
            <Button label="Save" icon="pi pi-check" text />
        </>
    )
    function leftToolbarTemplate(data) {
        return (
            <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                </span>
                <InputText id="procurar" placeholder="Procurar" inputMode="search" value={search} onChange={(e) => setSearch(e.target.value)}
                    onKeyUp={handleSearchKeyUp}
                    className='mr-0' />
                <Button label="Pesquisar" icon="pi pi-search" onClick={() => handleSearch(search)} />
            </div>
        )
    }
    function rightToolbarTemplate(data) {
        return (
            <>
                {/* <Button label="Novo" icon="pi pi-plus" severity="success" onClick={handleRowNew} outlined /> */}
            </>
        )

    }
    function action(data) {
        return (
            <div>
                <Button className="mr-1" label="" icon="pi pi-pencil" size="small" onClick={() => handleRowEdit(data)} severity="success" text />
                {/* <Button label="" icon="pi pi-trash" size="small" onClick={() => handleRowDelete(data)} severity="warning" text /> */}
            </div>
        )
    }

    // {data.meta.cod_tid_num === 'boolean' && <>{data.valor_conf === '1' ? 'Ativada' : 'Desativada'}</>}

    function tableValorConf(data) {
        return (
            <>
                {data.meta.cod_tid_num === 'boolean' && <InputSwitch checked={data.valor_conf === '1'}></InputSwitch>}
                {data.meta.cod_tid_num !== 'boolean' && <>{data.valor_conf}</>}
            </>

        )
    }
    return (
        <Form title={service.pluralTitle}>

            <ConfirmDialog />
            <Toast ref={toast} />
            <div>
                <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>
                <DataTable value={configList} dataKey="codigo_conf" size="small" showGridlines
                    rows={perpage}
                    tableStyle={{ minWidth: '1rem' }}
                    totalRecords={totalRows}
                    paginatorPosition="bottom"
                    paginatorRight
                    selectionMode="single"
                    rowClassName={(rowData) => visitedId.includes(rowData.codigo_conf) ? 'bg-gray-50' : ''}
                >

                    <Column field="cod_num_conf" sortable header="Número"></Column>
                    <Column field="meta.descricao_num" sortable header="Configuração"></Column>
                    <Column field="valor_conf" sortable header="Valor" body={tableValorConf}></Column>
                    <Column body={action}></Column>

                </DataTable>
                <Paginator

                    first={first}
                    rows={perpage}
                    onPageChange={(event) => {
                        setFirst(event.first)
                        setRows(event.rows)
                        setPage(event.page)
                        loadData(event.page)
                    }}
                    totalRecords={totalRows}
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    template={{
                        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport',
                        CurrentPageReport: (options) => {
                            return (
                                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center' }}>
                                    {options.totalRecords} resultados
                                </span>
                            )
                        }
                    }
                    }

                />

            </div >
        </Form >
    );
}