import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ProfissaoService from 'business/cadastro/pessoa/profissao/profissaoService'
import ControllerInput from 'base/ControllerInput'
import GridForm from 'base/GridForm'
import { yupResolver } from '@hookform/resolvers/yup'
import FormCadastro, { FormCadastroAux } from 'base/FormCadastro'
import { TabView, TabPanel } from 'primereact/tabview'

export default function Profissao() {

    const [service, setService] = useState()

    if (!service) {
        setService(new ProfissaoService())
    }

    const stepperRef = useRef(null)
    const toast = useRef(null)
    const { id } = useParams()
    const [validations, setValidations] = useState([])

    const form = useForm({
        defaultValues: async () => await load(),
        resolver: yupResolver(validations),
        mode: "onBlur"
    })

    async function load() {
        const data = await FormCadastroAux.load({ id, service, toast, beforeSubmit, useNew: true })
        return data
    }

    const beforeSubmit = (data) => {
        let submitData = structuredClone(data)
        submitData = service.prepareToSave({ data: submitData })
        return submitData
    }

    useEffect(() => {
        service.getSchema().then(e => {
            setValidations(e)
        })
    }, [service])

    return (
        < FormCadastro service={service} form={form} aux={FormCadastroAux} toast={toast} beforeSubmit={beforeSubmit}>

            <TabView ref={stepperRef} className='text-sm' style={{ flexBasis: '1rem' }}>

                <TabPanel header="Capa" leftIcon="pi pi-home mr-2">

                    <GridForm>

                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="descricao_prf" form={form} service={service} type="text" clearString label="Descrição" />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="salario_prf" form={form} service={service} type="number" label="Salário" />

                    </GridForm>

                </TabPanel>

            </TabView>

        </FormCadastro>
    )
}
