import axios from "axios"

export async function cepSearch(cep = '') {

    cep = cep.replace(/\D/g, '')

    const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)

    return response.data
}

export async function cnpjSearch(cnpj = '') {

    cnpj = cnpj.replace(/\D/g, '')

    const response = await axios.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj}`)

    let cnaes = response.data.cnaes_secundarios.map(item => item.codigo).join(',')
    cnaes = cnaes === '0' ? '' : cnaes

    response.data.cnaesInLine = cnaes

    console.log(response)

    return response.data
}
