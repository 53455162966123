import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';
import * as auth from "infra/localStorage"
import server from 'infra/server.js';
import LoginService from './loginService'

export default function Empresa() {

    const [loginService] = useState(new LoginService())
    const [empresa, setEmpresa] = useState([]);
    const navigate = useNavigate()
    const toast = useRef(null);
    let selected = ''
    const defaultValues = [];
    const form = useForm({ defaultValues });
    const errors = form.formState.errors;

    // Depois de montada a tela.
    useEffect(() => {

        const http = server()

        http.get('empresa')
            .then((response) => {
                setEmpresa(response.data)
            })
    }, [])

    // Salva.
    const onSubmit = _ => {

        const http = server()
        http.post('empresa', { "codigo": selected }
        ).then(async _ => {
            const storageData = auth.getData()
            const emp = empresa.find((emp) => emp.codigo_emp == selected)
            storageData.empresa.codigo_emp = emp.codigo_emp
            storageData.empresa.nome_razao_pes = emp.nome_razao_pes
            storageData.empresa.nome_fantasia_pej = emp.nome_fantasia_pej
            storageData.menu = await loginService.getMenu()
            auth.setData(storageData)

            form.reset()
            navigate('/')

        })

    };

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <div className='authContainer'>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-column align-items-left gap-2">

                <Toast ref={toast} />
                <h2>
                    Selecione uma Empresa
                </h2>
                {empresa.map((e) => {
                    return <Controller
                        key={e.nome_razao_pes}
                        name={e.nome_razao_pes}
                        control={form.control}
                        rules={{ required: false }}
                        defaultValue=""
                        render={(form) => (
                            <>
                                <Button key={form.field.name} label={e.codigo_emp + ' - ' + e.nome_fantasia_pej} type="submit" onClick={_ => selected = e.codigo_emp} id={form.field.name} {...form.field} icon="pi pi-home" />
                                {getFormErrorMessage(form.field.name)}
                            </>
                        )}
                    />
                })}

                <Link to={'/login'} className="MenuOpc">
                    Acessar com outra conta
                </Link>
            </form>
        </div>
    )
}
