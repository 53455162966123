import React, { useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { Button } from 'primereact/button'
import ProdutoService from '../produtoService'
import UnidadeService from 'business/cadastro/unidade/unidadeService'
import ControllerInput from 'base/ControllerInput'
import GridForm from 'base/GridForm'
import { confirmDialog } from 'primereact/confirmdialog'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { AutoComplete } from "primereact/autocomplete"
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'

export default function ProdutoFornecedor(props) {

    const [service] = useState(new ProdutoService())
    const [unidadeService] = useState(new UnidadeService())
    const { id, toast, form } = props

    const formFornecedor = useForm({
        mode: 'all',
        defaultValues: {
            codigo_fpr: null, fornecedor: null, ref_fabrica_fpr: undefined, fator_conversao_fpr: null, cod_uni_compra_fpr: null, mode: 'add'
        }
    })
    const [fornecedoresBox, setFornecedoresBox] = useState(null);
    const [unidadesBox, setUnidadesBox] = useState([]);

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "produto_fornecedor"
    })

    function fornecedorAction(data) {

        return (
            <div>
                <Button className="mr-1" label="" icon="pi pi-pencil" size="small" onClick={(e) => fornecedorHandleRowEdit({ data, e })} severity="success" text />
                <Button label="" icon="pi pi-trash" size="small" onClick={(e) => fornecedorHandleRowDelete({ data, e })} severity="warning" text />
            </div>
        )
    }
    async function fornecedorHandleRowEdit({ data, e }) {

        e.preventDefault()
        await loadUnidadesOnce()

        const selectedFor = { codigoPes: data.cod_for_fpr, nomrazPes: data.meta.nome_razao_pes }
        const selectedUni = { codigoUni: data.cod_uni_compra_fpr, descriUni: data.meta.descricao_uni }

        // setFornecedoresBox([selectedFor])

        formFornecedor.setValue('fornecedor', selectedFor, { shouldValidate: true })
        formFornecedor.setValue('id', data.codigo_fpr, { shouldValidate: true })
        formFornecedor.setValue('ref_fabrica_fpr', data.ref_fabrica_fpr, { shouldValidate: true })
        formFornecedor.setValue('fator_conversao_fpr', data.fator_conversao_fpr, { shouldValidate: true })
        formFornecedor.setValue('cod_uni_compra_fpr', selectedUni, { shouldValidate: true })
        formFornecedor.setValue('mode', 'edit', { shouldValidate: true })

    }

    function fornecedorHandleRowDelete({ data: row, e }) {
        e.preventDefault()


        confirmDialog({
            message: `Confirma remover ${row.meta.nome_razao_pes} (${row.ref_fabrica_fpr})?`,
            icon: 'pi pi-question-circle',
            accept: () => {

                const newList = (form.getValues('produto_fornecedor') || []).filter((e) => e.codigo_fpr !== row.codigo_fpr)

                /** Essas duas linhas abaixo é apenas para sujar o form e essa é a solução não ideal */
                append({})
                remove(fields.length)
                form.setValue('produto_fornecedor', newList)

                // Add to removed list to send to backend
                if (row.codigo_fpr > 0) {
                    const removed = form.getValues('produtoFornecedorRemoved') || []
                    removed.push(row.codigo_fpr)
                    form.setValue('produtoFornecedorRemoved', removed)
                }

                clearformFornecedor()

                toast.current.show({ severity: 'info', summary: 'Removido!', detail: `Fornecedor removido!`, life: 5000 })

            }
        })

    }

    const insertFornecedor = () => {

        const newList = form.getValues('produto_fornecedor') || []
        const tmpId = Math.min(newList.reduce((a, e) => a.codigo_fpr < e.codigo_fpr ? a : e, 0).codigo_fpr, 0) - 1 || -1

        if (formFornecedor.getValues('ref_fabrica_fpr') && formFornecedor.getValues('fator_conversao_fpr') && formFornecedor.getValues('cod_uni_compra_fpr')
            && formFornecedor.getValues('fornecedor')) {

            const newOne = {
                codigo_fpr: tmpId,
                cod_for_fpr: formFornecedor.getValues('fornecedor').codigoPes,
                cod_pro_fpr: id,
                dah_ultima_compra_fpr: new Date(),
                fator_conversao_fpr: formFornecedor.getValues('fator_conversao_fpr'),
                ref_fabrica_fpr: formFornecedor.getValues('ref_fabrica_fpr'),
                cod_uni_compra_fpr: formFornecedor.getValues('cod_uni_compra_fpr').codigoUni,
                atualizado_fpr: new Date(),
                valor_ultima_compra_fpr: 0.00,
                meta: {
                    nome_razao_pes: formFornecedor.getValues('fornecedor').nomrazPes,
                    descricao_uni: formFornecedor.getValues('cod_uni_compra_fpr').descriUni,
                }
            }

            newList.push(newOne)

            /** Essas duas linhas abaixo é apenas para sujar o form e essa é a solução não ideal por enquanto */
            append({})
            remove(fields.length)
            form.setValue('produto_fornecedor', newList)

            clearformFornecedor()

            toast.current.show({ severity: 'info', summary: 'Adicionado!', detail: `Fornecedor ${newOne.meta.nome_razao_pes} adicionado!`, life: 5000 })
        }

    }

    const updateFornecedor = () => {

        const codigo_fpr = formFornecedor.getValues('codigo_fpr')

        const newProdutoFornecedor = {
            codigo_fpr: codigo_fpr,
            cod_for_fpr: formFornecedor.getValues('fornecedor').codigoPes,
            cod_pro_fpr: id,
            dah_ultima_compra_fpr: new Date(),
            fator_conversao_fpr: formFornecedor.getValues('fator_conversao_fpr'),
            ref_fabrica_fpr: formFornecedor.getValues('ref_fabrica_fpr'),
            cod_uni_compra_fpr: formFornecedor.getValues('cod_uni_compra_fpr').codigoUni,
            atualizado_fpr: new Date(),
            valor_ultima_compra_fpr: 0.00,
            meta: {
                nome_razao_pes: formFornecedor.getValues('fornecedor').nomrazPes,
                descricao_uni: formFornecedor.getValues('cod_uni_compra_fpr').descriUni,
            }
        }


        const newList = form.getValues('produto_fornecedor').map(e => {
            if (e.codigo_fpr === id) {
                return newProdutoFornecedor
            }
            return e
        })

        append({})
        remove(fields.length)
        form.setValue('produto_fornecedor', newList)
        clearformFornecedor()

        toast.current.show({ severity: 'info', summary: 'Alterado!', detail: `Fornecedor ${newProdutoFornecedor.meta.nome_razao_pes} atualizado!`, life: 5000 })

    }

    const fornecedorSearch = (event) => {

        service.filterFornecedor({ search: event.query }).then((response) => {
            const forns = response.data.data
            setFornecedoresBox(forns)
        }).catch((error) => {
            console.log("Erro: ", error)
        })
    }

    function clearformFornecedor() {

        formFornecedor.reset()
        formFornecedor.setValue('ref_fabrica_fpr', '')
        formFornecedor.setValue('fator_conversao_fpr', null)
        formFornecedor.setValue('cod_uni_compra_fpr', null)
        formFornecedor.setValue('mode', 'add', { shouldValidate: true })

    }
    async function loadUnidadesOnce() {
        if (unidadesBox.length === 0) {
            await unidadeService.list('').then(rs => {
                const data = rs.data.data.map(e => ({ codigoUni: e.codigoUni, descriUni: e.descriUni }))
                setUnidadesBox(data)
            })
        }
    }


    return (


        <GridForm>
            <div>

            </div>
            <Controller
                name="value1"
                control={form.control}
                render={({ field, fieldState }) => (
                    // Verificar isso, se não tiver esse elemento o primeiro componente buga!
                    <div>
                    </div>
                )}
            />

            <div className={'field col-12 mb-2 md:col-3 lg:col-3 xl:col-4'} >

                <Controller
                    name="fornecedor"
                    control={formFornecedor.control}
                    rules={{ required: 'Value is required.' }}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className='mb-1 text-sm'>Fornecedor</label>
                            <AutoComplete
                                inputId={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                inputRef={field.ref}
                                suggestions={fornecedoresBox}
                                completeMethod={fornecedorSearch}
                                className={classNames('p-inputtext-sm', { 'p-invalid': fieldState.error })}
                                size='small'
                                field='nomrazPes'
                            />

                        </>
                    )}
                />
            </div>

            <ControllerInput field="ref_fabrica_fpr" form={formFornecedor} addClassName="md:col-3 lg:col-3 xl:col-2" type="text" label="Referência" />
            <ControllerInput field="fator_conversao_fpr" form={formFornecedor} addClassName="md:col-3 lg:col-3 xl:col-2" type="number" label="Fator de conversão" />
            <div className={'field col-12 mb-2 md:col-3 lg:col-3 xl:col-2'} >
                <Controller
                    name='cod_uni_compra_fpr'
                    control={formFornecedor.control}
                    rules={{ required: 'Value is required.' }}
                    defaultValue={''}
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className='mb-1 text-sm'>Unidade de compra</label>
                            <Dropdown
                                {...field}
                                inputId={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                inputRef={field.ref}
                                options={unidadesBox}
                                className={classNames('p-inputtext-sm', { 'p-invalid': fieldState.error })}
                                size='small'
                                optionLabel="descriUni"
                                placeholder=""
                                field='descriUni'
                                onClick={() => {
                                    loadUnidadesOnce()
                                }}
                            />
                        </>
                    )}
                />
            </div>

            {formFornecedor.getValues('mode') === 'add' && <div className={'align-self-end md:col-3 lg:col-3 xl:col-2'}>
                <Button id="bntAddFor" label='Adicionar' icon="pi pi-add" onClick={(e, fprData) => {
                    e.preventDefault()
                    insertFornecedor()
                }} severity="success" />
            </div>
            }
            {formFornecedor.getValues('mode') === 'edit' && <div className={'flex gap-2 align-self-end md:col-3 lg:col-3 xl:col-2'}>
                <Button id="bntAddFor" label='Salvar' icon="pi pi-add" onClick={(e) => {
                    e.preventDefault()
                    updateFornecedor()
                }} severity="success" />
                <Button id="bntAddFor" label='Cancelar' icon="pi pi-arrow-left" onClick={(e) => {
                    e.preventDefault()
                    clearformFornecedor()
                }} severity="warning" />
            </div>
            }

            <div className={'field col-12 mb-2 md:col-12 lg:col-12 xl:col-12'} >
                <Controller
                    name='produto_fornecedor'
                    defaultValue={''}
                    control={form.control}
                    render={({ field, fieldState }) => (
                        <DataTable value={field.value} dataKey="codigo_fpr" size="small" showGridlines >
                            <Column field="meta.nome_razao_pes" sortable header="Fornecedor" body={(p) => p.meta.nome_razao_pes + ' - ' + p.cod_for_fpr}></Column>
                            <Column field="ref_fabrica_fpr" sortable header="Ref. fáb."></Column>
                            <Column field="fator_conversao_fpr" sortable header="Fator de conversão" align='center' ></Column>
                            <Column field="meta.descricao_uni" sortable header="Un. de compra" body={(p) => p.meta.descricao_uni} align='center' ></Column>
                            <Column body={fornecedorAction}></Column>
                        </DataTable>
                    )}
                />
            </div>

        </GridForm>

    )
}

