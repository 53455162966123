import React, { useState } from 'react'
import AdvancedSearchBar from './AdvancedSearchBar'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { SplitButton } from 'primereact/splitbutton'

export default function SearchBar(props) {

    console.log('props.searchConfig', props.searchConfig)

    const [field, setField] = useState(props.searchConfig?.mainField?.value)
    const [showAdvancedBar, setShowAdvancedBar] = useState(props.searchConfig.searchType === 'adv')

    const onSearchClick = (config) => {

        if (config.searchType === 'main') {
            props.onMainSearchClick(config)
        } else if (config.searchType === 'adv') {
            props.onAdvSearchClick(props.searchConfig)
        }

    }

    const handleValueChange = (newValue) => {
        setField(newValue.target.value)
    }

    const leftSide = (_data) => {
        const items = [
            {
                label: 'Pesquisa avançada',
                icon: 'pi pi-sliders-h',
                command: () => {
                    setShowAdvancedBar(true)
                }
            },
        ]
        return (
            <div className="p-inputgroup flex-1">
                <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                </span>
                <InputText id="procurar" placeholder="Procurar" inputMode="search" className='mr-0'
                    value={field}
                    onChange={handleValueChange}
                    autoComplete='off'
                    onKeyUp={data => {

                        if (data.key === 'Enter') {
                            props.searchConfig.searchType = 'main'
                            props.searchConfig.mainField.value = data.target.value
                            setField(data.target.value)
                            onSearchClick(props.searchConfig)

                        }

                    }}
                />

                <SplitButton
                    menuClassName='w-auto'
                    label="Pesquisar"
                    icon="pi pi-search"
                    dropdownIcon="pi pi-angle-down"
                    onClick={() => {

                        props.searchConfig.mainField.value = field
                        props.searchConfig.searchType = 'main'
                        onSearchClick(props.searchConfig)

                    }}
                    severity="success"
                    outlined
                    model={items}
                />

            </div>
        )
    }
    const rightSide = (_data) => {
        return (
            <div>
                <div className="card flex justify-content-center w-auto" >
                    <Button
                        label="Novo"
                        icon="pi pi-plus"
                        onClick={props.onNewClick}
                        severity="success"
                        outlined
                    />
                </div>
            </div>
        )

    }

    return (

        <div>
            <Toolbar className="mb-2" start={leftSide} end={rightSide}></Toolbar>
            {showAdvancedBar &&
                <AdvancedSearchBar
                    barClassName='mb-2 p-2 fadein'
                    config={props.searchConfig}
                    onCloseClick={_ => setShowAdvancedBar(false)}
                    onSearchClick={onSearchClick}
                />
            }
        </div>

    )
}

