import ServiceBase from "base/baseService"

export default class MunicipioService extends ServiceBase {

    title = "Município"
    pluralTitle = "Municípios"
    url = '/cadastro/pessoa/municipio'
    frontUrl = '/cadastro/pessoa/pessoa/municipio/municipio'
    frontUrlList = '/cadastro/pessoa/pessoa/municipio/municipiolist'
    primaryKey = 'codigo_mun'

}

