// import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
// import "primereact/resources/themes/bootstrap4-light-purple/theme.css"
// import "primereact/resources/themes/bootstrap4-dark-blue/theme.css"
// import "primereact/resources/themes/bootstrap4-dark-purple/theme.css"
// import "primereact/resources/themes/md-light-indigo/theme.css"               // Label na linha do input
// import "primereact/resources/themes/md-light-deeppurple/theme.css"
// import "primereact/resources/themes/md-dark-indigo/theme.css"
// import "primereact/resources/themes/md-dark-deeppurple/theme.css"
// import "primereact/resources/themes/mdc-light-indigo/theme.css"
// import "primereact/resources/themes/mdc-light-deeppurple/theme.css"
// import "primereact/resources/themes/mdc-dark-indigo/theme.css"
// import "primereact/resources/themes/mdc-dark-deeppurple/theme.css"
// import "primereact/resources/themes/tailwind-light/theme.css"
// import "primereact/resources/themes/fluent-light/theme.css" // Good                      Actual
// import "primereact/resources/themes/lara-light-blue/theme.css"
// import "primereact/resources/themes/lara-light-indigo/theme.css"
// import "primereact/resources/themes/lara-light-purple/theme.css"
// import "primereact/resources/themes/lara-light-teal/theme.css"
// import "primereact/resources/themes/lara-dark-blue/theme.css"
// import "primereact/resources/themes/lara-dark-indigo/theme.css"
// import "primereact/resources/themes/lara-dark-purple/theme.css"
// import "primereact/resources/themes/lara-dark-teal/theme.css"
// import "primereact/resources/themes/soho-light/theme.css"
// import "primereact/resources/themes/soho-dark/theme.css"
// import "primereact/resources/themes/viva-light/theme.css"
// import "primereact/resources/themes/viva-dark/theme.css"
// import "primereact/resources/themes/mira/theme.css"
import "primereact/resources/themes/nano/theme.css" // Very compacted
// import "primereact/resources/themes/saga-blue/theme.css"
// import "primereact/resources/themes/saga-green/theme.css"
// import "primereact/resources/themes/saga-orange/theme.css"
// import "primereact/resources/themes/saga-purple/theme.css"
// import "primereact/resources/themes/vela-blue/theme.css"
// import "primereact/resources/themes/vela-green/theme.css"
// import "primereact/resources/themes/vela-orange/theme.css"
// import "primereact/resources/themes/vela-purple/theme.css"
// import "primereact/resources/themes/arya-blue/theme.css"
// import "primereact/resources/themes/arya-green/theme.css"
// import "primereact/resources/themes/arya-orange/theme.css"
// import "primereact/resources/themes/arya-purple/theme.css"

// import "estilo/theme-fizaqui.css"
// import "primeflex/themes/primeone-light.css"

import * as React from "react"
import { createRoot } from "react-dom/client"
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom"
import Produto from "business/cadastro/produto/Produto"
import ProdutoList from "business/cadastro/produto/ProdutoList"

import Perfil from "business/cadastro/perfil/Perfil"
import PerfilList from "business/cadastro/perfil/PerfilList"

import Config from "business/configuracao/config/Config"
import ConfigList from "business/configuracao/config/ConfigList"

import Empresa from "business/configuracao/empresa/Empresa"
import EmpresaList from "business/configuracao/empresa/EmpresaList"

import PessoaFisica from "business/cadastro/pessoa/pessoaFisica/PessoaFisica"
import PessoaFisicaList from "business/cadastro/pessoa/pessoaFisica/PessoaFisicaList"

import PessoaJuridica from "business/cadastro/pessoa/pessoaJuridica/PessoaJuridica"
import PessoaJuridicaList from "business/cadastro/pessoa/pessoaJuridica/PessoaJuridicaList"

import Usuario from "./business/cadastro/pessoa/usuario/Usuario.jsx"
import UsuarioList from "./business/cadastro/pessoa/usuario/UsuarioList.jsx"

import RamoAtividade from "business/cadastro/pessoa/ramoAtividade/RamoAtividade"
import RamoAtividadeList from "business/cadastro/pessoa/ramoAtividade/RamoAtividadeList"

import Profissao from "business/cadastro/pessoa/profissao/Profissao"
import ProfissaoList from "business/cadastro/pessoa/profissao/ProfissaoList"

import ClassificacaoPessoa from "business/cadastro/pessoa/classificacaoPessoa/ClassificacaoPessoa"
import ClassificacaoPessoaList from "business/cadastro/pessoa/classificacaoPessoa/ClassificacaoPessoaList"

import GrupoPessoa from "business/cadastro/pessoa/grupoPessoa/GrupoPessoa"
import GrupoPessoaList from "business/cadastro/pessoa/grupoPessoa/GrupoPessoaList"

import CategoriaPessoa from "business/cadastro/pessoa/categoriaPessoa/CategoriaPessoa"
import CategoriaPessoaList from "business/cadastro/pessoa/categoriaPessoa/CategoriaPessoaList"

// import "estilo/theme-custom-nano.css"
import "primereact/resources/primereact.min.css"
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'estilo/App.css'
// import Contact from "./Contact"
import Estoque from "./business/estoque/Estoque"
import Login from "./workarea/Auth/Login"
import AuthLayout from "./AuthLayout"
import Home from "./Home"
import Register from "./workarea/Auth/Register"
import ErrorPage from "./ErrorPage"
import BaseDados from "./workarea/Auth/BaseDados"
import EmpresaAuth from "./workarea/Auth/Empresa"
import Sobre from "./business/ajuda/sobre/Sobre.jsx"
import * as auth from "./infra/localStorage.js"
import ConfirmRegister from "./workarea/Auth/ConfirmRegister"
import PrimeReact, { addLocale } from 'primereact/api'
// import i18next from "i18next"

import _i18n from "helper/i18n.js"

addLocale('pt', {
  closeText: 'Fechar',
  prevText: 'Anterior',
  nextText: 'Próximo',
  currentText: 'Começo',
  monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
  dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
  weekHeader: 'Semana',
  firstDay: 0,
  isRTL: false,
  showMonthAfterYear: false,
  yearSuffix: '',
  timeOnlyTitle: 'Só Horas',
  timeText: 'Tempo',
  hourText: 'Hora',
  minuteText: 'Minuto',
  secondText: 'Segundo',
  ampm: false,
  month: 'Mês',
  week: 'Semana',
  day: 'Dia',
  allDayText: 'Todo o Dia'
})

PrimeReact.ripple = true

const router = createBrowserRouter([
  {
    element: <AuthLayout />,
    children: [
      { path: "login", element: <Login />, loader: redirectIfUser },
      { path: "register", element: <Register />, action: logoutUser },
      { path: "confirmregister", element: <ConfirmRegister />, },
      { path: "base", element: <BaseDados />, },
      { path: "empresa", element: <EmpresaAuth />, },
      { path: "logout", action: logoutUser, },
    ],
  },
  {
    path: "/",
    element: <Home />,
    loader: () => {
      const storageData = auth.getData()
      if (!storageData.token) {
        return redirect("/login")
      }
      return false
    },
    children: [
      // {
      //   path: "contact",
      //   element: <Contact />,
      //   errorElement: <ErrorBoundary />,
      //   loader: () => {

      //     const logado = false
      //     if (!logado) {
      //       return redirect("/login")
      //     }
      //     return true
      //   }
      // },
      { path: "cadastro/pessoa/pessoafisicalist", element: <PessoaFisicaList /> },
      { path: "cadastro/pessoa/pessoafisica", element: <PessoaFisica /> },
      { path: "cadastro/pessoa/pessoafisica/:id", element: <PessoaFisica /> },
      { path: "cadastro/pessoa/pessoajuridicalist", element: <PessoaJuridicaList /> },
      { path: "cadastro/pessoa/pessoajuridica", element: <PessoaJuridica /> },
      { path: "cadastro/pessoa/pessoajuridica/:id", element: <PessoaJuridica /> },
      { path: "Cadastro/Pessoa/ramoatividadelist", element: <RamoAtividadeList /> },
      { path: "Cadastro/Pessoa/ramoatividade", element: <RamoAtividade /> },
      { path: "Cadastro/Pessoa/ramoatividade/:id", element: <RamoAtividade /> },
      { path: "cadastro/pessoa/profissaolist", element: <ProfissaoList /> },
      { path: "cadastro/pessoa/profissao", element: <Profissao /> },
      { path: "cadastro/pessoa/profissao/:id", element: <Profissao /> },
      { path: "cadastro/pessoa/classificacaopessoalist", element: <ClassificacaoPessoaList /> },
      { path: "cadastro/pessoa/classificacaopessoa", element: <ClassificacaoPessoa /> },
      { path: "cadastro/pessoa/classificacaopessoa/:id", element: <ClassificacaoPessoa /> },
      { path: "cadastro/pessoa/grupopessoalist", element: <GrupoPessoaList /> },
      { path: "cadastro/pessoa/grupopessoa", element: <GrupoPessoa /> },
      { path: "cadastro/pessoa/grupopessoa/:id", element: <GrupoPessoa /> },
      { path: "cadastro/pessoa/categoriapessoalist", element: <CategoriaPessoaList /> },
      { path: "cadastro/pessoa/categoriapessoa", element: <CategoriaPessoa /> },
      { path: "cadastro/pessoa/categoriapessoa/:id", element: <CategoriaPessoa /> },
      { path: "estoque", element: <Estoque /> },
      { path: "sobre", element: <Sobre /> },
      { path: "cadastro/produto/produtolist", element: <ProdutoList /> },
      { path: "cadastro/produto/produto", element: <Produto /> },
      { path: "cadastro/produto/produto/:id", element: <Produto /> },
      { path: "configuracao/config/configlist", element: <ConfigList /> },
      { path: "configuracao/config/config", element: <Config /> },
      { path: "configuracao/config/config/:id", element: <Config /> },
      { path: "configuracao/empresalist", element: <EmpresaList /> },
      { path: "configuracao/empresa", element: <Empresa /> },
      { path: "configuracao/empresa/:id", element: <Empresa /> },
      { path: "cadastro/perfil/perfillist", element: <PerfilList /> },
      { path: "cadastro/perfil/perfil", element: <Perfil /> },
      { path: "cadastro/perfil/perfil/:id", element: <Perfil /> },
      { path: "cadastro/pessoa/usuariolist", element: <UsuarioList /> },
      { path: "cadastro/pessoa/usuario", element: <Usuario /> },
      { path: "cadastro/pessoa/usuario/:id", element: <Usuario /> },
      { path: "*", element: <ErrorPage /> },

    ],
  },
])

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

function redirectIfUser() {
  return true
}

function logoutUser() {
  return
}

function ErrorBoundary() {
  console.log("ERRRRR...")
}

