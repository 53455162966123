import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import RamoAtividadeService from 'business/cadastro/pessoa/ramoAtividade/ramoAtividadeService'
import ControllerInput from 'base/ControllerInput'
import GridForm from 'base/GridForm'
import { yupResolver } from "@hookform/resolvers/yup"
import FormCadastro, { FormCadastroAux } from 'base/FormCadastro'
import { TabView, TabPanel } from 'primereact/tabview'

export default function RamoAtividade() {

    const [service, setService] = useState()

    if (!service) {
        setService(new RamoAtividadeService())
    }

    const stepperRef = useRef(null)
    const toast = useRef(null)
    const { id } = useParams()
    const [validations, setValidations] = useState([])

    const form = useForm({
        defaultValues: async () => await load(),
        resolver: yupResolver(validations),
        mode: "onBlur"
    })

    async function load() {
        const data = await FormCadastroAux.load({ id, service, toast, beforeSubmit, useNew: true })
        return data
    }

    const beforeSubmit = (data) => {

        let submitData = structuredClone(data)
        submitData = service.prepareToSave({ data: submitData })

        return submitData

    }

    useEffect(() => {
        service.getSchema().then(e => {
            setValidations(e)
        })

    }, [service])


    return (

        < FormCadastro service={service} form={form} aux={FormCadastroAux} toast={toast} beforeSubmit={beforeSubmit}>

            <TabView ref={stepperRef} className='text-sm' style={{ flexBasis: '1rem' }}>

                <TabPanel header="Capa" leftIcon="pi pi-home mr-2">

                    <GridForm>
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-4" field="descricao_rati" form={form} service={service} type="text" clearString label="Ramo de atividade" />
                    </GridForm>

                </TabPanel>

            </TabView>

        </FormCadastro >
    )
}
