import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import * as auth from "../../../infra/localStorage.js"

export default function Sobre() {
    const [sobre, setSobre] = useState(auth.getData());

    useEffect(() => {

    }, [])

    return (
        <Card title="Sobre">
            <p className="m-0">
                Usuário: {sobre.usuario.nome_usu} - {sobre.usuario.email_usu}
            </p>
            <p className="m-0">
                Base: {sobre.base.descricao_bad}
            </p>
            <p className="m-0">
                Empresa: {sobre.empresa.codigo_emp} - {sobre.empresa.nome_razao_pes}
            </p>
        </Card>
    );
}