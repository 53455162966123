import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import InputFrame from "./InputFrame";
import { InputMask } from "primereact/inputmask";

export default function InputTextForm(props) {

    const field = props.controller.field
    const fieldState = props.controller.fieldState

    const handleOnBlur = (e) => {
        if (props?.clearString) {
            const cleanedValue = e.target.value.replace(/\s\s+/g, ' ').trim()
            if (cleanedValue !== e.target.value) {
                field.onChange(cleanedValue)
            }
        }
        if (props?.noSpace) {
            const cleanedValue = e.target.value.replace(/ /g, '').trim()
            if (cleanedValue !== e.target.value) {
                field.onChange(cleanedValue)
            }
        }
        if (field.onBlur) field.onBlur(e)
        if (props.onBlur) props.onBlur(e)
    }
    return (
        <>
            <InputFrame {...props}>
                {props?.mask
                    ? <InputMask
                        id={field.name}
                        {...field}
                        autoComplete="off"
                        onBlur={handleOnBlur}
                        className={'p-inputtext-sm ' + (fieldState.isDirty ? '' : '') + classNames({ 'p-invalid': fieldState.error })}
                        inputMode={props.inputMode}
                        mask={props.mask}
                        unmask="true"
                        pattern={props.pattern}
                        onKeyDown={props.onKeyDown}
                    />
                    : <InputText
                        id={field.name}
                        {...field}
                        autoComplete="off"
                        onBlur={handleOnBlur}
                        className={'p-inputtext-sm ' + (fieldState.isDirty ? '' : '') + classNames({ 'p-invalid': fieldState.error })}
                        inputMode={props.inputMode}
                        pattern={props.pattern}
                        onKeyDown={props.onKeyDown}
                    />
                }
            </InputFrame>
        </>
    )
}
