import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import PerfilService from './perfilService'
import ControllerInput from 'base/ControllerInput'
import GridForm from 'base/GridForm'
import { yupResolver } from "@hookform/resolvers/yup"
import FormCadastro, { FormCadastroAux } from 'base/FormCadastro'
import { Toast } from 'primereact/toast'
import { TreeTable } from 'primereact/treetable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Panel } from 'primereact/panel'
import { Message } from 'primereact/message'
import { DataTable } from 'primereact/datatable'
import { useTranslation } from 'react-i18next'

export default function Perfil() {

    const { t } = useTranslation()
    const [service] = useState(new PerfilService())
    const toast = useRef(null)
    const { id } = useParams()
    const [validations, setValidations] = useState([])

    const [membroList, setMembroList] = useState([])

    const [menulist, setMenuList] = useState([])
    const [permissaolist, setPermissaoList] = useState([])

    const [selectedMenu, setSelectedMenu] = useState([])
    const [selectedPermissao, setSelectedPermissao] = useState([])

    const form = useForm({
        defaultValues: async () => await load(),
        resolver: yupResolver(validations)
    })

    async function load() {

        const data = await FormCadastroAux.load({ id, service: service, toast, useNew: true, beforeSubmit })
        const allMenus = await service.menuToTreeTable({ menus: data.meta.menu })
        const allPermissoes = data.meta.permissao

        setMenuList(allMenus)
        setPermissaoList(allPermissoes)
        setMembroList(data.meta.membro)

        if (id) {
            setSelectedMenu(service.menuPerfilToSelected(data))
            setSelectedPermissao(service.menuPermissaoToSelected(data))
        }

        return data
    }

    const beforeSubmit = (data) => {

        /* Para não perder data.perfil_menu ao tentar salvar pela segunda vez */
        const submitData = { ...data }

        delete submitData.fieldNameDirty
        submitData.perfil_menu = service.preparePerfilMenuToSave({ data: submitData, selectedMenu })
        submitData.perfil_permissao = service.preparePerfilPermissaoToSave({ data: submitData, selectedPermissao })

        return submitData

    }

    useEffect(() => {
        service.getSchema().then(setValidations)
    }, [service])

    const actionTemplate = (elem) => {
        const buttons = ['Ler', 'Inserir', 'Atualizar', 'Deletar']

        return (
            <div>
                {elem.key in selectedMenu && elem.children.length === 0 && (
                    <div className="flex flex-wrap gap-2">
                        {buttons.map((buttonLabel, index) => (
                            <Button
                                className={`pt-0 pb-0 mt-0 mb-0 text-sm ${selectedMenu[elem.key][`${buttonLabel.toLowerCase()}_pmen`] ? '' : 'p-button-secondary'}`}
                                key={index}
                                type="button"
                                // rounded
                                outlined={!selectedMenu[elem.key][`${buttonLabel.toLowerCase()}_pmen`]}
                                onClick={(e) => {
                                    form.setValue('fieldNameDirty', 'novo valor', { shouldDirty: true })
                                    selectedMenu[elem.key][`${buttonLabel.toLowerCase()}_pmen`] = selectedMenu[elem.key][`${buttonLabel.toLowerCase()}_pmen`] ? 0 : 1
                                    setSelectedMenu({ ...selectedMenu })
                                }}
                            >
                                {buttonLabel}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        )
    }

    const menuAddDefaultValuesKeys = (keys) => {

        for (const codigoPmen in keys) {
            const opc = keys[codigoPmen]
            if (!('ler_pmen' in opc)) {
                opc['ler_pmen'] = 1
                opc['inserir_pmen'] = 1
                opc['atualizar_pmen'] = 1
                opc['deletar_pmen'] = 1
            }
        }

        return keys
    }


    return (

        < FormCadastro service={service} form={form} aux={FormCadastroAux} beforeSubmit={beforeSubmit}>
            <Toast ref={toast} />

            <GridForm>
                <ControllerInput field="descricao_perf" form={form} addClassName="md:col-6 lg:col-6 xl:col-12" service={service} type="text" label="Descrição" />
            </GridForm>
            <Panel className="mb-4" header={"Menu"} >

                <TreeTable

                    // tableStyle={{ width: 'auto' }}
                    id="codigo_men" // Parece não estar surtindo efeito!
                    key="codigo_men" // Parece não estar surtindo efeito!
                    itemID='codigo_men' // Parece não estar surtindo efeito!
                    className="p-treetable-my-custom-style"
                    value={menulist}
                    selectionMode="checkbox"
                    expandedKeys={{ '1': true, '10': true }}
                    selectionKeys={selectedMenu}
                    metaKeySelection={false}
                    onSelectionChange={(e) => {
                        const widthDefault = menuAddDefaultValuesKeys(e.value)
                        setSelectedMenu(widthDefault)
                        form.setValue('fieldNameDirty', 'novo valor', { shouldDirty: true })
                    }}
                    style={{ borderCollapse: 'collapse' }}
                >
                    <Column field="name" expander />
                    <Column body={actionTemplate}  ></Column>

                </TreeTable>

                {/* <div className='flex mt-2 p-2 '>SELECTEDMENU: {JSON.stringify(selectedMenu)}</div> */}
                {/* <div className='flex mt-2 p-2 '>MENULIST: {JSON.stringify(menulist)}</div> */}

            </Panel>
            <DataTable
                value={permissaolist}
                selectionMode='checkbox'
                selection={selectedPermissao}
                onSelectionChange={(e) => {
                    setSelectedPermissao(e.value)
                    form.setValue('fieldNameDirty', 'novo valor', { shouldDirty: true })
                }}
                dataKey="codigo_per"
                className="mb-4"
            // tableStyle={{ minWidth: '50rem' }}
            >
                <Column selectionMode="multiple"
                    headerStyle={{ width: '3rem' }}
                ></Column>
                <Column field="descricao_per" header="Permissão"></Column>
                <Column field="codigo_per" header="Código"></Column>
            </DataTable>

            {/* <div className='flex mt-2 p-2 '>PERMISSAOLIST: {JSON.stringify(permissaolist)}</div> */}

            {/* <div className='flex mt-2 p-2 '>SELECTEDPERMISSAO: {JSON.stringify(selectedPermissao)}</div> */}

            {/* <div className='flex mt-2 p-2 '>tmpNewPerfilPermissao: {JSON.stringify(tmpNewPerfilPermissao)}</div> */}


            <DataTable
                value={membroList}
                dataKey="login_usu"
                className="mb-4"
            >
                <Column field="nome_razao_pes" header="Membros"></Column>
                <Column field="login_usu" header="Login"></Column>
                <Column
                    field="criado_pes"
                    header="Criação"
                    body={(rowData) => new Date(rowData.criado_pes).toLocaleDateString()}
                ></Column>
            </DataTable>

            <Message severity="info" text={t('perfilHelp')}
                className='mt-4'
            />

        </FormCadastro >
    )
}

