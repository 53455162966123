import { useTranslation } from "react-i18next"

export default function Form({ form, title, children, onSubmit, decorateless }) {

    const { t } = useTranslation()

    title = title || ''

    if (form) {

        return (
            <div className={(decorateless ? "" : "form-shape") + " col-12"}>
                <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className={title ? "mb-4" : ""}>{t(title)}</div>
                    {children}
                </form>
            </div>
        )
    }

    return (
        <div className={(decorateless ? "" : "form-shape") + " col-12"}>
            <div className={title ? "mb-4" : ""}>{t(title)}</div>
            {children}
        </div>
    )

}