import React, { useEffect, useRef, useState } from 'react'
import { Column } from 'primereact/column'
import EmpresaService from "./empresaService"
import FormList from 'base/FormList'
import { InputSwitch } from 'primereact/inputswitch'

export default function EmpresaList() {

    const [service, setService] = useState()
    const toast = useRef(null)

    if (!service) {
        setService(new EmpresaService())
    }

    const searchData = {
        pkName: 'codigo_emp',
        dsName: 'nome_razao_pes',
        mainField: { name: 'nome_razao_pes', label: 'Razão social' },
        advFields: [
            { name: 'nome_razao_pes', label: 'Razão social', type: 'string', inputMode: 'text' },
            { name: 'nome_fantasia_pej', label: 'Fantasia', type: 'string', inputMode: 'text' },
            { name: 'codigo_emp', label: 'Código', type: 'integer', inputMode: 'numeric' },
        ]
    }

    useEffect(() => {
    }, [service])

    return (
        <FormList service={service} toast={toast} searchData={searchData} showActions={true}>
            <Column field="meta.nome_razao_pes" sortable header="Razão social"></Column>
            <Column field="meta.nome_fantasia_pej" sortable header="Fantasia"></Column>
            <Column field="ativa_emp" body={(empresa) => <InputSwitch checked={empresa.ativa_emp === 1}></InputSwitch>} sortable header="Acessível"></Column>
        </FormList >
    )
}