
import { useLocation, useParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import Form from 'base/Form'
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog'
import { useEffect } from 'react'
import { Toast } from 'primereact/toast'

export default function FormCadastro(props) {

    const { service, children, form, aux, toast } = props

    const navigate = useNavigate()
    const pesquisa = useLocation()
    const { id } = useParams()

    const isFormEdited = form.formState.isDirty

    useEffect(() => {
        handleValidationError()
    }, [form.formState.errors])

    const handleValidationError = () => {

        if (Object.keys(form.formState.errors).length > 0) {
            console.log("Validation error:", form.formState.errors)
            if (props.onValidationError) {
                props.onValidationError()
            } else {

                if (props.toast) {
                    props.toast.current.show({
                        severity: 'info', summary: 'Preenchimentos',
                        detail: 'Verifique os campos marcados com asteríscos!', life: 3000
                    })
                }
            }
        }

    }

    /**
     * Submit, caso tenham problemas no schema onSubmit não é disparado!
     * @param {*} data 
     */
    function onSubmit(data) {

        console.log('FormCadastro.onSubmit:', data)

        let dataCopy = { ...data }
        console.log('Submiting (B) ')

        if (typeof props.beforeSubmit === 'function') {
            dataCopy = props.beforeSubmit(dataCopy)
            if (dataCopy === false) {
                return false
            }
        }

        console.log('Submiting: ', dataCopy)

        confirmDialog({
            message: 'Confirma salvar?',
            icon: 'pi pi-question-circle',
            accept: () => {

                service.save(id, dataCopy).then(async (_response) => {
                    navigate(service.frontUrlList, pesquisa)
                }).catch((err) => {
                    aux.showExceptions(err)
                })

            }
        })

    }

    const handleCancel = (e) => {

        const cancelEvent = () => {
            if (props.onCancel) {
                props.onCancel()
            } else {
                navigate(service.frontUrlList, pesquisa)
            }
        }

        if (isFormEdited) {
            confirmDialog({
                message: 'Confirma abandonar a edição?',
                icon: 'pi pi-question-circle',
                accept: () => {
                    cancelEvent()
                }
            })
        } else {
            cancelEvent()
        }

        e.preventDefault()
    }

    function getFullTitle() {

        let fullTitle = service.title.toUpperCase()

        if (form.getValues(service.primaryKey)) {
            fullTitle += ' (Código ' + form.getValues(service.primaryKey) + ')'
        }

        fullTitle += ' ' + (form.formState.isDirty ? ' (Editando...)' : '')

        return fullTitle
    }

    return (

        < Form form={form} onSubmit={onSubmit} title={getFullTitle()} >
            <Toast ref={toast} />
            <ConfirmDialog />
            {children}
            < div className="mt-4 flex gap-2 justify-content-end" >
                <Button label="Salvar" size="normal" disabled={!isFormEdited} type='submit' icon="pi pi-save" severity="primary"
                    onAuxClick={e => {
                        console.log('Esse é o onAuxClick')
                    }}
                    onBlur={props.saveOnBlur}
                    onClick={props.saveOnClick}
                />
                <Button label="Voltar" size="normal" icon="pi pi-arrow-left" severity="success" onClick={handleCancel} />
            </div >


        </Form >
    )
}

export class FormCadastroAux {
    static toast

    static async load({ id = 'new', service, toast, useNew }) {

        console.log('Newson ID: ', id)

        let rs = { data: {} }
        this.toast = toast

        if (id === 'new') {
            if (useNew) {
                rs = await service.getById({ id })
            }
        } else {
            rs = await service.getById({ id })
        }

        if (this.hasErrorsShow(rs)) {
            return {}
        } else {
            return rs.data
            // const data = rs.data
            // return service.prepareToFront(data)
        }

    }

    static hasErrorsShow = (rs) => {
        const data = rs.response?.data

        const hasErrors = (data && data.messages?.errors) ||
            (data && data.messages?.error) ||
            (data && data.error)

        if (hasErrors) {
            this.showExceptions(rs)
        }

    }

    /**
     * Extrair essa função para reuso
     */
    static showExceptions = (rs) => {
        const data = rs.response?.data
        let messages = ''

        if (typeof data == 'object') {

            if (data?.messages?.errors) {
                const msgError = data?.messages?.errors
                if (Array.isArray(msgError)) {
                    messages = ''
                    msgError.map(e => {
                        const msg = e.message + '. '
                        messages += msg
                        return ''
                    })
                }
            } else if (data && typeof data?.error == 'string') {
                messages += data.error
            }
        } else if (typeof data == 'string') {
            messages = data
        }

        if (!messages && rs?.message) {
            messages = rs.message + '. '
        }

        if (data?.message?.length > 0) {
            messages = data?.message
        }

        this.toast.current.show({ severity: 'error', summary: 'Ops!', detail: 'Mensagem técnica: ' + messages, life: 7000 })

    }
}
