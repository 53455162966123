/**
 *  save(id = null, data = {}) {
 *    return super.save(id, data)
 * }
 */
import ServiceBase from "base/baseService"

export default class PerfilService extends ServiceBase {

    title = "Perfil de usuário"
    pluralTitle = "Perfis de usuário"
    url = '/cadastro/perfil'
    frontUrl = '/cadastro/perfil/perfil'
    frontUrlList = '/cadastro/perfil/perfillist'
    primaryKey = 'codigo_perf'

    postDTO(data = {}) {

        return {
            ...data
        }
    }
    prepareToFront(data = {}) {

        return {
            ...data
        }
    }

    async list(qs = '') {

        return super.list(qs).then((response) => {
            return response
        })
    }

    // Get show
    async getById({ id = null }) {

        return super.getById({ id, includes: 'menu,permissao,membro' }).then((response) => {
            response.data = this.prepareToFront(response.data)
            console.log('perfilService response: ', response)
            return response
        }).catch((error) => {
            console.log("Erro (service): ", error)
            return error
        })

    }

    async menuToTreeTable({ menus }) {

        menus = await menus.map(e => ({
            key: String(e.codigo_men),
            label: e.descricao_men,
            data: {
                name: e.descricao_men,
                codigo_men: e.codigo_men,
                ler_pmen: true,
                inserir_pmen: true,
                atualizar_pmen: true,
                deletar_pmen: true,
            },
            icon: e.icone_men,
            order: e.ordem_men.replace(/\./g, '-'),
            ordem_men: e.ordem_men
        }))

        const arvore = [];

        for (const menu of menus) {
            const partesOrdem = menu.ordem_men.split('.');
            let nodoAtual = arvore;

            for (let index = 0; index < partesOrdem.length; index++) {
                const parte = partesOrdem[index];

                if (!nodoAtual.find(node => node.ordem === parte)) {
                    const novoNodo = {
                        key: menu.key,
                        data: menu.data,
                        ordem: parte,
                        ordem_men: menu.ordem_men,
                        label: index === partesOrdem.length - 1 ? menu.label : null,
                        icon: menu.icon,
                        route: menu.route,
                        children: [],
                    };
                    nodoAtual.push(novoNodo);
                }

                nodoAtual = nodoAtual.find(node => node.ordem === parte).children;
            }
        }

        return arvore;
    }

    menuPermissaoToSelected(data) {
        const selecteds = data.perfil_permissao.map(e => (
            {
                codigo_per: e.cod_per_pper
            }
        ))

        return selecteds
    }


    menuPerfilToSelected(data) {

        const treeFormat = {}
        for (const elem of data.perfil_menu) {

            const parcial = elem.parcial_sel_pmen == 1 || false

            treeFormat[elem.cod_men_pmen] = {
                checked: !parcial,
                partialChecked: parcial,
                atualizar_pmen: elem.atualizar_pmen,
                deletar_pmen: elem.deletar_pmen,
                inserir_pmen: elem.inserir_pmen,
                ler_pmen: elem.ler_pmen,
            }
        }
        return treeFormat

    }
    preparePerfilPermissaoToSave({ data, selectedPermissao }) {

        const newPerfilPermissao = []
        let found

        // DEL
        for (const perfilPermissao of data.perfil_permissao) {
            found = selectedPermissao.some(e => e.codigo_per == perfilPermissao.cod_per_pper)
            if (!found) {
                newPerfilPermissao.push({ action: 'del', ...perfilPermissao })
            }
        }

        // INS
        for (const selected of selectedPermissao) {
            found = data.perfil_permissao.some(e => e.cod_per_pper === selected.codigo_per)
            if (!found) {
                newPerfilPermissao.push({ action: "ins", codigo_pper: 0, cod_perf_pper: 0, cod_per_pper: selected.codigo_per, valor_pper: null })
            }
        }

        return newPerfilPermissao

    }

    preparePerfilMenuToSave({ data, selectedMenu }) {

        // Vou percorrer o perfil_menu que chegou ao abrir a tela,
        // definir a action de cada registro como 'insert,delete,update'

        const newPerfilMenu = []
        let found

        for (const elem of data.perfil_menu) {

            found = false
            for (const codigoPmen in selectedMenu) {
                const item = selectedMenu[codigoPmen]
                if (codigoPmen == elem.cod_men_pmen) {
                    if (item.partialChecked !== (elem.parcial_sel_pmen == 1)
                        || item.atualizar_pmen !== elem.atualizar_pmen
                        || item.deletar_pmen !== elem.deletar_pmen
                        || item.inserir_pmen !== elem.inserir_pmen
                        || item.ler_pmen !== elem.ler_pmen
                    ) {

                        newPerfilMenu.push({
                            action: 'upd',
                            ...elem,
                            parcial_sel_pmen: item.partialChecked,
                            atualizar_pmen: item.atualizar_pmen | 0,
                            deletar_pmen: item.deletar_pmen | 0,
                            inserir_pmen: item.inserir_pmen | 0,
                            ler_pmen: item.ler_pmen | 0,
                        })
                    }
                    found = true
                    break
                }
            }
            if (!found) {
                newPerfilMenu.push({ action: 'del', ...elem })
            }

        }


        // Adicionar os novos
        for (const codigoPmen in selectedMenu) {
            const item = selectedMenu[codigoPmen]

            found = false
            for (const elem of data.perfil_menu) {
                if (codigoPmen == elem.cod_men_pmen) {
                    found = true
                    break
                }
            }
            if (!found) {
                const newOne = {
                    cod_perf_pmen: 0,
                    cod_men_pmen: +codigoPmen,
                    parcial_sel_pmen: item.partialChecked,
                    atualizar_pmen: item.atualizar_pmen | 0,
                    deletar_pmen: item.deletar_pmen | 0,
                    inserir_pmen: item.inserir_pmen | 0,
                    ler_pmen: item.ler_pmen | 0,
                }
                newPerfilMenu.push({ action: 'ins', ...newOne })
            }
        }

        return newPerfilMenu
    }

}

