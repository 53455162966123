import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { classNames } from 'primereact/utils';
import { Password } from "primereact/password";
import { InputText } from 'primereact/inputtext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as auth from "infra/localStorage.js"
import server from 'infra/server';
import LoginService from './loginService'

export default function Login() {

    const [loginService] = useState(new LoginService())
    const { state } = useLocation();
    const [message, setMessage] = useState('');

    const navigate = useNavigate()
    const toast = useRef(null);
    const { msgRegistered } = state || ""

    useEffect(() => {

        if (msgRegistered && msgRegistered.length > 0) {
            setMessage(msgRegistered)
        }

    }, [])



    // const defaultValues = [{ email: 'ivan@fizaqui.com.br' }, { value: '123' }];
    const defaultValues = [];

    const form = useForm({ defaultValues });
    const errors = form.formState.errors;

    const onSubmit = (data) => {

        if (data.email && data.password) {

            const storageData = auth.getData()

            const http = server()

            http.post('login', {
                "loginUsu": data.email,
                "password": data.password
            }
            ).then((response) => {
                storageData.token = response.data.token
                auth.setData(storageData)

                const httpInfo = server()
                httpInfo.get('sessaoinfo')
                    .then(async (response) => {

                        const dataInfo = response.data[0]

                        storageData.usuario.nome_usu = dataInfo.nome_usu
                        storageData.usuario.email_usu = dataInfo.login_usu
                        storageData.base.codnum_bad = dataInfo.codnum_bad
                        storageData.base.descricao_bad = dataInfo.descricao_bad
                        storageData.empresa.codigo_emp = dataInfo.cod_emp_logada_ses
                        auth.setData(storageData)

                        if (storageData.base.codnum_bad && storageData.empresa.codigo_emp) {

                            /**
                                 *  Esse getMenu deve ser chamado dentro de /base e /empresa caso entre nesses Ifs
                                 */
                            storageData.menu = await loginService.getMenu()
                            auth.setData(storageData)

                            // Busca a descrição da empresa
                            server().get('empresa').then((response) => {

                                const selectEmp = response.data.find((e) => e.codigo_emp == storageData.empresa.codigo_emp)
                                storageData.empresa.nome_razao_pes = selectEmp.nome_razao_pes
                                storageData.empresa.nome_fantasia_pej = selectEmp.nome_fantasia_pej
                                auth.setData(storageData)

                                navigate('/')

                            })

                        } else {

                            if (!storageData.base.codnum_bad) {
                                navigate('/base')
                            } else if (!storageData.empresa.codigo_emp) {
                                navigate('/empresa')
                            } else {
                                navigate('/')
                            }
                        }
                        // form.reset()
                    })

            }).catch((error) => {

                let message = ''

                if (error.response && error.response.data) {
                    const reason = error.response.data
                    if (reason.includes('E_INVALID_AUTH_PASSWORD')) {
                        // alert("Login inválido!")
                        message = 'E-mail ou senha inválidos!'
                    } else {
                        message = reason
                    }
                }

                if (message) {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Login',
                        detail: message,
                        life: 4000
                    })
                }
            })


        }


    };

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <div className='authContainer'>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-column align-items-left gap-2">

                <Toast ref={toast} />
                <div>
                    <h2>Login</h2>
                    <p>{message}</p>
                </div>
                <Controller
                    name="email"
                    control={form.control}
                    rules={{ required: 'E-mail' }}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.email })}>
                            </label>
                            <InputText id={field.name} {...field} placeholder='E-mail' inputMode="email" className={classNames({ 'p-invalid': fieldState.error })} autoComplete="on" />
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                <Controller
                    name="password"
                    control={form.control}
                    rules={{ required: 'A senha é obrigatória' }}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.password })} />

                            <Password inputId={field.name} {...field} placeholder='Senha' className={classNames({ 'p-invalid': fieldState.error })} feedback={false}
                                toggleMask={false}
                                autoComplete="on" />
                            {/* <Password id={field.name} {...field} inputRef={field.ref} placeholder='Senha' className={classNames({ 'p-invalid': fieldState.error })} feedback={true} toggleMask autoComplete="on" /> */}
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                <Button label="Acessar" type="submit" icon="pi pi-check" />
                <Link to={'/register'} className="MenuOpc">
                    Registrar-se
                </Link>
            </form>
        </div>
    )
}
