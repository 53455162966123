import { Avatar } from "primereact/avatar"
import { Button } from "primereact/button"
import { OverlayPanel } from "primereact/overlaypanel"
import { Sidebar } from "primereact/sidebar"
import { useNavigate } from "react-router-dom"
import { MD5 } from 'crypto-js';
import * as auth from "infra/localStorage.js"
import { useRef, useState } from "react"

export default function UserAvatar() {

    const navigate = useNavigate()
    const [perfilSidebar, setPerfilSidebar] = useState(false)
    const overlayRef = useRef(null)
    const storageData = auth.getData()
    const emailMd5 = MD5(storageData.usuario.email_usu)
    const gravatar = `https://www.gravatar.com/avatar/${emailMd5}`;
    const handlePop = (event) => {
        overlayRef.current.toggle(event)
    }
    const logout = () => {
        auth.clear()
        navigate('/login')
    }
    const handleOnHidePop = (data) => {
    }

    return <div >
        <Avatar image={gravatar} shape="circle" size="small"
            onClick={handlePop}
            className="flex"
            style={{ borderRadius: 45 }}
        />
        <OverlayPanel
            className="grid mt-0"
            ref={overlayRef}
            appendTo={typeof window !== 'undefined' ? document.body : null}
            id="user_panel"
            onHide={handleOnHidePop}
        >
            <Button className="col-12" text onClick={(e) => setPerfilSidebar(true)} >Perfil</Button>
            <Button className="col-12" text onClick={logout}>Sair</Button>
        </OverlayPanel>
        <Sidebar visible={perfilSidebar} style={{ width: 400 }} position="right" onHide={() => setPerfilSidebar(false)}>
            <h2>
                Perfil
            </h2>
        </Sidebar>
    </div >

}