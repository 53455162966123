import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ProdutoService from './produtoService'
import { Panel } from 'primereact/panel'
import ControllerInput from 'base/ControllerInput'
import GridForm from 'base/GridForm'
import { yupResolver } from "@hookform/resolvers/yup"
import FormCadastro, { FormCadastroAux } from 'base/FormCadastro'
import { Toast } from 'primereact/toast'
import ProdutoFornecedor from './ProdutoFornecedor/ProdutoFornecedor'
import { Button } from 'primereact/button'


export default function Produto() {

    const [service] = useState(new ProdutoService())
    const toast = useRef(null)
    const { id } = useParams()
    const [validations, setValidations] = useState([]);

    const form = useForm({
        defaultValues: async () => await FormCadastroAux.load({ id, service, toast }),
        resolver: yupResolver(validations)
    })

    useEffect(() => {
        service.getSchema().then(setValidations)
    }, [service])


    return (

        < FormCadastro service={service} form={form} aux={FormCadastroAux}>
            <Toast ref={toast} />

            <Panel className="mb-4" header={"Capa"} >
                <GridForm>
                    <ControllerInput field="descricao_pro" form={form} addClassName="md:col-6 lg:col-6 xl:col-4" service={service} type="text" label="Descrição" />
                    <ControllerInput field="codigo_barra_pro" form={form} addClassName="md:col-3 lg:col-3 xl:col-2" service={service} type="text" label="Código de barras" rules={{ required: 'Código de barras é obrigatório!!!' }} />
                    <ControllerInput field="data_vencimento_pro" form={form} addClassName="md:col-3 lg:col-3 xl:col-2" service={service} type="calendar" label="Vencimento" />
                    <ControllerInput field="preco_custo_pro" form={form} addClassName="md:col-3 lg:col-3 xl:col-2" service={service} type="number" label="Preço de custo" />
                    <ControllerInput field="preco_varejo_pro" form={form} addClassName="md:col-3 lg:col-3 xl:col-2" service={service} type="number" label="Preço de varejo" rules={{ required: false }} />
                    <ControllerInput field="preco_atacado_pro" form={form} addClassName="md:col-3 lg:col-3 xl:col-2" service={service} type="number" label="Preço de atacado" />
                    <ControllerInput field="aliquota_pis_pro" form={form} addClassName="md:col-3 lg:col-3 xl:col-2" service={service} type="number" label="Alíquota do PIS" />
                    <ControllerInput field="aliquota_cofins_pro" form={form} addClassName="md:col-3 lg:col-3 xl:col-2" service={service} type="number" label="Alíquota do COFINS" minFractionDigits={3} maxFractionDigits={3} />
                </GridForm>
            </Panel>

            <Panel header={"Fornecedores"} toggleable>
                <ProdutoFornecedor form={form} id={id} toast={toast} />
            </Panel>

            <Button
                onClick={async (e) => {

                    e.preventDefault()
                    try {
                        console.log('Requiresdd: ', service.requiredFields)
                        // Validar os dados usando o Yup
                        console.log("Mostrando...")
                        // await form.trigger()
                        console.log(form.formState.errors)

                        // const user = await validations.validate({ name: 'jimmy', age: 24 })


                        // Se a validação passar, prosseguir com a submissão dos dados
                        // ...
                    } catch (error) {
                        // Se a validação falhar, exibir os erros
                        console.error('Erros de validação:', error.errors);
                        // Você pode exibir os erros em algum elemento na tela ou usar console.log
                        // Por exemplo:
                        toast.current.show({ severity: 'error', summary: 'Erros de validação', detail: error.errors.join('\n') });
                    }
                    console.log(form.formState.errors.lenght)
                }}
            >
                Mostra erros
            </Button>

        </FormCadastro >
    )
}

