import ServiceBase from "base/baseService"
import MunicipioService from "business/cadastro/pessoa/municipio/municipioService"
import { cepSearch } from "helper/searchers"

export default class PessoaService extends ServiceBase {

    title = "Pessoa"
    pluralTitle = "Pessoas"
    url = '/cadastro/pessoa/pessoa'
    frontUrl = '/cadastro/pessoa/pessoa'
    frontUrlList = '/cadastro/pessoa/pessoalist'
    primaryKey = 'codigo_pes'
    codTendResidencia = 1

    formatMunicipioOption = (codigo_mun, descricao_mun, sigla_uf) => {
        if (codigo_mun) {
            return { codigo_mun, descricao_munuf: descricao_mun + ' - ' + sigla_uf }
        }

        return { codigo_mun, descricao_munuf: '' }
    }

    enderecoSearch = async ({ cep, resolve, reject }) => {
        try {
            const response = await cepSearch(cep)

            if (response.ibge) {
                const optionMunicipio = this.formatMunicipioOption(response.ibge, response.localidade, response.uf)
                resolve({ response, optionMunicipio })
            } else {
                reject({ cep })
            }

        } catch (error) {
            console.log('Erro ao buscar endereço. Verifique o CEP e tente novamente.')
        }
    }

    getIndexEnderecoResidencial = (pessoaEndereco) => {

        let i = 0
        for (const [index, data] of pessoaEndereco.fields.entries()) {
            if (data.cod_tend_end.codigo_tend === this.codTendResidencia) {
                i = index
                break
            }
        }

        return i
    }

    getNewEndereco(data = {}) {
        return {
            action: 'ins',
            usa_desde_end: null,
            cod_tend_end: { codigo_tend: null },
            municipio: { uf: {} },
            ...data
        }

    }

    async filterMunicipio({ municipio, uf, codigoIbge }) {

        const queryString = `?page=1&perpage=7&filtertype=combo&${'descricao_mun=' + municipio}&${'descricao_uf=' + uf}&${'codigoIbge=' + codigoIbge}`
        console.log('queryString', queryString)

        return new MunicipioService().list(queryString)

    }

    addEnderecoResidencial(pessoa) {

        const foundResidencia = pessoa.pessoa_endereco.some(e => e.cod_tend_end === this.codTendResidencia)

        if (!foundResidencia) {

            const newResidencia = this.getNewEndereco({ cod_tend_end: this.codTendResidencia })
            pessoa.pessoa_endereco.push(newResidencia)

        }

    }

    enderecosCodigoToObject(pessoa, enderecoTipo) {
        pessoa.pessoa_endereco.forEach((end, i) => {
            if (typeof end.cod_tend_end === 'number') {
                end.action = end.action || 'none'
                end.cod_tend_end = enderecoTipo.find(e => e.codigo_tend === end.cod_tend_end)
                end.cod_mun_end = this.formatMunicipioOption(
                    pessoa.pessoa_endereco[i].municipio.codigo_mun,
                    pessoa.pessoa_endereco[i].municipio.descricao_mun,
                    pessoa.pessoa_endereco[i].municipio.uf.sigla_uf,
                )
            }
        })

    }

    formatActionOfEnderecos(dataPessoa, dirtyPessoa) {

        // O Dirty pode conter os campos do data porém o valor é true se foi alterado!
        // Exemplo: cod_mun_end = true

        if (dirtyPessoa?.pessoa_endereco) {
            for (const [i, end] of dirtyPessoa?.pessoa_endereco.entries()) { // As linhas
                for (const key in end) { // Os campos da linha
                    if (end[key] === true) {
                        if (dataPessoa.pessoa_endereco[i]?.action === 'none') {
                            dataPessoa.pessoa_endereco[i].action = 'upd'
                            // Basta um campo alterado para marcar a linha toda como 'upd'
                            break
                        }
                    }
                }
            }
        }

    }

}

