import React, { useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { classNames } from 'primereact/utils'
import { Password } from "primereact/password"
import { InputText } from 'primereact/inputtext'
import { InputMask } from "primereact/inputmask"
import { Link } from 'react-router-dom'
import { Checkbox } from "primereact/checkbox"
import server from 'infra/server'

export default function Register() {

    const [message, setMessage] = useState('')
    const toast = useRef(null)

    // const defaultValues = [{ email: 'ivan@fizaqui.com.br' }, { value: '123' }]
    const defaultValues = []
    const form = useForm({ defaultValues })

    const errors = form.formState.errors
    const toastIf = (expression, detail) => {
        if (expression) {
            toast.current.show({ severity: 'error', summary: 'Ops!', detail })
            return true
        }
        return false
    }

    const onSubmit = (data) => {

        if (toastIf(data.email.length < 9, 'E-mail muito curto')) {
        } else if (toastIf(data.celular.length < 10, 'Celular muito curto')) {
        } else if (toastIf(data.password.length < 5, 'Senha muito curta')) {
        } else if (toastIf(data.password !== data.password_repeat, 'As senhas devem ser iguais')) {
        } else if (toastIf(!data.termos, 'Você deve aceitar os termos')) {
        } else {
            toast.current.show({ severity: 'info', summary: 'Registro', detail: 'Registrando...' })
            const postData = {
                "nomeUsu": data.nome,
                "telcelUsu": data.celular,
                "loginUsu": data.email,
                "password": data.password,
                "urlconfirmUsu": window.location.origin + '/confirmregister'
            }
            server().post('registerstart', postData).then((response) => {

                setMessage('Confirme o registro no e-mail que foi enviado para você!')

                // navigate('/login', { state: { msgRegistered: 'Confirme o registro no e-mail que foi enviado para você!' } })
                // form.reset()

            }).catch((err) => {

                const detail = err?.response?.data?.message || err.message
                setMessage(detail)
            })

        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>
    }

    return (
        <div className='authContainer'>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-column align-items-left gap-2">
                <Toast ref={toast} />
                <div>
                    <h2>Nova conta</h2>
                    <p>
                        {message}
                    </p>
                </div>
                <Controller
                    name="nome"
                    control={form.control}
                    rules={{
                        required: 'Nome',
                        validate: value => value.length > 4 || "Nome muito curto"
                    }}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.nome })}>
                            </label>
                            <InputText id={field.name} {...field} placeholder='Nome' inputMode="text" className={classNames({ 'p-invalid': fieldState.error })} />
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                <Controller
                    name="celular"
                    control={form.control}
                    rules={{ required: 'celular' }}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.celular })}>
                            </label>
                            <InputMask id={field.name} {...field} placeholder='Celular' inputMode="tel" mask="(99) 99999-9999" className={classNames({ 'p-invalid': fieldState.error })} />
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                <Controller
                    name="email"
                    control={form.control}
                    rules={{ required: 'email' }}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.email })}>
                            </label>
                            <InputText id={field.name} {...field} placeholder='E-mail' inputMode="email" className={classNames({ 'p-invalid': fieldState.error })}
                                autoComplete="on"
                            />
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                <Controller
                    name="password"
                    control={form.control}
                    rules={{ required: 'A senha é obrigatória' }}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.password })}>
                            </label>
                            <Password inputId={field.name} {...field} placeholder='Senha' className={classNames({ 'p-invalid': fieldState.error })} feedback={true}
                                toggleMask={false}
                                promptLabel="Informe sua senha"
                                weakLabel="Tá indo bem..."
                                mediumLabel="Médio"
                                strongLabel="Essa é boa!"
                                autoComplete="on"
                            />
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                <Controller
                    name="password_repeat"
                    control={form.control}
                    rules={{
                        required: 'A senha é obrigatória'
                    }}
                    defaultValue=""
                    render={({ field, fieldState }) => (
                        <>
                            <label htmlFor={field.name} className={classNames({ 'p-error': errors.password })}>
                            </label>
                            <Password inputId={field.name} {...field} placeholder='Repita a senha'
                                className={classNames({ 'p-invalid': fieldState.error })}
                                feedback={false}
                                toggleMask={false}
                                promptLabel="Informe sua senha"
                                // weakLabel="Um pouco mais vai"
                                // mediumLabel="Médio"
                                // strongLabel="Essa é boa!"
                                autoComplete="off"
                            />
                            {getFormErrorMessage(field.name)}
                        </>
                    )}
                />
                <Controller
                    name="termos"
                    control={form.control}
                    rules={{ required: true }}
                    defaultValue=""
                    render={({ field, fieldState }) => (

                        <div className="flex align-items-center">
                            <Checkbox inputId={field.name} {...field} checked={field.value} className={classNames({ 'p-invalid': fieldState.error })}></Checkbox>
                            <label htmlFor={field.name} className={classNames("ml-2", { 'p-error': errors.termos })}>Eu concordo com os termos*</label>
                        </div>
                    )}
                />
                <Button label="Registrar-se" type="submit" icon="pi pi-check" />
                <Link to={'/login'} className="MenuOpc">
                    Login
                </Link>
            </form>
        </div>
    )
}
