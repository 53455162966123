/**
 *  save(id = null, data = {}) {
 *    return super.save(id, data)
 * }
 */
import ServiceBase from "base/baseService"

export default class ConfigService extends ServiceBase {

    title = "Configuração"
    pluralTitle = "Configurações"
    url = '/configuracao/config'
    frontUrl = '/configuracao/config/config'
    frontUrlList = '/configuracao/config/configlist'
    primaryKey = 'codigo_conf'

    postDTO(data = {}) {

        return {
            ...data,
            valor_conf: data.config_numero[0].cod_tid_num === 'boolean' ? (data.valor_conf === '1' || data.valor_conf === true ? '1' : '0') : data.valor_conf,
        }

    }
    prepareToFront(data = {}) {

        return {
            ...data
        }
    }

    async list(qs = '') {

        return super.list(qs).then((response) => {
            return response
        })
    }

    // Get show
    async getById({ id = null }) {

        return super.getById({ id, includes: 'usuario_hist_acao' }).then((response) => {
            response.data = this.prepareToFront(response.data)
            return response
        }).catch((error) => {
            console.log("Erro (service): ", error)
            return error
        })

    }

}

