import React, { useEffect, useRef, useState } from 'react'
import { Toast } from 'primereact/toast'
import * as auth from "infra/localStorage.js"
import server from 'infra/server.js'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

export default function ConfirmRegister() {

    const navigate = useNavigate()
    const [message, setMessage] = useState('')
    const toast = useRef(null)

    useEffect(() => {

        const data = window.location.search // Ex: ?loginUsu=bia@gmail.com&regcodUsu=103129
        const url = '/registerconfirm' + data

        server().get(url)
            .then((response) => {
                setMessage('Redirecionando para o login!')
                navigate('/login', { state: { msgRegistered: 'Você foi registrado com sucesso, agora é só acessar!' } })

            }).catch((err) => {
                setMessage('Náo foi possível registrar! ' + err.message)
            })



    }, [])

    const defaultValues = []

    const form = useForm({ defaultValues })
    const onSubmit = (data) => {
    }


    return (
        <div className='authContainer'>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-column align-items-left gap-2">

                <Toast ref={toast} />
                <div>
                    <h2>Olá!</h2>
                    <p>
                        {message}
                    </p>
                </div>

            </form>
        </div>
    )
}
