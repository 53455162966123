import ServiceBase from "base/baseService"

export default class ClassificacaoPessoaService extends ServiceBase {

    title = "Classificação de pessoa"
    pluralTitle = "Classificação de pessoas"
    url = '/cadastro/pessoa/classificacaopessoa'
    frontUrl = '/cadastro/pessoa/classificacaopessoa'
    frontUrlList = '/cadastro/pessoa/classificacaopessoalist'
    primaryKey = 'codigo_clap'
    ignoredsSchemaConvertions = ['cod_tip_clap']

    prepareToSave({ data }) {

        data.cod_tip_clap = data.cod_tip_clap?.codigo_tip || null

        delete data.meta

        return data

    }

}
