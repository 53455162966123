/**
 * https://github.com/primefaces/primereact/issues/4614 
 * MenuBar: Submenu doesn't close on outside click when rendered inside <StrictMode>
 * 
 */

import { useNavigate } from "react-router-dom"
import { Menubar } from 'primereact/menubar'
import { Badge } from 'primereact/badge'
import * as auth from "../../infra/localStorage.js"
import { classNames } from 'primereact/utils'
import UserAvatar from "workarea/desktop/UserAvatar.js"
import { useEffect, useState } from "react"

export default function MenuTop() {
    const navigate = useNavigate()
    const storageData = auth.getData()
    const [ditem, setDitem] = useState([])

    // Help to use 'template'  https://github.com/primefaces/primereact/issues/1783

    // const menu = auth.getData().menu
    // scanItems(menu)

    useEffect(() => {

        const menu = auth.getData().menu

        if (Object.keys(menu).length > 0) {
            setDitem(menu)
            scanItems(menu)
        }


        function scanItems(items) {
            items.forEach((child) => {
                if (child.route) {
                    child.command = () => navigate(child.route)
                    child.template = (item, options) => {
                        return (
                            <a
                                key={child.ordem_men}
                                href={child.route}
                                role="menuitem"
                                className={options.className}
                                target={item.target}
                                onClick={options.onClick}
                            >
                                <span className={classNames(options.iconClassName, options.icon)}></span>
                                <span className="p-menuitem-text">{item.label}</span>
                            </a >
                        )
                    }
                }
                if (child.items) {
                    scanItems(child.items)
                }
            })
        }

    }, [])


    // const start = <img alt="logo" src="https://primefaces.org/cdn/primereact/images/logo.png" height="40" className="mr-2"></img>;
    // const end = <> <InputText placeholder="Search" type="text" className="w-full" />  </>;
    const start = <></>
    const end = <div className="flex">
        <div className="mr-4 ml-4 mt-2">
            {storageData.empresa.nome_fantasia_pej}
        </div>
        <div className="flex flex-nowrap justify-content-between gap-4 mr-5 mt-2">
            <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '1.3rem' }}>
                <Badge value="2"></Badge>
            </i>
            <i className="pi pi-calendar p-overlay-badge" style={{ fontSize: '1.3rem' }}>
                <Badge value="5+" severity="danger"></Badge>
            </i>
            <i className="pi pi-envelope p-overlay-badge" style={{ fontSize: '1.3rem' }}>
                <Badge value="2" severity="success"></Badge>
            </i>
        </div>
        <div>
            <UserAvatar></UserAvatar>
        </div>
    </div>;

    return (

        <div >
            <Menubar
                model={ditem}
                start={start}
                end={end}
                className="flex flex-nowrap"
            />
            {/* <div className="mb-4">mock: {JSON.stringify(ditem)}</div> */}
            {/* <div className="mb-4">menu: {JSON.stringify(ditem)}</div> */}

        </div >


    )
}

