import ServiceBase from "base/baseService"

export default class UsuarioService extends ServiceBase {

    static title = "Usuário"
    static pluralTitle = "Usuários"
    static url = '/cadastro/pessoa/usuario'
    static frontUrl = '/cadastro/pessoa/usuario'
    static frontUrlList = '/cadastro/pessoa/usuariolist'
    static primaryKey = 'codigo_usu'

    static postDTO(data = {}) {

        return {
            ...data,
        }

    }
    static prepareToFront(data = {}) {

        return {
            ...data
        }
    }

    static async list(qs = '') {

        return super.list(qs).then((response) => {
            return response
        })
    }

    // Get show
    static async getById({ id = null }) {

        return super.getById({ id, includes: 'usuario_hist_acao' }).then((response) => {
            response.data = this.prepareToFront(response.data)
            return response
        }).catch((error) => {
            console.log("Erro (service): ", error)
            return error
        })

    }

}

