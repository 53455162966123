import { yupResolver } from "@hookform/resolvers/yup"
import ControllerInput from 'base/ControllerInput'
import FormCadastro, { FormCadastroAux } from 'base/FormCadastro'
import GridForm from 'base/GridForm'
import OutputSpanForm from 'base/OutputSpanForm'
import CategoriaPessoaService from 'business/cadastro/pessoa/categoriaPessoa/categoriaPessoaService'
import ClassificacaoPessoaService from 'business/cadastro/pessoa/classificacaoPessoa/classificacaoPessoaService'
import GrupoPessoaService from 'business/cadastro/pessoa/grupoPessoa/grupoPessoaService'
import RamoAtividadeService from 'business/cadastro/pessoa/ramoAtividade/ramoAtividadeService'
import RegimeTributacaoService from 'business/cadastro/pessoa/regimeTributacao/regimeTributacaoService'
import EmpresaService from 'business/configuracao/empresa/empresaService'
import { defaultEmptySelection } from 'helper/default'
import { errorToToast } from 'helper/error'
import { formatDatetime, getMask } from 'helper/formater'
import { generateCNPJ } from 'helper/generate'
import { cnpjSearch } from 'helper/searchers'
import { softSetField } from 'helper/setters'
import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'
import { Divider } from 'primereact/divider'
import { TabPanel, TabView } from 'primereact/tabview'
import { useEffect, useRef, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

export default function Empresa() {

    const [service, setService] = useState()

    if (!service) {
        setService(new EmpresaService())
    }

    const [municipiosBox, setMunicipiosBox] = useState(null)
    const stepperRef = useRef(null)
    const toast = useRef(null)
    const { id } = useParams()
    const [validations, setValidations] = useState([])
    const [pessoaEnderecoTipoList, setPessoaEnderecoTipoList] = useState([])
    const [registro, setRegistro] = useState({})
    const [classificacaoPessoaList, setClassificacaoPessoaList] = useState([])
    const [grupoPessoaList, setGrupoPessoaList] = useState([])
    const [categoriaPessoaList, setCategoriaPessoaList] = useState([])
    const [ramoAtividadeList, setRamoAtividadeList] = useState([])
    const [regimeTributacaoList, setRegimeTributacaoList] = useState([])
    const [regimeTributacaoEList, setRegimeTributacaoEList] = useState([])

    const DEF = { RESIDENCIAL: 1, REGISTRO: 6 }

    const form = useForm({
        defaultValues: async () => await load(),
        resolver: yupResolver(validations),
        mode: "onBlur"
    })

    const pessoaEndereco = useFieldArray({
        control: form.control,
        name: "pessoa_juridica.pessoa.pessoa_endereco"
    })

    async function load() {
        const data = await FormCadastroAux.load({ id, service, toast, beforeSubmit, useNew: true })

        if (data.pessoa_juridica.pessoa.classificacao_pessoa) {
            const classificacaoPessoa = { codigo_clap: data.pessoa_juridica.pessoa.classificacao_pessoa?.codigo_clap, descricao_clap: data.pessoa_juridica.pessoa.classificacao_pessoa?.descricao_clap }
            setClassificacaoPessoaList([classificacaoPessoa])
            data.pessoa_juridica.pessoa.cod_clap_pes = classificacaoPessoa
        }

        if (data.pessoa_juridica.pessoa.grupo_pessoa) {
            const grupoPessoa = { codigo_grup: data.pessoa_juridica.pessoa.grupo_pessoa?.codigo_grup, descricao_grup: data.pessoa_juridica.pessoa.grupo_pessoa?.descricao_grup }
            setGrupoPessoaList([grupoPessoa])
            data.pessoa_juridica.pessoa.cod_grup_pes = grupoPessoa
        }

        if (data.pessoa_juridica.pessoa.categoria_pessoa) {
            const categoriaPessoa = { codigo_catp: data.pessoa_juridica.pessoa.categoria_pessoa?.codigo_catp, descricao_catp: data.pessoa_juridica.pessoa.categoria_pessoa?.descricao_catp }
            setCategoriaPessoaList([categoriaPessoa])
            data.pessoa_juridica.pessoa.cod_catp_pes = categoriaPessoa
        }

        if (data.pessoa_juridica.ramo_atividade) {
            const ramoAtividade = { codigo_rati: data.pessoa_juridica.ramo_atividade?.codigo_rati, descricao_rati: data.pessoa_juridica.ramo_atividade?.descricao_rati }
            setRamoAtividadeList([ramoAtividade])
            data.pessoa_juridica.cod_rati_pej = ramoAtividade
        }

        if (data.pessoa_juridica.regime_tributacao) {
            const regimeTributacao = { codigo_retri: data.pessoa_juridica.regime_tributacao?.codigo_retri, descricao_retri: data.pessoa_juridica.regime_tributacao?.descricao_retri }
            setRegimeTributacaoList([regimeTributacao])
            data.pessoa_juridica.cod_retri_pej = regimeTributacao
        }

        if (data.pessoa_juridica.regime_tributacao_e) {
            const regimeTributacaoE = { codigo_retri: data.pessoa_juridica.regime_tributacao_e?.codigo_retri, descricao_retri: data.pessoa_juridica.regime_tributacao_e?.descricao_retri }
            setRegimeTributacaoEList([regimeTributacaoE])
            data.pessoa_juridica.cod_retrie_pej = regimeTributacaoE
        }

        startMunicipiosBox(data)
        setPessoaEnderecoTipoList([...data.meta.pessoa_endereco_tipo])

        service.addEnderecoResidencial(data.pessoa_juridica.pessoa)
        service.enderecosCodigoToObject(data.pessoa_juridica.pessoa, data.meta.pessoa_endereco_tipo)

        return data
    }

    const startMunicipiosBox = (data) => {

        const muns = data.pessoa_juridica.pessoa.pessoa_endereco.map(e => service.formatMunicipioOption(e.municipio.codigo_mun, e.municipio.descricao_mun, e.municipio.uf.sigla_uf))
        setMunicipiosBox(muns)

    }

    const beforeSubmit = (data) => {

        let submitData = structuredClone(data)

        const { dirtyFields } = form.formState

        service.formatActionOfEnderecos(submitData?.pessoa_juridica?.pessoa, dirtyFields?.pessoa_juridica?.pessoa)

        submitData = service.prepareToSave({ data: submitData })

        return submitData

    }

    useEffect(() => {
        service.getSchema().then(e => {
            console.log('Setting vaidations with', e)
            setValidations(e)
        })

    }, [service])

    const loadClassificacaoPessoaList = async () => {
        if (classificacaoPessoaList.length < 2) {
            const clap = new ClassificacaoPessoaService()
            await clap.list('?cod_tip_clap=j&cod_tip_clap=null').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_clap: e.codigo_clap, descricao_clap: e.descricao_clap }))
                data.push({ codigo_clap: null, descricao_clap: defaultEmptySelection() })
                setClassificacaoPessoaList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    const loadGrupoPessoaList = async () => {
        if (grupoPessoaList.length < 2) {
            const grup = new GrupoPessoaService()
            await grup.list('?cod_tip_grup=j&cod_tip_grup=null').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_grup: e.codigo_grup, descricao_grup: e.descricao_grup }))
                data.push({ codigo_grup: null, descricao_grup: defaultEmptySelection() })
                setGrupoPessoaList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    const loadCategoriaPessoaList = async () => {
        if (categoriaPessoaList.length < 2) {
            const catp = new CategoriaPessoaService()
            await catp.list('?cod_tip_catp=j&cod_tip_catp=null').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_catp: e.codigo_catp, descricao_catp: e.descricao_catp }))
                data.push({ codigo_catp: null, descricao_catp: defaultEmptySelection() })
                setCategoriaPessoaList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    const loadRamoAtividadeList = async () => {
        if (ramoAtividadeList.length < 2) {
            const rati = new RamoAtividadeService()
            await rati.list('').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_rati: e.codigo_rati, descricao_rati: e.descricao_rati }))
                data.push({ codigo_rati: null, descricao_rati: defaultEmptySelection() })
                setRamoAtividadeList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    const loadRegimeTributacaoList = async () => {
        if (regimeTributacaoList.length < 2) {
            const retri = new RegimeTributacaoService()
            await retri.list('?especial_retri=0').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_retri: e.codigo_retri, descricao_retri: e.descricao_retri }))
                data.push({ codigo_retri: null, descricao_retri: defaultEmptySelection() })
                setRegimeTributacaoList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    const loadRegimeTributacaoEList = async () => {
        if (regimeTributacaoEList.length < 2) {
            const retrie = new RegimeTributacaoService()
            await retrie.list('?especial_retri=1').then(rs => {
                const data = rs.data.data.map(e => ({ codigo_retri: e.codigo_retri, descricao_retri: e.descricao_retri }))
                data.push({ codigo_retri: null, descricao_retri: defaultEmptySelection() })
                setRegimeTributacaoEList(data)
            }).catch(err => errorToToast({ err, toast }))
        }
    }

    const municipioSearch = (event) => {

        service.filterMunicipio({ municipio: event.query }).then((response) => {
            const rs = response.data.data
            const formatado = rs.map(e => service.formatMunicipioOption(e.codigo_mun, e.descricao_mun, e.meta.sigla_uf))
            setMunicipiosBox(formatado)
        }).catch((error) => {
            console.log("Erro: ", error)
        })
    }

    const enderecoSearch = async (cep, index) => {

        service.enderecoSearch({
            cep,
            resolve: ({ response, optionMunicipio }) => {
                if (!municipiosBox.some(e => e.codigo_mun === response.ibge)) {
                    const municipiosUnique = municipiosBox
                    municipiosUnique.push(optionMunicipio)
                    setMunicipiosBox(municipiosUnique)
                }
                form.setValue(`pessoa_juridica.pessoa.pessoa_endereco[${index}].endereco_end`, response.logradouro, { shouldValidate: true, shouldTouch: true })
                form.setValue(`pessoa_juridica.pessoa.pessoa_endereco[${index}].bairro_end`, response.bairro)
                form.setValue(`pessoa_juridica.pessoa.pessoa_endereco[${index}].cod_mun_end`, optionMunicipio)
            },
            reject: () => {
                toast.current.show({ severity: 'warn', summary: 'Ops!', detail: `CEP ${cep} não encontrado!`, life: 7000 })
            }
        })

    }

    // Procura por dados do CNPJ em API externa.
    const setFieldsFromCnpj = async (cnpj) => {
        try {

            if (form.getValues("pessoa_juridica.pessoa.nome_razao_pes") === '') {

                const data = await cnpjSearch(cnpj)

                const indexEnd = service.getIndexEnderecoResidencial(pessoaEndereco)

                softSetField(form, "pessoa_juridica.pessoa.nome_razao_pes", data.razao_social)
                softSetField(form, "pessoa_juridica.pessoa.email_pes", data.email)
                softSetField(form, "pessoa_juridica.pessoa.tel_celular_pes", data.ddd_telefone_1)
                softSetField(form, "pessoa_juridica.pessoa.tel_comercial_pes", data.ddd_telefone_2)
                softSetField(form, "pessoa_juridica.nome_fantasia_pej", data.nome_fantasia)
                softSetField(form, "pessoa_juridica.cnae_fiscal_pej", data.cnae_fiscal)
                softSetField(form, "pessoa_juridica.cnaes_sec_pej", data.cnaesInLine)
                softSetField(form, "pessoa_juridica.data_fundacao_pej", new Date(data.data_inicio_atividade + ' '))
                softSetField(form, "pessoa_juridica.cnpj_ativo_pej", data.descricao_situacao_cadastral === 'ATIVA', true)

                softSetField(form, `pessoa_juridica.pessoa.pessoa_endereco[${indexEnd}].cep_end`, data.cep)
                softSetField(form, `pessoa_juridica.pessoa.pessoa_endereco[${indexEnd}].numero_end`, data.numero)
                softSetField(form, `pessoa_juridica.pessoa.pessoa_endereco[${indexEnd}].complemento_end`, data.complemento)

                await enderecoSearch(data.cep, indexEnd)
            }
        } catch (error) {
            console.error(error)
        }

    }

    const Cnpj = () => {
        return (
            <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" mask={getMask('cnpj')} field="pessoa_juridica.pessoa.cpf_cnpj_pes" form={form} service={service} type="text" inputMode="tel" label="CNPJ"
                onBlur={e => {
                    e.preventDefault()
                    setFieldsFromCnpj(e.target.value)
                }
                }
            />
        )
    }

    return (

        < FormCadastro service={service} form={form} aux={FormCadastroAux} toast={toast} beforeSubmit={beforeSubmit} >

            <TabView ref={stepperRef}
                className='text-sm'
                style={{ flexBasis: '1rem' }}
                onBeforeTabChange={async (e) => {
                    if (e.index === DEF.REGISTRO) {
                        if (registro?.meta === undefined) {
                            const rs = await service.getById({ id, includes: 'registro' })
                            setRegistro(rs.data.meta.registro)
                        }
                    }

                }}
            >

                <TabPanel header="Capa" leftIcon="pi pi-home mr-2">
                    <GridForm>
                        {!id && Cnpj()}
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-4" field="pessoa_juridica.pessoa.nome_razao_pes" form={form} service={service} type="text" clearString label="Razão social (Pode ter números)"
                            onBlur={(event) => {
                                event.preventDefault()
                                if (event.target.value.includes('Inventad')) {
                                    if (form.getValues("pessoa_juridica.pessoa.cpf_cnpj_pes") === '') {
                                        form.setValue("pessoa_juridica.pessoa.cpf_cnpj_pes", generateCNPJ())
                                    }
                                }
                            }}
                        />
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-4" field="pessoa_juridica.nome_fantasia_pej" form={form} service={service} type="text" clearString label="Nome fantasia (Pode ter números)" />
                        {id && Cnpj()}
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa_juridica.pessoa.ie_pes" form={form} service={service} type="text" inputMode="tel" noSpace label="IE" hint="Inscrição estadual, pode ser ISENTO ou um número e serve para emitir notas fiscais" />

                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" mask={getMask('cellphone')} field="pessoa_juridica.pessoa.tel_celular_pes" form={form} service={service} type="text" inputMode="tel" label="Celular" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" mask={getMask('cellphone')} field="pessoa_juridica.pessoa.tel_comercial_pes" form={form} service={service} type="text" inputMode="tel" label="Fone comercial" />

                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa_juridica.pessoa.email_pes" form={form} service={service} type="text" noSpace inputMode="email" label="E-mail" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa_juridica.pessoa.email_financeiro_pes" form={form} service={service} type="text" noSpace inputMode="email" label="E-mail (financeiro)" />

                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa_juridica.data_fundacao_pej" form={form} service={service} type="calendar" label="Fundação" />
                        <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field="pessoa_juridica.cnpj_ativo_pej" form={form} service={service} type="switch" label="CNPJ ativo" />
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-4" field="pessoa_juridica.obs_pej" form={form} service={service} type="textarea" clearString label="Observações" rows={3} />

                        {pessoaEndereco.fields.map((data, index) => {
                            if (data.cod_tend_end.codigo_tend !== DEF.RESIDENCIAL) {
                                return null
                            }
                            return (
                                <div key={index} className='grid col-12 m-0 p-0'>
                                    <Divider className='flex mt-2 mb-2'></Divider>
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].cod_tend_end`} form={form} service={service} type="dropdown" label="Endereço de"
                                        optionLabel='descricao_tend'
                                        options={pessoaEnderecoTipoList}
                                        disabled
                                    />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].cep_end`} form={form} service={service} mask={getMask('cep')} type="text" inputMode="tel" label="CEP"
                                        onBlur={e => enderecoSearch(e.target.value, index)}
                                    />
                                    <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].endereco_end`} form={form} service={service} type="text" clearString label="Endereço" />
                                    <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].numero_end`} form={form} service={service} type="text" inputMode="numeric" noSpace label="Número (aceitar SN)" />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].complemento_end`} form={form} service={service} type="text" clearString label="Complemento" />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].bairro_end`} form={form} service={service} type="text" clearString label="Bairro" />

                                    <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].cod_mun_end`} form={form} service={service} type="autocomplete" label="Municipio"
                                        suggestions={municipiosBox}
                                        completeMethod={municipioSearch}
                                        fieldShow={'descricao_munuf'}
                                    />

                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].tel_end`} mask={getMask('cellphone')} form={form} service={service} type="text" inputMode="tel" label="Telefone" />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].usa_desde_end`} form={form} service={service} type="calendar" inputMode="tel" label="Desde" />

                                </div>
                            )
                        }
                        )}

                    </GridForm>
                </TabPanel>

                <TabPanel header="Empresa" leftIcon="pi pi-directions mr-2">
                    <GridForm>
                        <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-2" field="ativa_emp" form={form} service={service} type="switch" label="Acessível no login" />
                    </GridForm>
                </TabPanel>
                <TabPanel header="Endereços" leftIcon="pi pi-directions mr-2">
                    <GridForm>
                        {pessoaEndereco.fields.map((data, index) => {
                            if (data.cod_tend_end.codigo_tend === DEF.RESIDENCIAL) {
                                return ''
                            }
                            return (
                                <div key={data.id} className='grid col-12 m-0 p-0'>
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].cod_tend_end`} form={form} service={service} type="dropdown" label="Endereço de"
                                        optionLabel='descricao_tend'
                                        options={pessoaEnderecoTipoList}
                                        onClick={_ => true}
                                    />
                                    <ControllerInput addClassName="md:col-2 lg:col-3 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].nome_end`} form={form} service={service} type="text" clearString label="Nome" />
                                    <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].cep_end`} form={form} service={service} mask={getMask('cep')} type="text" inputMode="tel" label="CEP"
                                        onBlur={e => enderecoSearch(e.target.value, index)}
                                    />
                                    <ControllerInput addClassName="md:col-3 lg:col-4 xl:col-3" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].endereco_end`} form={form} service={service} type="text" clearString label="Endereço" />
                                    <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-1" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].numero_end`} form={form} service={service} type="text" inputMode="numeric" noSpace label="Número" />
                                    <ControllerInput addClassName="md:col-3 lg:col-4 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].complemento_end`} form={form} service={service} type="text" clearString label="Complemento" />
                                    <ControllerInput addClassName="md:col-3 lg:col-6 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].bairro_end`} form={form} service={service} type="text" clearString label="Bairro" />
                                    <ControllerInput addClassName="md:col-3 lg:col-4 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].cod_mun_end`} form={form} service={service} type="autocomplete" label="Municipio"
                                        suggestions={municipiosBox}
                                        completeMethod={municipioSearch}
                                        fieldShow={'descricao_munuf'}
                                    />

                                    <ControllerInput addClassName="md:col-3 lg:col-4 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].tel_end`} mask={getMask('cellphone')} form={form} service={service} type="text" inputMode="tel" label="Telefone" />
                                    <ControllerInput addClassName="md:col-3 lg:col-3 xl:col-2" field={`pessoa_juridica.pessoa.pessoa_endereco[${index}].usa_desde_end`} form={form} service={service} type="calendar" inputMode="tel" label="Desde" />

                                    <Button

                                        className="md:col-1 lg:col-1 xl:col-1"
                                        label="." icon="pi pi-trash" size="small" onClick={(e) => {
                                            confirmDialog({
                                                message: 'Confirma remover o endereço? ' + index,
                                                icon: 'pi pi-question-circle',
                                                accept: () => {
                                                    const removed = form.getValues(`pessoa_juridica.pessoa.pessoaEnderecoRemoved`) || []
                                                    pessoaEndereco.remove(index)
                                                    if (data.codigo_end) {
                                                        removed.push({ codigo_end: data.codigo_end })
                                                        form.setValue('pessoa_juridica.pessoa.pessoaEnderecoRemoved', removed)
                                                    }
                                                }
                                            })
                                            e.preventDefault()
                                        }}
                                        severity="warning" text
                                    />

                                    {pessoaEndereco.fields.length > 2 && <Divider className='flex mt-2 mb-2'></Divider>}
                                </div>
                            )
                        })}

                        <div className="flex m-2 justify-content-start">
                            <Button
                                onClick={(e) => {
                                    e.preventDefault()
                                    pessoaEndereco.append(service.getNewEndereco(), { shouldFocus: false })
                                }}
                            >Adicionar</Button>

                        </div>

                    </GridForm>
                </TabPanel>

                <TabPanel header="Crédito" leftIcon="pi pi-wrench mr-2">
                    <GridForm>
                        <ControllerInput addClassName="md:col-6 lg:col-3 xl:col-2" field="pessoa_juridica.pessoa.lim_credito_pes" form={form} service={service} type="number" inputMode="numeric" noSpace label="Limite de crédito" />
                        <ControllerInput addClassName="md:col-6 lg:col-3 xl:col-2" field="pessoa_juridica.pessoa.blq_venda_ate_pes" form={form} service={service} type="calendar" inputMode="tel"
                            label="Bloquear vendas até" mask={getMask('datetime')} showTime hourFormat="24"
                        />
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-4" field="pessoa_juridica.pessoa.obs_blq_venda_pes" form={form} service={service} type="textarea" clearString label="Observações do bloqueio" rows={5} />
                    </GridForm>
                </TabPanel>
                <TabPanel header="Mais" leftIcon="pi pi-wrench mr-2">
                    <GridForm>
                        <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field="pessoa_juridica.pessoa.cod_clap_pes" form={form} service={service} type="dropdown" label="Classificação"
                            optionLabel='descricao_clap'
                            options={classificacaoPessoaList}
                            onClick={loadClassificacaoPessoaList}
                        />

                        <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field="pessoa_juridica.pessoa.cod_grup_pes" form={form} service={service} type="dropdown" label="Grupo"
                            optionLabel='descricao_grup'
                            options={grupoPessoaList}
                            onClick={loadGrupoPessoaList}
                        />

                        <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field="pessoa_juridica.pessoa.cod_catp_pes" form={form} service={service} type="dropdown" label="Categoria"
                            optionLabel='descricao_catp'
                            options={categoriaPessoaList}
                            onClick={loadCategoriaPessoaList}
                        />
                        <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field="pessoa_juridica.vers_cont_social_pej" form={form} service={service} type="text" clearString label="Versão de contrato social" />
                        <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field="pessoa_juridica.pessoa.biografia_pes" form={form} service={service} type="text" clearString label="Bio" />

                        <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-2" field="pessoa_juridica.pessoa.cli_atacadista_pes" form={form} service={service} type="switch" label="Atacadista" />
                        <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-2" field="pessoa_juridica.forn_pej" form={form} service={service} type="switch" label="Fornecedor" />
                        <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field="pessoa_juridica.forn_oficial_pej" form={form} service={service} type="switch" label="É fornecedor oficial" />

                        <ControllerInput addClassName="md:col-4 lg:col-4 xl:col-2" field="pessoa_juridica.fidel_forn_pej" form={form} service={service} type="switch" label="Fidelidade com fornecedor" />
                        <ControllerInput addClassName="md:col-2 lg:col-2 xl:col-2" field="pessoa_juridica.conveniado_pej" form={form} service={service} type="switch" label="Conveniado" />
                        <ControllerInput addClassName="md:col-6 lg:col-6 xl:col-4" field="pessoa_juridica.pessoa.ref_bancaria_pes" form={form} service={service} type="textarea" clearString label="Referências bancárias" rows={5} />

                    </GridForm>
                </TabPanel>
                <TabPanel header="Fiscal" leftIcon="pi pi-calculator mr-2">
                    <GridForm>

                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.cod_rati_pej" form={form} service={service} type="dropdown" label="Ramo de atividade específica"
                            optionLabel='descricao_rati'
                            options={ramoAtividadeList}
                            onClick={loadRamoAtividadeList}
                        />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.cod_retri_pej" form={form} service={service} type="dropdown" label="Regime de tributação"
                            optionLabel='descricao_retri'
                            options={regimeTributacaoList}
                            onClick={loadRegimeTributacaoList}
                        />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.cod_retrie_pej" form={form} service={service} type="dropdown" label="Regime de tributação especial"
                            optionLabel='descricao_retri'
                            options={regimeTributacaoEList}
                            onClick={loadRegimeTributacaoEList}
                        />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.cnae_fiscal_pej" form={form} service={service} type="text" noSpace clearString label="CNAE fiscal" />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-4" field="pessoa_juridica.cnaes_sec_pej" form={form} service={service} type="text" noSpace clearString label="CNAEs secundários (vírgulas)" />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.num_suframa_pej" form={form} service={service} type="text" clearString label="Número do suframa" />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.pessoa.ie_subt_pes" form={form} type="text" noSpace service={service} label="IE ST" />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.pessoa.ins_mun_pes" form={form} type="text" noSpace service={service} label="Incrição municipal" />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.pessoa.id_inter_pes" form={form} service={service} type="text" clearString label="Id. internacional" />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.nire_pej" form={form} service={service} type="text" noSpace clearString label="NIRE" />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.reter_picocs_pej" form={form} service={service} type="switch" label="Reter PIS/COFINS/CSLL" />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.pessoa.reter_iss_pes" form={form} service={service} type="switch" label="Reter ISS" />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.inc_fiscal_pej" form={form} service={service} type="switch" label="Possui incentivo fiscal" />
                        <ControllerInput addClassName="md:col-6 lg:col-4 xl:col-2" field="pessoa_juridica.inc_cult_pej" form={form} service={service} type="switch" label="Possui incentivo cultural" />

                    </GridForm>
                </TabPanel>
                <TabPanel header="Informações" leftIcon="pi pi-info-circle mr-2" visible={id > 0} >
                    <GridForm>

                        <OutputSpanForm field="usu_ins_pes" className="col-12 md:col-4 lg:col-4 xl:col-3" value={form.getValues('pessoa_juridica.pessoa.usu_ins_pes') + ' - ' + registro?.meta?.nome_razao_usu} label="Inserido por" />
                        <OutputSpanForm field="cod_emp_pes" className="col-12 md:col-4 lg:col-4 xl:col-3" value={form.getValues('pessoa_juridica.pessoa.cod_emp_pes') + ' - ' + registro?.meta?.nome_razao_emp} label="Na empresa" />
                        <OutputSpanForm field="pessoa_juridica.pessoa.criado_pes" className="col-12 md:col-4 lg:col-4 xl:col-3" form={form} value={formatDatetime(form.getValues('pessoa_juridica.pessoa.criado_pes'))} label="Inserido em" />

                    </GridForm>
                </TabPanel>


            </TabView>



        </FormCadastro >
    )
}

