import React, { useEffect, useRef, useState } from 'react'
import { Column } from 'primereact/column'
import PessoaFisicaService from "./pessoaFisicaService"
import { formatCPF, formatCellphone } from 'helper/formater'
import FormList from 'base/FormList'

export default function PessoaFisicaList() {

    const [service, setService] = useState()
    const toast = useRef(null)

    if (!service) {
        console.log('New pessoa fisica list')
        setService(new PessoaFisicaService())
    }
    const searchData = {
        pkName: 'codigo_pef',
        dsName: 'nome_razao_pes',
        mainField: { name: 'nome_razao_pes', label: 'Nome' },
        advFields: [
            { name: 'nome_razao_pes', label: 'Nome' },
            { name: 'cpf_cnpj_pes', label: 'CPF', inputMode: 'numeric' },
            { name: 'endereco_end', label: 'Rua' },
            { name: 'tel_celular_pes', label: 'Celular', inputMode: 'tel' },
            { name: 'codigo_pef', label: 'Código', type: 'integer', inputMode: 'numeric' },
        ]
    }

    useEffect(() => {
    }, [service])

    return (

        <FormList service={service} toast={toast} searchData={searchData} showActions={true}>

            <Column field="meta.nome_razao_pes" sortable header="Nome"></Column>
            <Column field="meta.cpf_cnpj_pes" body={e => formatCPF(e.meta.cpf_cnpj_pes)} sortable header="CPF"></Column>
            <Column field="meta.tel_celular_pes" body={e => formatCellphone(e.meta.tel_celular_pes)} sortable header="Celular"></Column>

        </FormList >
    );
}