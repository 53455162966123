import ServiceBase from "base/baseService"

export default class CategoriaPessoaService extends ServiceBase {

    title = "Categoria de pessoa"
    pluralTitle = "Categoria de pessoas"
    url = '/cadastro/pessoa/categoriapessoa'
    frontUrl = '/cadastro/pessoa/categoriapessoa'
    frontUrlList = '/cadastro/pessoa/categoriapessoalist'
    primaryKey = 'codigo_catp'
    ignoredsSchemaConvertions = ['cod_tip_catp']

    prepareToSave({ data }) {

        data.cod_tip_catp = data.cod_tip_catp?.codigo_tip || null

        delete data.meta

        return data

    }

}
