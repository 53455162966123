import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Link, useNavigate } from 'react-router-dom';
import * as auth from "infra/localStorage.js"
import server from 'infra/server.js'
import LoginService from './loginService'

export default function BaseDados() {

    const [baseDados, setBaseDados] = useState([])
    const [loginService] = useState(new LoginService())
    const navigate = useNavigate()
    const toast = useRef(null);
    let selected = ''

    const defaultValues = [];
    const form = useForm({ defaultValues });
    const errors = form.formState.errors;

    // Depois de montada a tela.
    useEffect(() => {

        const http = server()

        http.get('basedados')
            .then((response) => {
                setBaseDados(response.data)
            })

    }, [])

    // Salva.
    const onSubmit = () => {

        const http = server()
        http.post('basedados', { "codnum_bad": selected }
        ).then(() => {

            const storageData = auth.getData()

            const oBase = baseDados.find((e) => e.codnum_bad == selected)
            storageData.base.descricao_bad = oBase.descricao_bad

            auth.setData(storageData)

            /**
             * Agora vamos ver se a empresa está definida no servidor.
             */

            server().get('sessaoinfo')
                .then(async (response) => {
                    const dataInfo = response.data[0]
                    storageData.empresa.codigo_emp = dataInfo.cod_emp_logada_ses
                    storageData.menu = await loginService.getMenu()
                    auth.setData(storageData)

                    if (storageData.empresa.codigo_emp) {
                        // Busca a descrição da empresa
                        server().get('empresa').then((response) => {

                            const selectEmp = response.data.find((e) => e.codigo_emp == storageData.empresa.codigo_emp)
                            storageData.empresa.nome_razao_pes = selectEmp.nome_razao_pes
                            storageData.empresa.nome_fantasia_pej = selectEmp.nome_fantasia_pej
                            auth.setData(storageData)

                            navigate('/')

                        })
                    } else {
                        navigate('/empresa')

                    }
                    form.reset()

                })

        })

    };

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    };

    return (
        <div className='authContainer'>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-column align-items-left gap-2">

                <Toast ref={toast} />
                <h2>
                    Selecione uma Base
                </h2>
                {baseDados.map((e) => {
                    return <Controller
                        key={e.codnum_bad}
                        name={e.descricao_bad}
                        control={form.control}
                        rules={{ required: false }}
                        defaultValue=""
                        render={(form) => (
                            <>
                                <Button key={form.field.name} label={form.field.name} type="submit" onClick={_ => selected = e.codnum_bad} id={form.field.name} {...form.field} icon="pi pi-globe" />
                                {getFormErrorMessage(form.field.name)}
                            </>
                        )}
                    />
                })}

                {/* <Button label="Registrar-se" type="submit" icon="pi pi-check" /> */}
                {/* onClick={setSelected(field.name)} */}

                <Link to={'/login'} className="MenuOpc">
                    Acessar com outra conta
                </Link>
            </form>
        </div>
    )
}
