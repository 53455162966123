
import InputTextForm from "base/InputTextForm";
import InputNumberForm from "base/InputNumberForm";
import InputCalendarForm from "base/InputCalendarForm";
import InputTextareaForm from "./InputTextareaForm";
import InputSwitchForm from "./InputSwitchForm";
import InputDropdownForm from "./InputDropdownForm";
import { Controller } from "react-hook-form";
import InputAutoCompleteForm from "./InputAutoCompleteForm";

export default function ControllerInput(props) {

    const form = props.form
    const rules = props.rules || { required: false }
    let className = props.className

    if (props.className) {
        className = props.className
    } else if (props.addClassName) {
        className = "field col-12 mb-2 " + props.addClassName
    }

    return (
        <div className={className} >
            <Controller
                name={props.field}
                defaultValue={''} // Alterei de null para '', atentar-se para possíveis problemas!
                control={form.control}
                rules={rules}
                render={(controller) => {

                    if (props.type === 'text') {
                        return <InputTextForm form={form} controller={controller} {...props} />
                    } else if (props.type === 'number') {
                        return <InputNumberForm form={form} controller={controller} {...props} />
                    } else if (props.type === 'calendar') {
                        return <InputCalendarForm form={form} controller={controller} {...props} />
                    } else if (props.type === 'textarea') {
                        return <InputTextareaForm form={form} controller={controller} {...props} />
                    } else if (props.type === 'switch') {
                        return <InputSwitchForm form={form} controller={controller} {...props} />
                    } else if (props.type === 'dropdown') {
                        return <InputDropdownForm form={form} controller={controller} {...props} />
                    } else if (props.type === 'autocomplete') {
                        return <InputAutoCompleteForm form={form} controller={controller} {...props} />
                    }

                    return <></>
                }}
            />
        </div >
    )
}